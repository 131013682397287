

export const actionSetPage = (page, index) => ({
	type: 'SET_PAGE',
	page: page,
	pageIndex: index,

});

export const actionSetNode = (node) => ({
	type: 'SET_NODE',
	node: node,
});
