import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './Companies.css';
import moment from 'moment';

import { connect } from 'react-redux';

import {
  actionSetCompany,
  actionSetCompanies,
} from '../../../redux/actions/actionCompany';
import store from '../../../redux/store';

import RestAPI from '../../../RestAPI';
import DataTable from '../../3_organisms/DataTable/DataTable';
import ModalOkCancel from '../../3_organisms/ModalOkCancel';

import { scopeIncludesOneOf } from '../../../helpers/scopecheck';
import NewRowModal from '../../3_organisms/NewRowModal/NewRowModal';

class Companies extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  updateView() {
    store.dispatch(actionSetCompanies(null));
    RestAPI.companies();
  }

  onSelect(company) {
    store.dispatch(actionSetCompany(company));
  }

  onAddNew(name) {
    let company = {
      name: name,
      created: new Date().toISOString(),
      modified: new Date().toISOString(),
      active: true,
    };

    RestAPI.createCompany(company).then((res) => {
      if (res && !res.error) {
        this.updateView();
      }
    });
  }

  onDelete(company) {
    RestAPI.deleteCompany(company).then((res) => {
      if (res && !res.error) {
        store.dispatch(actionSetCompany(null));
        this.updateView();
      }
    });
  }

  onSet(company) {
    if (company) {
      store.dispatch(actionSetCompany(company));
      this.props.history.push('/admin/company/' + company.uuid);
    }
  }

  render() {
    const { companies, user, login } = this.props;

    const { showAddModal, companyToDelete } = this.state;

    const canEdit = scopeIncludesOneOf(login && login.scope, ['root', 'admin']);
    const isUser = scopeIncludesOneOf(login && login.scope, ['user']);

    let validCompanies = [];
    if (isUser) {
      if (companies && user && user.companies_uuid)
        validCompanies = user.companies_uuid
          .map((uuid) => {
            return companies.find((company) => company.uuid === uuid);
          })
          .filter((company) => company);
    } else if (companies) validCompanies = companies;

    const rows =
      validCompanies &&
      validCompanies.map((company, index) => ({
        name: company.name,
        active: company.active?'✔️':'❌',
        loginname: company.loginname,
        company: company,
        created: company.created
          ? moment(company.created).format('DD:MM:YYYY HH:mm:ss')
          : '-',
        modified: company.modified
          ? moment(company.modified).format('DD:MM:YYYY HH:mm:ss')
          : '-',
      }));

    const actions = [
      canEdit && {
        icon: 'external',
        color: 'blue',
        tooltip: 'edit company',
        func: (row) => this.onSet(row.company),
      },
    ];

    const actionsMenu = [
      canEdit && {
        text: 'Edit',
        icon: 'external',
        color: 'blue',
        tooltip: 'edit company',
        func: (row) => this.onSet(row.company),
      },
      canEdit && {
        text: 'Delete',
        icon: 'delete',
        color: 'red',
        tooltip: 'delete company',
        func: (row) => this.setState({ companyToDelete: row.company }),
      },
    ];

    const columns = [
      {
        name: 'Name',
        key: 'name',
        required: true,
        singleLine: true,
        header: false,
      },
      {
        name: 'Login Name',
        key: 'loginname',
        disabled: true,
        singleLine: true,
        header: false,
      },
      {
        name: 'Created',
        key: 'created',
        disabled: true,
        singleLine: true,
      },
      {
        name: 'Modified',
        key: 'modified',
        disabled: true,
        singleLine: true,
      },
      {
        name: 'Active',
        key: 'active',
        disabled: true,
        singleLine: true,
      },
    ];

    return (
      <div>
        <h3>Companies:</h3>
        <div id="companies">
          <DataTable
            name="companies"
            placeholder="Filter name"
            sortable={true}
            filter={'name'}
            columns={columns}
            rows={rows}
            actions={actions}
            actionsMenu={actionsMenu}
            onSelect={(row) => this.onSelect(row.company)}
            generalActions={[
              canEdit && {
                icon: 'add',
                text: 'new company',
                color: 'blue',
                float: 'right',
                func: (event) => this.setState({ showAddModal: true }),
              },
            ]}
            updateView={() => this.updateView()}
          />
        </div>
        <ModalOkCancel
          text="Delete Company?"
          color="red"
          open={companyToDelete != null}
          onOK={() => {
            this.onDelete(companyToDelete);
            this.setState({ companyToDelete: null });
          }}
          onCancel={() => this.setState({ companyToDelete: null })}
        />
        {showAddModal && (
          <NewRowModal
            icon={'add'}
            title={'new company'}
            fields={columns}
            onApply={(result) => {
              this.onAddNew(result.name);
              this.setState({ showAddModal: false });
            }}
            onCancel={() => this.setState({ showAddModal: false })}
          />
        )}
      </div>
    );
  }
}

// You have to connect() to any reducers that you wish to connect to yourself
Companies = connect(
  (state) => ({
    login: state.login.login,
    user: state.user.user,
    companies: state.company.companies,
    company: state.company.company,
  }),
  {} // bind account loading action creator
)(Companies);

export default withRouter(Companies);
