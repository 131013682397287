import React from 'react';
import styled, { css } from 'styled-components';
import { API_ROOT } from '../../../api-config';
import moment from 'moment/moment';

const Label = styled.div`
  position: absolute;
  bottom: 2px;
  left: 2px;
  right: 2px;
  opacity: 0;
  background: rgba(0, 0, 0, 0.75);
  color: #ffffff;
  transition: opacity 0.25s ease;
  padding: 5px;
`;

const Close = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  opacity: 0;
  padding: 5px
  transition: opacity .25s ease, background .25s ease;
  border-radius: 6px;
  z-index: 10;
  
  &:hover {    
    background: #db2828;
    cursor: pointer;
  }
  
  > i {
    display: block;
    text-align: center;
    width: 16px;
    height: 16px;
    padding-top: 1px;
    margin: 0;
    line-height: 1;
  }
`;

const Replace = styled(Close)`
  left: 5px;
  right: auto;

  &:hover {
    background: #2185d0;
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${(props) =>
    props.detailed
      ? css`
          width: 100%;
          height: auto;
        `
      : css`
          width: 200px;
          height: 200px;
        `}
  padding: 2px;
  background-color: ${(props) =>
    props && props.selected ? '#00b5ad' : 'transparent'};
  transition: background-color 0.25s ease;

  &:hover > ${Label}, &:hover > ${Replace}, &:hover > ${Close} {
    opacity: 1;
  }

  > img,
  > video {
    max-width: 100%;
    max-height: 100%;
  }
`;

const Details = styled.div`
  width: 100%;
  margin-top: 6px;
  overflow: auto;

  > label {
    display: block;
    margin-top: 6px;
    font-size: 12px;
    line-height: 0.8;
    color: #999;
  }
`;

const renderDetailedInfo = (media) => {
  return (
    <Details>
      <label>Name</label>
      <div>{media.name}</div>
      <label>Created</label>
      <div>{moment(media.created).format('DD:MM:YYYY HH:mm:ss')}</div>
      <label>Type</label>
      <div>{media.type}</div>
      <label>Filename</label>
      <div>{media.filename}</div>
      <label>UUID</label>
      <div>{media.uuid}</div>
    </Details>
  );
};

const MediaPreview = ({
  media,
  selected,
  onClick,
  onReplace,
  onDelete,
  detailed,
}) => (
  <Wrapper detailed={detailed} selected={selected} onClick={onClick}>
    {onDelete && (
      <Close onClick={onDelete}>
        <i className="remove icon" />
      </Close>
    )}
    {onReplace && (
      <Replace onClick={onReplace}>
        <i className="exchange icon" />
      </Replace>
    )}
    {media.type && media.type.indexOf('video') === 0 ? (
      <video width="200" height="200" controls={detailed}>
        <source
          src={`${API_ROOT}/media/${media.owner_uuid}&${media.filename}`}
          type={media.type}
        />
        Your browser does not support the video tag.
      </video>
    ) : (
      <img
        src={`${API_ROOT}/media/${media.owner_uuid}&${media.filename}`}
        alt="_image"
      />
    )}
    {detailed ? (
      renderDetailedInfo(media)
    ) : (
      <Label>
        <p>
          {media.name}
          <br />
          {moment(media.created).format('DD:MM:YYYY HH:mm:ss')}
        </p>
      </Label>
    )}
  </Wrapper>
);

MediaPreview.defaultProps = {
  onClick: () => undefined,
};

export default MediaPreview;
