let backendHost;
let backendHostVM;
let backendHostVM4;

let hostname = window && window.location && window.location.hostname;
hostname = hostname.replace('www.', '');

console.log('API host: ', hostname);
console.log('REACT_APP_DEPLOY: ', process.env.REACT_APP_DEPLOY);

const deploy = process.env.REACT_APP_DEPLOY;

const validHosts = [
  'vuppetmaster.de',
  'login.virtual-trainer.de',
  'dashboard.virtual-trainer.de',
  'staging.virtual-trainer.de',
  'dev.virtual-trainer.de',
];

if (validHosts.includes(hostname.toLowerCase())) {
  // server
  if (hostname.startsWith('dev')) {
    backendHost = 'https://dev.virtual-trainer.de/api_trainer4';
    backendHostVM = 'https://vuppetmaster.de/vm';
    backendHostVM4 = 'https://vuppetmaster.de/vmapi/v4.dev';
  } else if (hostname.startsWith('login')) {
    backendHost = 'https://login.virtual-trainer.de/api_trainer4';
    backendHostVM = 'https://vuppetmaster.de/vm';
    backendHostVM4 = 'https://vuppetmaster.de/vmapi/v4.trainer';
  } else if (hostname.startsWith('dashboard')) {
    backendHost = 'https://dashboard.virtual-trainer.de/api_trainer4';
    backendHostVM = 'https://vuppetmaster.de/vm';
    backendHostVM4 = 'https://vuppetmaster.de/vmapi/v4.trainer';
  } else if (hostname.startsWith('staging')) {
    backendHost = 'https://staging.virtual-trainer.de/api_trainer4';
    backendHostVM = 'https://vuppetmaster.de/vm';
    backendHostVM4 = 'https://vuppetmaster.de/vmapi/v4.trainer';
  } else {
    backendHost = 'https://vuppetmaster.de/api_trainer4';
    backendHostVM = 'https://vuppetmaster.de/vm';
    backendHostVM4 = 'https://vuppetmaster.de/vmapi/v4.trainer';
  }
} else {
  // local
  backendHost = 'http://localhost:10443/api_trainer4';
  backendHostVM = 'http://127.0.0.1/vm';
  backendHostVM4 = 'http://localhost:9443/vmapi/v4.local';
}

console.log('API_ROOT ', backendHost);

export const API_ROOT = `${backendHost}`;
export const API_ROOT_VM = `${backendHostVM}`;
export const API_ROOT_VM4 = `${backendHostVM4}`;
