import React, { Component } from 'react';
import {
  Dimmer,
  Loader,
  Table,
  Header,
  Checkbox,
  Icon,
  Grid,
  Dropdown,
  Button,
  Segment,
  Message,
  Flag,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import store from '../../../redux/store';

import EditInline from '../../1_atom/EditLine/EditLine';
import ModalOkCancel from '../../3_organisms/ModalOkCancel';

import DatePicker from 'react-datepicker';

import { actionSetModule } from '../../../redux/actions/actionModule';

import RestAPI from '../../../RestAPI';
import { actionSetMembers } from '../../../redux/actions/actionUser';
import { scopeIncludesOneOf } from '../../../helpers/scopecheck';
import { arrayDropDown } from '../../../helpers/arrayDropDown';

import { allLanguages } from '../../../helpers/alllanguages';
import { updateModuleFromVM } from '../../../helpers/module.helper';

const clone = require('clone');

const MemberList = (props) => {
  var outputNodes =
    props.members &&
    props.members
      .filter((m) => m.company_uuid !== undefined && m.company_uuid !== null)
      .filter((m) => m.company_uuid === props.module.company_uuid)
      .filter((m) => {
        if (props.exact)
          return m.name.localeCompare(props.filter) === 0 ? true : false;
        else return m.name.startsWith(props.filter);
      })
      .filter((m) => {
        let result =
          m.modules_uuid &&
          m.modules_uuid.find((uuid) => uuid === props.module.uuid)
            ? true
            : false;
        if (props.all) return !result;
        else return result;
      })
      .map((member, index) => {
        return (
          <Table.Row key={index}>
            <Table.Cell />
            <Table.Cell>
              <Header textAlign="left">{member.name}</Header>
            </Table.Cell>
          </Table.Row>
        );
      });

  return outputNodes ? outputNodes : '';
};

const MemberTable = (props) => {
  return (
    <div id="mygrid">
      <Table compact size="small" celled selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell singleLine>Select</Table.HeaderCell>
            <Table.HeaderCell singleLine>Name</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          <MemberList {...props} />
        </Table.Body>
        <Table.Footer fullWidth>
          <Table.Row>
            <Table.HeaderCell colSpan="6" />
          </Table.Row>
        </Table.Footer>
      </Table>
    </div>
  );
};

const Languages = (props) => {
  return (
    <Table celled fixed singleLine collapsing>
      <Table.Body>
        {props.languages &&
          props.languages.map((lang) => {
            const entry = allLanguages.find(
              (entry) => entry.code === lang.code
            );
            return (
              <Table.Row key={lang.code}>
                <Table.Cell>
                  <Checkbox
                    checked={lang && lang.active === true}
                    onChange={(event, data) => {
                      const newLang = props.languages.map((entry) => {
                        if (entry.code === lang.code) {
                          entry.active = data.checked;
                        }
                        return entry;
                      });
                      props.onChange(newLang);
                    }}
                  />
                </Table.Cell>
                <Table.Cell>
                  <div>
                    <Flag name={lang.code === 'en' ? 'gb' : lang.code} />
                  </div>
                </Table.Cell>
                <Table.Cell>
                  <div>{entry && entry.text}</div>
                </Table.Cell>
                <Table.Cell>
                  {props.module.labels && props.module.labels[lang.code] && (
                    <div>{props.module.labels[lang.code]}</div>
                  )}
                </Table.Cell>
              </Table.Row>
            );
          })}
      </Table.Body>
    </Table>
  );
};

class Module extends Component {
  constructor(props) {
    super(props);

    let optProjects = [];
    if (this.props.vmprojects) {
      optProjects = this.props.vmprojects
        .filter((project) => project.uuid)
        .map((project) => {
          return {
            key: project.uuid,
            text: `${project.name} (${project.user})`,
            value: project.key,
          };
        });
    }

    this.state = {
      name: '',
      period: false,
      startDate: null,
      endDate: null,
      status: null,

      moduleToDelete: null,

      filterall: '',
      filterallexact: false,
      filterselect: '',
      filterselectexact: false,

      showProgress: null,
      showPause: null,
      confirmUserID: null,

      languages: null,
      storybuilder_key: null,
      storybuilder_name: null,

      optProjects,
    };
  }

  componentDidMount() {
    /*    if (
      this.props.vmprojects && 
      this.props.vmprojects.length > 0 && 
      this.props.module &&
      !this.props.module.languages &&
      this.props.module.storybuilder_key
    ) {
      this.setStorybuilderKey(this.props.module.storybuilder_key);
    }*/
  }

  updateView() {
    RestAPI.members().then((result) => {});
  }

  onApply() {
    let module = {
      name: this.state.name !== '' ? this.state.name : this.props.module.name,
      uuid: this.props.module.uuid,
      labels: this.props.module.labels,
      tutorial_uuid: this.props.module.tutorial_uuid,
      storybuilder_key: this.state.storybuilder_key
        ? this.state.storybuilder_key
        : this.props.module.storybuilder_key,
      storybuilder_name: this.state.storybuilder_name
        ? this.state.storybuilder_name
        : this.props.module.storybuilder_name,
      company_uuid: this.props.module.company_uuid,
      tag: this.props.module.tag,
      status: this.state.status ? this.state.status : this.props.module.status,
      languages: this.state.languages
        ? this.state.languages
        : this.props.module.languages,
      showProgress:
        this.state.showProgress !== null
          ? this.state.showProgress
          : this.props.module.showProgress,
      showPause:
        this.state.showPause !== null
          ? this.state.showPause
          : this.props.module.showPause,
      confirmUserID:
        this.state.confirmUserID !== null
          ? this.state.confirmUserID
          : this.props.module.confirmUserID,
    };

    this.setState({ wait: true });

    store.dispatch(actionSetModule(module));
    RestAPI.updateModule(module).then(() => {
      let count = this.props.members.length;
      this.props.members.forEach((m) => {
        RestAPI.updateMember(m).then((res) => {
          if (--count === 0) {
            RestAPI.modules();
            this.setState({ wait: false });
            this.props.history.push('/admin/modules');
          }
        });
      });
    });
  }

  onRemoveFromModule() {
    let members = clone(this.props.members);
    members
      .filter((m) => m.company_uuid !== undefined && m.company_uuid !== null)
      .filter((m) => m.company_uuid === this.props.module.company_uuid)
      .filter((m) => {
        if (this.state.filterselectexact)
          return m.name.localeCompare(this.state.filterselect) === 0
            ? true
            : false;
        else return m.name.startsWith(this.state.filterselect);
      })
      .filter((m) => {
        return m.modules_uuid &&
          m.modules_uuid.find((uuid) => uuid === this.props.module.uuid)
          ? true
          : false;
      })
      .forEach((m) => {
        m.modules_uuid = m.modules_uuid.filter(
          (uuid) => uuid !== this.props.module.uuid
        );
      });
    store.dispatch(actionSetMembers(members));
  }

  onAddToModule() {
    let members = clone(this.props.members);
    members
      .filter((m) => m.company_uuid !== undefined && m.company_uuid !== null)
      .filter((m) => m.company_uuid === this.props.module.company_uuid)
      .filter((m) => {
        if (this.state.filterallexact)
          return m.name.localeCompare(this.state.filterall) === 0
            ? true
            : false;
        else return m.name.startsWith(this.state.filterall);
      })
      .filter((m) => {
        return m.modules_uuid &&
          m.modules_uuid.find((uuid) => uuid === this.props.module.uuid)
          ? false
          : true;
      })
      .forEach((m) => {
        if (!m.modules_uuid) m.modules_uuid = [];
        m.modules_uuid.push(this.props.module.uuid);
      });
    store.dispatch(actionSetMembers(members));
  }

  setStorybuilderKey(key) {
    let project = this.props.vmprojects.find((project) => project.key === key);
    if (!project) return;

    let module = clone(this.props.module);
    module.storybuilder_key = key;
    updateModuleFromVM(module, this.props.vmprojects);
    store.dispatch(actionSetModule(module));
    this.setState({
      languages: module.languages,
      storybuilder_key: key,
      storybuilder_name: project.name,
    });
  }

  render() {
    const { tutorials, companies, module, login, vmprojects } = this.props;
    const {
      optProjects,
      moduleToDelete,
      status,
      languages,
      period,
      startDate,
      endDate,
      showPause,
      showProgress,
      confirmUserID,
    } = this.state;

    const canEdit = scopeIncludesOneOf(login && login.scope, ['root', 'admin']);

    const optCompanies = arrayDropDown(companies);

    /*    let dateStart = null;
    if (module) dateStart = startDate ? startDate : module.startDate;
    let dateEnd = null;
    if (module) dateEnd = endDate ? endDate : module.endDate;
*/
    let selProject =
      module &&
      optProjects.find((entry) => entry.value === module.storybuilder_key);

    let currentLanguages = languages;
    if (!currentLanguages)
      currentLanguages = this.props.module && this.props.module.languages;

    return (
      <div>
        <Message>
          <h4>Settings:</h4>
          {canEdit ? (
            <EditInline
              label={'Name:'}
              text={module && module.name}
              onChanged={(text) => this.setState({ name: text })}
            />
          ) : (
            <div>Name: {module && module.name}</div>
          )}
          <div>
            <Checkbox
              label="Active"
              checked={(status ? status : module.status) === 'active'}
              onChange={(event, data) => {
                this.setState({
                  status: data.checked ? 'active' : 'inactive',
                });
              }}
            />
          </div>
          <div>
            <Checkbox
              label="Progress"
              checked={
                showProgress !== null ? showProgress : module.showProgress
              }
              onChange={(event, data) => {
                this.setState({
                  showProgress: data.checked,
                });
              }}
            />
          </div>
          <div>
            <Checkbox
              label="Pause"
              checked={showPause !== null ? showPause : module.showPause}
              onChange={(event, data) => {
                this.setState({
                  showPause: data.checked,
                });
              }}
            />
          </div>
          <div>
            <Checkbox
              label="Confirm UserID"
              checked={
                confirmUserID !== null ? confirmUserID : module.confirmUserID
              }
              onChange={(event, data) => {
                this.setState({
                  confirmUserID: data.checked,
                });
              }}
            />
          </div>
        </Message>
        <Message>
          <h4>Languages and Labels:</h4>
          {module && module.company_uuid && (
            <Languages
              module={module}
              languages={currentLanguages}
              onChange={(languages) => {
                this.setState({ languages });
              }}
            ></Languages>
          )}
        </Message>
        <Message>
          <h4>Period:</h4>
          {module && module.company_uuid && (
            <div>
              <Checkbox
                label="active"
                checked={period}
                onChange={(event, data) => {
                  this.setState({
                    period: data.checked,
                  });
                }}
              />
              <div>
                <div>
                  Start:
                  {startDate && (
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => this.setState({ startDate: date })}
                    />
                  )}
                </div>
                <div>
                  End:
                  {endDate && (
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => this.setState({ endDate: date })}
                    />
                  )}
                </div>
              </div>
              )}
            </div>
          )}
        </Message>
        <Message>
          <h4>Members:</h4>
          {module && module.company_uuid && (
            <Grid columns={3} divided>
              <Grid.Row>
                <Grid.Column>
                  <EditInline
                    label={'Filter:'}
                    text={this.state.filterall}
                    onChanged={(text) => this.setState({ filterall: text })}
                  />
                  <Checkbox
                    label="exact"
                    onChange={(event, data) => {
                      this.setState({ filterallexact: data.checked });
                    }}
                  />
                  <MemberTable
                    all={true}
                    exact={this.state.filterallexact}
                    filter={this.state.filterall}
                    {...this.props}
                  />
                </Grid.Column>
                <Grid.Column width={1}>
                  <Button
                    color="green"
                    onClick={() => {
                      this.onAddToModule();
                    }}
                  >
                    <Icon name="angle double right" />
                  </Button>
                  <br />
                  <Button
                    color="red"
                    onClick={() => {
                      this.onRemoveFromModule();
                    }}
                  >
                    <Icon name="angle double left" />
                  </Button>
                </Grid.Column>
                <Grid.Column>
                  <EditInline
                    label={'Filter:'}
                    text={this.state.filterselect}
                    onChanged={(text) => this.setState({ filterselect: text })}
                  />
                  <Checkbox
                    label="exact"
                    onChange={(event, data) => {
                      this.setState({ filterselectexact: data.checked });
                    }}
                  />
                  <MemberTable
                    select={true}
                    exact={this.state.filterselectexact}
                    filter={this.state.filterselect}
                    {...this.props}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}
        </Message>
        <Message>
          <h4>Vuppetmaster:</h4>
          {canEdit && (
            <div>
              <Segment>
                <table>
                  <tbody>
                    <tr>
                      <td> Storybuilder Project:</td>
                      <td>
                        {vmprojects.length > 0 ? (
                          <Dropdown
                            selection
                            fluid
                            search
                            options={optProjects}
                            placeholder="Choose Project"
                            value={selProject && selProject.value}
                            onChange={(e, d) => {
                              this.setStorybuilderKey(d.value);
                            }}
                          />
                        ) : (
                          <div>{module && module.storybuilder_name}</div>
                        )}
                      </td>
                      <td>Key: {module && module.storybuilder_key}</td>
                    </tr>
                    <tr>
                      <td>Company:</td>
                      <td>
                        <Dropdown
                          placeholder="Select Company"
                          fluid
                          search
                          value={module && module.company_uuid}
                          selection
                          options={optCompanies}
                          onChange={(e, d) => {
                            let m = clone(module);
                            m.company_uuid = d.value;
                            store.dispatch(actionSetModule(m));
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Tag:</td>
                      <td>
                        <EditInline
                          fluid
                          text={module && module.tag}
                          onChanged={(text) => {
                            store.dispatch(
                              actionSetModule({ ...module, tag: text })
                            );
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Segment>
            </div>
          )}
        </Message>
        {this.state.wait && (
          <Dimmer active>
            <Loader />
          </Dimmer>
        )}

        <Button color="green" onClick={() => this.onApply()}>
          Apply
        </Button>
        <ModalOkCancel
          text="Delete Company?"
          color="red"
          open={moduleToDelete != null}
          onOK={() => {
            this.onDelete(moduleToDelete);
            this.setState({ moduleToDelete: null });
          }}
          onCancel={() => this.setState({ moduleToDelete: null })}
        />
      </div>
    );
  }
}

// You have to connect() to any reducers that you wish to connect to yourself
Module = connect(
  (state) => ({
    login: state.login.login,
    module: state.module.module,
    members: state.member.members,
    tutorials: state.tutorial.tutorials,
    tutorial: state.tutorial.tutorial,
    companies: state.company.companies,
    vmprojects: state.vuppetmaster.vmprojects,
  }),
  {} // bind account loading action creator
)(Module);

export default withRouter(Module);
