import React, { Component } from 'react';

import AceEditor from 'react-ace';
 
/*
import 'brace/mode/json';
import 'brace/theme/monokai';
import 'brace/ext/language_tools';
*/

class ViewJson extends Component {

	constructor(props){
    	super(props);
    	this.state={
			source: null,   // string
    	}
  	}

	componentWillReceiveProps(nextProps)
	{
		if (nextProps.source !== this.props.source)
		{
//			console.log('#### componentWillReceiveProps ',nextProps.source)
//			this.setState( { source: null});
		}
		
	}
  
	render() {
		return (
<div id='viewjson'>
	<AceEditor
		height='300px'
		width='100%'
		mode="json"
		theme="monokai"
		name="viewjson"
		onLoad={this.onLoad}
		onChange={ (newValue)=> { 
//			console.log('AceEditor onChange ',newValue);
			this.setState( { source:newValue}); 
			this.props.update(newValue);  
		}}
		fontSize={14}
		showPrintMargin={true}
		showGutter={true}
		highlightActiveLine={true}
		value={this.state.source!==null?this.state.source:this.props.source } 
		setOptions={{
			enableBasicAutocompletion: false,
			enableLiveAutocompletion: false,
			enableSnippets: false,
			showLineNumbers: true,
			tabSize: 2,
		}}/>
</div>
    );
  }
}

export default ViewJson;
