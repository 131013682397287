import React, { Component } from 'react';
import {
  Button,
  Form,
  Responsive,
  Message,
  Icon,
  Image,
} from 'semantic-ui-react';

import { withRouter } from 'react-router-dom';

import styles from './Login.module.scss';

import HelpModal from '../../../1_atom/HelpModal/HelpModal';
import Copyright from '../../../1_atom/Copyright/Copyright';
import RestAPI from '../../../../RestAPI';

const iconError = 'exclamation triangle';
const iconCheck = 'circle notched';
const iconOk = 'check';

const maxWidth = 500;

const ViewForm = (props) => (
  <div>
    <h1>
      Herzlichen Willkommen
      <p />
      zu Ihrer Schulung!
    </h1>
    <p />
    Bitte loggen Sie sich mit den Ihnen vorliegenden Zugangsdaten ein:
    <p />
    <Form size={props.size}>
      {false && (
        <Form.Input
          fluid
          icon="home"
          iconPosition="left"
          placeholder="Firmen-ID"
          onChange={(d, e) => props.onChange('company', e.value)}
        />
      )}
      <Form.Input
        fluid
        icon="user"
        iconPosition="left"
        placeholder="Login-Name"
        onChange={(d, e) => props.onChange('user', e.value)}
      />
      <Form.Input
        fluid
        icon="lock"
        iconPosition="left"
        placeholder="Passwort"
        type="password"
        onChange={(d, e) => props.onChange('passwd', e.value)}
      />
      <Button
        size={props.size}
        color={'blue'}
        disabled={!props.active}
        fluid
        onClick={() => props.onLogin()}
      >
        Login
      </Button>
      <Message
        hidden={props.message === ''}
        icon
        negative={props.icon === iconError}
        positive={props.icon === iconOk}
      >
        <Icon name={props.icon} loading={props.icon === iconCheck} />
        <Message.Content>
          <Message.Header>{props.message}</Message.Header>
        </Message.Content>
      </Message>
    </Form>
  </div>
);

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: '',
      passwd: '',
      company: '',
      message: '',
      showIcon: false,
      showMessage: '',
      openHelp: false,
      buttonactive: true,
    };
  }

  onChange(type, data) {
    switch (type) {
      case 'company':
        this.setState({ company: data });
        break;
      case 'user':
        this.setState({ user: data });
        break;
      case 'passwd':
        this.setState({ passwd: data });
        break;
      default:
        break;
    }
  }

  onLogin() {
    this.setState({
      showIcon: iconCheck,
      buttonactive: false,
      showMessage: 'Überprüfen',
    });
    RestAPI.login(this.state.company, this.state.user, this.state.passwd)
      .then((res) => {
        if (process.env.NODE_ENV !== 'production') console.log(res);
        if (res) {
          if (res.ok) {
            this.setState({ showMessage: 'ok', showIcon: iconOk });
            setTimeout(() => {
              // show the nice thump 200 ms
              this.props.onLogin(res);
            }, 200);
          } else {
            console.error('invalid user or password');
            this.setState({
              showMessage: 'Ungültige Zugangsdaten',
              showIcon: iconError,
              buttonactive: true,
            });
          }
        }
      })
      .catch((err) => {
        this.setState({ showProgress: false });
        console.error(err);
        this.setState({
          showMessage: 'Verbindungsfehler',
          showIcon: iconError,
          buttonactive: true,
        });
      });
  }

  render() {
    return (
      <div className={styles.main}>
        <div className={styles.flex_container}>
          <div className={styles.flex_item1}>
            <div className={styles.image}>
              <Image
                src={process.env.PUBLIC_URL + '/assets/gloria_walkpresent.png'}
              />
            </div>
          </div>
          <div className={styles.flex_item2}>
            <div className={styles.loginform}>
              <Responsive minWidth={maxWidth}>
                <ViewForm
                  onChange={(type, data) => {
                    this.onChange(type, data);
                  }}
                  onLogin={() => {
                    this.onLogin();
                  }}
                  message={this.state.showMessage}
                  icon={this.state.showIcon}
                  active={this.state.buttonactive}
                />
              </Responsive>
              <Responsive maxWidth={maxWidth - 1}>
              <ViewForm
                  size={'mini'}
                  onChange={(type, data) => {
                    this.onChange(type, data);
                  }}
                  onLogin={() => {
                    this.onLogin();
                  }}
                  message={this.state.showMessage}
                  icon={this.state.showIcon}
                  active={this.state.buttonactive}
                />
              </Responsive>
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <Copyright />
        </div>

        <div className={styles.helpbutton}>
          <Button
            icon
            size={'mini'}
            onClick={() => {
              this.setState({ openHelp: true });
            }}
          >
            Hilfe
            <Icon name={'help'} />
          </Button>
        </div>
        <HelpModal
          open={this.state.openHelp}
          onClose={() => {
            this.setState({ openHelp: false });
          }}
        />
      </div>
    );

  }
}

export default withRouter(LoginForm);
