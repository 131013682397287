

const initialState = {
  tutorials: null,
  tutorial: null,
};


const reducerTutorial = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_TUTORIALS':
      return {
        ...state,
        tutorials: action.tutorials,
      };
		case 'SET_TUTORIAL':
      return {
        ...state,
        tutorial: action.tutorial,
      };
    default:
      return state;
  }
};

export default reducerTutorial;
