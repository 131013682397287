import React, { Component } from 'react';
import { List, Dropdown, Button, Checkbox } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './Members.css';

import store from '../../../redux/store';
import {
  scopeIncludesOneOf,
  scopeRemove,
  scopeAdd,
} from '../../../helpers/scopecheck';

import EditLine from '../../1_atom/EditLine/EditLine.js';
import styled from 'styled-components';

import { arrayDropDown } from '../../../helpers/arrayDropDown';

import { actionSetMember } from '../../../redux/actions/actionUser';
import { actionSetSession } from '../../../redux/actions/actionSession';

import RestAPI from '../../../RestAPI';
var clone = require('clone');

const InlineDiv = styled.div`
  display: inline-block;
  width: '100px';
`;

class Member extends Component {
  onApply() {
    if (!this.props.member) {
      return false;
    }

    let member = clone(this.props.member);

    RestAPI.updateMember(member).then(() => {
      RestAPI.members();
      this.props.history.push('/admin/members');
    });
  }

  render() {
    const { companies, member, modules, sessions } = this.props;

    let opt = arrayDropDown(companies);

    let sessionsMember =
      sessions && sessions.filter((s) => s.member_uuid === member.uuid);

    const feedbackMember = scopeIncludesOneOf(member && member.scope, ['feedback']);
    const demoMember = scopeIncludesOneOf(member && member.scope, ['demo']);
    const debugMember = scopeIncludesOneOf(member && member.scope, ['debug']);
    const controlMember = scopeIncludesOneOf(member && member.scope, ['control']);

    return (
      <div className="member">
        {member && (
          <div>
            <div>
              <EditLine
                label="Name:"
                text={member.name}
                onChanged={(text) => {
                  let m = clone(member);
                  m.name = text;
                  store.dispatch(actionSetMember(m));
                }}
              />
            </div>
            <div>
              <EditLine
                label="Password:"
                text={member.passwd}
                onChanged={(text) => {
                  let m = clone(member);
                  m.passwd = text;
                  store.dispatch(actionSetMember(m));
                }}
              />
            </div>
            <div>
              <Checkbox
                label="Passive"
                toggle
                checked={member.passive}
                onChange={(event, data) => {
                  let m = clone(member);
                  if (!m.scope) m.scope = [];
                  m.passive = data.checked;
                  store.dispatch(actionSetMember(m));
                }}
              />
            </div>
            <div>
              <Checkbox
                label="Demo"
                toggle
                checked={demoMember}
                onChange={(event, data) => {
                  let m = clone(member);
                  if (!m.scope) m.scope = [];
                  if (data.checked) scopeAdd(m.scope, 'demo');
                  else scopeRemove(m.scope, 'demo');
                  store.dispatch(actionSetMember(m));
                }}
              />
            </div>
            <div>
              <Checkbox
                label="Debug"
                toggle
                checked={debugMember}
                onChange={(event, data) => {
                  let m = clone(member);
                  if (!m.scope) m.scope = [];
                  if (data.checked) scopeAdd(m.scope, 'debug');
                  else scopeRemove(m.scope, 'debug');
                  store.dispatch(actionSetMember(m));
                }}
              />
            </div>
            <div>
              <Checkbox
                label="Feedback"
                toggle
                checked={feedbackMember}
                onChange={(event, data) => {
                  let m = clone(member);
                  if (!m.scope) m.scope = [];
                  if (data.checked) scopeAdd(m.scope, 'feedback');
                  else scopeRemove(m.scope, 'feedback');
                  store.dispatch(actionSetMember(m));
                }}
              />
            </div>
            <div>
              <Checkbox
                label="Control"
                toggle
                checked={controlMember}
                onChange={(event, data) => {
                  let m = clone(member);
                  if (!m.scope) m.scope = [];
                  if (data.checked) scopeAdd(m.scope, 'control');
                  else scopeRemove(m.scope, 'control');
                  store.dispatch(actionSetMember(m));
                }}
              />
            </div>
          </div>
        )}
        <br />
        Modules:
        <br />
        <List
          items={
            member &&
            member.modules_uuid &&
            member.modules_uuid.map((module_uuid, index) => {
              let module = modules.find((m) => m.uuid === module_uuid);
              if (module) {
                let fin =
                  sessionsMember &&
                  sessionsMember.find(
                    (s) => s.module_uuid === module_uuid && s.status.finished
                  );
                let name = module.name; // + fin?'👍':'';
                name += fin ? '👍' : '';

                let module_sessions =
                  sessionsMember &&
                  sessionsMember.filter((s) => s.module_uuid === module_uuid);
                let sessions_out =
                  module_sessions &&
                  module_sessions.map((s, index) => {
                    return (
                      <Button
                        key={index}
                        onClick={() => {
                          store.dispatch(actionSetSession(s));
                          // set tutorial
                          if (module && module.tutorial_uuid) {
                            RestAPI.tutorial(module.tutorial_uuid);
                          }

                          this.props.history.push('/admin/session/' + s.uuid);
                        }}
                      >
                        Edit Sessions {s.status.finished ? '👍' : ''}
                      </Button>
                    );
                  });

                return (
                  <div key={index}>
                    {name}
                    <br />
                    {sessions_out}
                  </div>
                );
              } else return null;
            })
          }
        />
        <br />
        <InlineDiv>
          Comany:
          <Dropdown
            placeholder="Select Company"
            value={member && member.company_uuid}
            selection
            options={opt}
            onChange={(e, d) => {
              console.log('#### onDropdownChanged', d.value);

              let u = clone(member);
              u.company_uuid = d.value;
              store.dispatch(actionSetMember(u));
            }}
          />
        </InlineDiv>
        <br />
        <br />
        <br />
        <Button color="green" onClick={() => this.onApply()}>
          Apply
        </Button>
      </div>
    );
  }
}

// You have to connect() to any reducers that you wish to connect to yourself
Member = connect(
  (state) => ({
    member: state.member.member,
    tutorials: state.tutorial.tutorials,
    modules: state.module.modules,
    sessions: state.session.sessions,
    companies: state.company.companies,
  }),
  {} // bind account loading action creator
)(Member);

export default withRouter(Member);
