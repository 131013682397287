

export const actionSetTutorials = (tut) => ({
	type: 'SET_TUTORIALS',
	tutorials: tut
});

export const actionSetTutorial = (tut) => ({
	type: 'SET_TUTORIAL',
	tutorial: tut,
});
