import React, { Component } from 'react';
import { Dropdown, Segment } from 'semantic-ui-react';

import EditLine from '../../1_atom/EditLine/EditLine.js';

import { connect } from 'react-redux';
import store from './../../../redux/store';
import { actionSetNode } from '../../../redux/actions/actionPage';

class NodeScript extends Component {
  render() {
    let opt = [];
    if (this.props.scripts) {
      this.props.scripts.forEach((element) => {
        opt.push({
          key: element.name,
          text: element.name,
          value: element.name,
        });
      });
    }

    return (
      <Segment>
        {this.props.node.name}
        <EditLine
          label="Start:"
          text={this.props.node.start}
          onChanged={(text) =>
            store.dispatch(actionSetNode({ ...this.props.node, start: text }))
          }
        />
        <Dropdown
          placeholder="Select"
          value={
            this.props.node.data && this.props.node.data.script
              ? this.props.node.data.script
              : ''
          }
          selection
          options={opt}
          onChange={(e, d) => {
            console.log('#### onDropdownChanged', d.value);

            let node = JSON.parse(JSON.stringify(this.props.node));
            if (!node.data) node.data = { page: '' };
            node.data = { ...node.data, script: d.value };
            node.name = node.data.script;

            store.dispatch(actionSetNode(node));
          }}
        />
      </Segment>
    );
  }
}

NodeScript = connect(
  (state) => ({
    node: state.page.node,
    scripts: state.main.scripts,
  }),
  {}
)(NodeScript);

export default NodeScript;
