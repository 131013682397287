import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class ThemeSwitch extends Component {
  state = {};

  render() {
    const style = {
      height: '100%',
      width: '100%',
      backgroundColor: '#ffffff',
      position: 'absolute',
      zIndex: '1000',
    };

    return <div style={style}></div>;
  }
}

export default withRouter(ThemeSwitch);
