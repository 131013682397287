const initialState = {
  grid: {
    active: false,
    horizontalLines: 0,
    verticalLines: 0,
  },
  themeswitch: false,
  debug: false,
  control: false,
};

const reducerSettings = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SETTINGS_GRID':
      return {
        ...state,
        grid: action.grid,
      };
    case 'SET_SETTINGS_DEBUG':
      return {
        ...state,
        debug: action.debug,
      };
    case 'SET_SETTINGS_CONTROL':
      return {
        ...state,
        control: action.control,
      };
    case 'SET_SETTINGS_THEMESWITCH':
      return {
        ...state,
        themeswitch: action.themeswitch,
      };
    default:
      return state;
  }
};

export default reducerSettings;
