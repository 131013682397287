import React, { Component } from 'react';
import { Segment } from 'semantic-ui-react'


import EditLine from '../../1_atom/EditLine/EditLine.js';
import ViewJson from '../../3_organisms/ViewJson.js';
import SelectTheme from './SelectTheme'
import CssResult from './CssResult'

import { connect } from 'react-redux';
import store from './../../../redux/store';
import { actionSetNode } from '../../../redux/actions/actionPage';

class NodeText extends Component {

	constructor(props) {
		super(props);
		this.state = {
		}
	}

	render() {
		return (
			<Segment>
				{this.props.node.name}
				<EditLine label='Name:' text={this.props.node.name} onChanged={(text) => store.dispatch(actionSetNode( { ...this.props.node, name:  text } )) } />
				<EditLine label='Start:' text={this.props.node.start} onChanged={(text) => store.dispatch(actionSetNode( { ...this.props.node, start:  text } )) } />
				<EditLine label='End:' text={this.props.node.end} onChanged={(text) => store.dispatch(actionSetNode( { ...this.props.node, end:  text } )) } />
				<EditLine label='Text:' text={this.props.node.data?this.props.node.data.text:''} onChanged={(text) => {

					if (!this.props.node.data)
						this.props.node.data = { text: text	};
					this.props.node.data = { ...this.props.node.data, text: text }
					store.dispatch(actionSetNode(this.props.node)) }
				} />
				
				<SelectTheme />
				<CssResult theme={this.props.node.theme} />
				<br/>
				<ViewJson
					update={(css) => { 
						if (this.props.onCssChanged(css))
							store.dispatch(actionSetNode( { ...this.props.node, css: JSON.parse(css) } )) 
					}}
					source={ JSON.stringify(this.props.node.css,null,4) }
					title={''}
				/>
			</Segment>
		)
	}
}

NodeText = connect(
	state => ({
		node: state.page.node,
	}),
	{},
)(NodeText);

export default NodeText;

