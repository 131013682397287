import { API_ROOT, API_ROOT_VM } from './api-config';

import store from './redux/store';
import { actionSetLogin } from './redux/actions/actionLogin';
import {
  actionSetMembers,
  actionSetMember,
  actionSetUsers,
  actionSetUser,
} from './redux/actions/actionUser';
import {
  actionSetTutorials,
  actionSetTutorial,
} from './redux/actions/actionTutorial';
import {
  actionSetSession,
  actionSetSessions,
} from './redux/actions/actionSession';
import {
  actionSetFeedbacks,
} from './redux/actions/actionFeedback';
import { actionSetCompanies } from './redux/actions/actionCompany';
import {
  actionSetModule,
  actionSetModules,
} from './redux/actions/actionModule';
import {
  actionSetScripts,
  actionSetVariables,
  actionSetThemes,
} from './redux/actions/actionMain';
import {
  actionAddMedia,
  actionRemoveMedia,
  actionSetMedia,
  actionUpdateMedia,
} from './redux/actions/actionMedia';
import superagent from 'superagent';

import {
  convertTutorialFromDB,
  convertTutorialToDB,
} from './helpers/tutorial_manager';

const post = async (url, data) => {
  return await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(data),
    mode: 'cors',
  })
    .then((response) => {
      if (!response) return null;
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        return response.json();
      } else {
        return response.text().then((text) => ({
          ok: false,
          status: response.status,
          error: text,
        }));
      }
    })
    .catch((e) => {
      return { ok: false, error: e.message };
    });
};

const postForm = async (url, data) => {
  return await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
    },
    credentials: 'include',
    body: data,
    mode: 'cors',
  }).then((response) => {
    if (!response) return null;
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
      return response.json();
    } else {
      return response
        .text()
        .then((text) => ({ ok: false, status: response.status, error: text }));
    }
  });
};

const get = async (url, queryData) => {
  const query = queryData
    ? '?' +
      Object.keys(queryData)
        .map(
          (k) => encodeURIComponent(k) + '=' + encodeURIComponent(queryData[k])
        )
        .join('&')
    : '';
  return await fetch(url + query, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    mode: 'cors',
  })
    .then((response) => {
      if (!response) return null;
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        return response.json();
      } else {
        return response.text().then((text) => ({ ok: false, error: text }));
      }
    })
    .catch((e) => {
      return { ok: false, error: e.message };
    });
};

const getBinary = async (url, queryData) => {
  const query = queryData
    ? '?' +
      Object.keys(queryData)
        .map(
          (k) => encodeURIComponent(k) + '=' + encodeURIComponent(queryData[k])
        )
        .join('&')
    : '';
  return await fetch(url + query, {
    method: 'GET',
    credentials: 'include',
    mode: 'cors',
  })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      return { ok: false, error: e.message };
    });
};

const downloadAsTextFile = (filename, text) => {
  const element = document.createElement('a');
  element.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
  );
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

export const graphQL = async (query) => {
  let result = post(API_ROOT + '/graphql', { query });
  return result;
};

export const toGraphQL = (object, addBrackets = true) => {
  if (!object) {
    return object;
  }

  const toString = (value) => {
    switch (typeof value) {
      case 'string':
        return JSON.stringify(value);
      case 'number':
        return value + '';
      case 'object':
        return toGraphQL(value);
      default:
        return value;
    }
  };

  if (object.length !== undefined) {
    const values = object.map((value) => toString(value)).join(', ');
    return addBrackets ? `[${values}]` : values;
  } else {
    const values = Object.keys(object)
      .filter((key) => object[key] !== undefined)
      .map((key) => key + ':' + toString(object[key]))
      .join(', ');

    return addBrackets ? `{${values}}` : values;
  }
};

// AUTHENTICATION

export const login = async (company, name, passwd) => {
  const result = await post(API_ROOT + '/auth/login', {
    company,
    name: name.trim(),
    passwd: passwd.trim(),
  });
  if (result && result.ok) {
    store.dispatch(actionSetLogin(result));
  }
  return result;
};

export const logout = async () => {
  const result = await get(API_ROOT + '/auth/logout');
  if (result && result.ok) {
    store.dispatch(actionSetLogin());
  }
  return result;
};

export const authStatus = async () => {
  const result = await get(API_ROOT + '/auth/status');
  if (result && result.ok) {
    store.dispatch(actionSetLogin(result));
  }
  return result;
};

export const getipdata = async (ip) => {
  const result = await post(API_ROOT + '/auth/getipdata', { ip: ip });
  return result;
};

// USER

const user = async (uuid) => {
  const result = await graphQL(
    `{users(filter: {uuid: {EQ: "${uuid}"}}) { name, uuid, email, companies_uuid, role } }`
  );
  if (result && result.data && result.data.users) {
    store.dispatch(
      actionSetUser(result.data && result.data.users && result.data.users[0])
    );
  }
  return result;
};

export const users = async () => {
  const result = await graphQL(
    '{users { name, uuid, email, companies_uuid, role } }'
  );
  if (result && result.data && result.data.users) {
    store.dispatch(actionSetUsers(result.data && result.data.users));
  }
  return result;
};

export const createUser = async (user) => {
  return graphQL(`mutation { users_create(${toGraphQL(user, false)}) { ok } }`);
};

export const updateUser = async (user) => {
  return graphQL(
    `mutation { users_update(filter: {uuid: {EQ: "${
      user.uuid
    }"}}, update: {set: ${toGraphQL(user)} }) { ok } }`
  );
};

export const deleteUser = async (user) => {
  return graphQL(
    `mutation { users_delete(filter: {uuid: {EQ: "${user.uuid}"}}) { ok } }`
  );
};

// MEMBERS

export const members = async () => {
  const result = await graphQL(
    '{members { name, uuid, email, modules_uuid, company_uuid, passive, scope } }'
  );
  if (result && result.data && result.data.members) {
    store.dispatch(actionSetMembers(result.data && result.data.members));
  }
  return result;
};

export const member = async (uuid) => {
  const result = await graphQL(
    `{members(filter: {uuid: {EQ: "${uuid}"}}) { uuid, company_uuid, modules_uuid, name, passive, scope } }`
  );
  if (result && result.data && result.data.members && result.data.members[0]) {
    store.dispatch(actionSetMember(result.data.members[0]));
    return result.data.members[0];
  }
  return null;
};

export const createMember = async (member) => {
  return graphQL(
    `mutation { members_create(${toGraphQL(member, false)}) { ok } }`
  );
};

export const createManyMembers = async (members) => {
  return graphQL(
    `mutation { members_createMany(many: ${toGraphQL(members, true)}) { ok } }`
  );
};

export const updateMember = async (member) => {
  return graphQL(
    `mutation { members_update(filter: {uuid: {EQ: "${
      member.uuid
    }"}}, update: {set: ${toGraphQL(member)} }) { ok } }`
  );
};

export const deleteMember = async (member) => {
  return graphQL(
    `mutation { members_delete(filter: {uuid: {EQ: "${member.uuid}"}}) { ok } }`
  );
};

// MODULES

export const modulesByCompany = async (comp_uuid) => {
  const result = await graphQL(
    `{ modules(filter: {company_uuid: {EQ: "${comp_uuid}"}}) { name, uuid,company_uuid, tutorial_uuid, languages, labels, storybuilder_key, storybuilder_name, tag, showPause, showProgress, confirmUserID, status } }`
  );
  if (result && result.data && result.data.modules) {
    store.dispatch(actionSetModules(result.data && result.data.modules));
  }
  return result;
};

export const modulesByMember = async (member_uuid) => {
  const result = await get(API_ROOT + '/eval/getModulesByMember', {
    member_uuid,
  });
  return result;
};

export const modules = async () => {
  const result = await graphQL(
    '{ modules { name, uuid,company_uuid, tutorial_uuid, languages, labels, storybuilder_key, storybuilder_name, tag, showPause, showProgress, confirmUserID, status } }'
  );
  if (result && result.data && result.data.modules) {
    store.dispatch(actionSetModules(result.data && result.data.modules));
    return result.data.modules;
  }
  return null;
};

export const moduleSingle = async (uuid) => {
  const result = await graphQL(
    `{ modules(filter: { uuid: {EQ: "${uuid}"}}) { name, uuid,company_uuid, tutorial_uuid, languages, labels, storybuilder_key, storybuilder_name, tag, showPause, showProgress, confirmUserID, status } }`
  );
  if (result && result.data && result.data.modules) {
    store.dispatch(actionSetModule(result.data && result.data.modules[0]));
    return result.data.modules[0];
  }
  return null;
};

export const createModule = async (module) => {
  const result = await graphQL(
    `mutation { modules_create(${toGraphQL(module, false)}) { ok, uuid } }`
  );
  return result && result.data && result.data.modules_create;
};

export const updateModule = async (module) => {
  return graphQL(
    `mutation { modules_update(filter: {uuid: {EQ: "${
      module.uuid
    }"}}, update: {set: ${toGraphQL(module)} }) { ok } }`
  );
};

export const deleteModule = async (module) => {
  return graphQL(
    `mutation { modules_delete(filter: {uuid: {EQ: "${module.uuid}"}}) { ok } }`
  );
};

// COMPANIES

export const companies = async () => {
  const result = await graphQL(
    '{ companies { name, loginname, uuid, created, modified, active } }'
  );
  if (result && result.data && result.data.companies) {
    store.dispatch(actionSetCompanies(result.data && result.data.companies));
  }
  return result;
};

export const company = async (uuid) => {
  const result = await graphQL(
    `{companies(filter: {uuid: {EQ: "${uuid}"}}) { uuid, name, created, modified, active } }`
  );
  if (
    result &&
    result.data &&
    result.data.companies &&
    result.data.companies[0]
  ) {
    store.dispatch(actionSetModule(result.data.companies[0]));
  }
  return result;
};

export const createCompany = async (companies) => {
  const result = await graphQL(
    `mutation { companies_create(${toGraphQL(companies, false)}) { ok, uuid } }`
  );
  return result && result.data && result.data.companies_create;
};

export const updateCompany = async (companies) => {
  return graphQL(
    `mutation { companies_update(filter: {uuid: {EQ: "${
      companies.uuid
    }"}}, update: {set: ${toGraphQL(companies)} }) { ok } }`
  );
};

export const deleteCompany = async (companies) => {
  return graphQL(
    `mutation { companies_delete(filter: {uuid: {EQ: "${companies.uuid}"}}) { ok } }`
  );
};

// SESSIONS

export const sessions = async () => {
  const result = await graphQL(
    '{sessions { uuid, clientdata, member_uuid, module_uuid, pages_uuid, scoreMin, scoreMax, rating, scoreCurrent, status, created, modified } }'
  );
  if (result && result.data && result.data.sessions) {
    for (let i in result.data.sessions) {
      let session = result.data.sessions[i];
      if (
        typeof session.pages_uuid === 'string' ||
        session.pages_uuid instanceof String
      ) {
        if (session.pages_uuid === '') {
          session.pages_uuid = [];
        } else {
          // convert to array
          session.pages_uuid = JSON.parse(
            session.pages_uuid.replace(/'/g, '"')
          );
          deleteSession(session);
          createSession(session).then((result) => {
            if (result) {
            }
          });
        }
      }
    }
    store.dispatch(actionSetSessions(result.data.sessions));
  }
  return result;
};

export const session = async (uuid) => {
  const result = await graphQL(
    `{sessions(filter: {uuid: {EQ: "${uuid}"}}) { uuid, clientdata, member_uuid, tutorial_uuid, pages_uuid, rating, scoreMin, scoreMax, scoreCurrent, status, created, modified, variables } }`
  );
  if (
    result &&
    result.data &&
    result.data.sessions &&
    result.data.sessions[0]
  ) {
    store.dispatch(actionSetSession(result.data.sessions[0]));
  }
  return result;
};

export const sessionsByCompanyModule = async (company_uuid, module_uuid) => {
  const result = await get(API_ROOT + '/eval/getSessionsByCompanyModule', {
    company_uuid,
    module_uuid,
  });
  if (result && result.ok && result.data) {
    store.dispatch(actionSetSessions(result.data));
  }
  return result;
};

export const sessionsMember = async (uuid, module_uuid) => {
  const result = await graphQL(
    `{sessions(sort: {created: ASC }, filter: {member_uuid: {EQ: "${uuid}"}, module_uuid: {EQ: "${module_uuid}"}}) { uuid, clientdata, member_uuid, tutorial_uuid, pages_uuid, rating, scoreMin, scoreMax, scoreCurrent, status, created, modified, variables } }`
  );
  if (
    result &&
    result.data &&
    result.data.sessions &&
    result.data.sessions[0]
  ) {
    store.dispatch(actionSetSessions(result.data.sessions[0]));
  }
  return result;
};

export const createSession = async (session) => {
  const result = await graphQL(
    `mutation { sessions_create(${toGraphQL(session, false)}) { ok, uuid } }`
  );
  return result && result.data && result.data.sessions_create;
};

export const updateSession = async (session) => {
  return graphQL(
    `mutation { sessions_update(filter: {uuid: {EQ: "${
      session.uuid
    }"}}, update: {set: ${toGraphQL(session)} }) { ok } }`
  );
};

export const updateSessionReplaceUUID = async (uuid, session) => {
  return graphQL(
    `mutation { sessions_update(filter: {uuid: {EQ: "${uuid}"}}, update: {set: ${toGraphQL(
      session
    )} }) { ok } }`
  );
};

export const deleteSession = async (session) => {
  return graphQL(
    `mutation { sessions_delete(filter: {uuid: {EQ: "${session.uuid}"}}) { ok } }`
  );
};


// FEEDBACK

export const feedbacks = async (uuid) => {
  const result = await graphQL(
    `{feedbacks { uuid, member_uuid, module_uuid, session_uuid, sequence_uuid, sequence_name, created, language, status, comments, text } }`
  );
  if (
    result &&
    result.data &&
    result.data.feedbacks
  ) {
    store.dispatch(actionSetFeedbacks(result.data.feedbacks));
  }
  return result;
};

export const createFeedback = async (data) => {
  const result = await graphQL(
    `mutation { feedbacks_create(${toGraphQL(data, false)}) { ok, uuid } }`
  );
  return result && result.data && result.data.feedback_create;
};

export const updateFeedback = async (feedback) => {
  return graphQL(
    `mutation { feedbacks_update(filter: {uuid: {EQ: "${
      feedback.uuid
    }"}}, update: {set: ${toGraphQL(feedback)} }) { ok } }`
  );
};



// TUTORIAL

export const tutorials = async () => {
  const result = await graphQL(
    '{tutorials { name, version, variables, themes, pages, pages_hierarchy, pages_array { uuid, name } , uuid, created, modified, vuppetmaster_key } }'
  );
  if (result && result.data && result.data.tutorials) {
    let tutorials = result.data.tutorials.filter(
      (tutorial) => tutorial && tutorial.version && tutorial.version === 'v2'
    );
    tutorials.forEach((tut) => {
      convertTutorialFromDB(tut);
    });
    store.dispatch(actionSetTutorials(tutorials));
  }
  return result;
};

export const tutorial = async (uuid) => {
  const result = await graphQL(
    `{tutorials (filter: {uuid: {EQ: "${uuid}"}}) { name, version, variables, themes, pages, pages_hierarchy, pages_array { uuid, name, status, theme, vars, nodes { name, uuid, active, type, css, start, end, theme, css, data } } , uuid, created, modified, vuppetmaster_key } }`
  );
  if (result && result.data && result.data.tutorials[0]) {
    let tutorial = result.data.tutorials[0];
    convertTutorialFromDB(tutorial);
    store.dispatch(actionSetVariables(tutorial.variables));
    store.dispatch(actionSetThemes(tutorial.themes));
    store.dispatch(actionSetTutorial(tutorial));
  }
  return result;
};

export const createTutorial = async (tutorial) => {
  let tut = convertTutorialToDB(tutorial);
  const result = await graphQL(
    `mutation { tutorials_create(${toGraphQL(tut, false)}) { ok, uuid } }`
  );
  return result && result.data && result.data.tutorials_create;
};

export const updateTutorial = async (tutorial) => {
  let tut = convertTutorialToDB(tutorial);
  const result = await graphQL(
    `mutation { tutorials_update(filter: {uuid: {EQ: "${
      tut.uuid
    }"}}, update: {set: ${toGraphQL(tut)} }) { ok } }`
  );
  return result;
};

export const deleteTutorial = async (tutorial) => {
  return graphQL(
    `mutation { tutorials_delete(filter: {uuid: {EQ: "${tutorial.uuid}"}}) { ok } }`
  );
};

// PAGES

export const pages = async () => {
  const result = await graphQL(
    '{pages { name, uuid, sortid, theme, vars, nodes { uuid, active, start, end, theme, css, type, page, label, filename } } }'
  );
  //  if (result && result.data && result.data.pages) {
  //    store.dispatch(actionSetPages(result.data && result.data.pages));
  //  }
  return result;
};

// MEDIA

export const media = async (owner_uuid) => {
  const result = await graphQL(
    `{media (filter: {owner_uuid: {EQ: "${owner_uuid}"}}) { uuid, name, owner_uuid, filename, type, created } }`
  );
  if (result && result.data && result.data.media) {
    store.dispatch(actionSetMedia(result.data && result.data.media));
  }
  return result;
};

export const updateMedia = async (media) => {
  return graphQL(
    `mutation { media_update(filter: {uuid: {EQ: "${
      media.uuid
    }"}}, update: {set: ${toGraphQL(media)} }) { ok } }`
  );
};

export const deleteMedia = async (media) => {
  const result = await graphQL(
    `mutation { media_delete(filter: {uuid: {EQ: "${media.uuid}"}}) { ok } }`
  );
  if (
    result &&
    result.data &&
    result.data.media_delete &&
    result.data.media_delete.ok
  ) {
    store.dispatch(actionRemoveMedia(media.uuid));
  }
  return result;
};

export const uploadMedia = async (file, owner_uuid) => {
  let form = new FormData();
  form.append('owner_uuid', owner_uuid);
  form.append('file', file);
  const result = await postForm(API_ROOT + '/media/upload', form);
  if (result && result.ok && result.data) {
    store.dispatch(actionAddMedia(result.data));
  }
  return result;
};

export const cloneMedia = async (uuid, new_owner_uuid) => {
  const result = await post(API_ROOT + '/media/clone', {
    uuid: uuid,
    new_owner_uuid: new_owner_uuid,
  });
  if (result && result.ok && result.data) {
  }
  return result;
};

export const replaceMedia = async (uuid, owner_uuid, file) => {
  let form = new FormData();
  form.append('uuid', uuid);
  form.append('owner_uuid', owner_uuid);
  form.append('file', file);
  const result = await postForm(API_ROOT + '/media/replace', form);
  if (result && result.ok && result.data) {
    store.dispatch(actionUpdateMedia(uuid, result.data));
  }
  return result;
};

// VM

export const vmProjectList = async () => {
  const result = await get(API_ROOT + '/vm/projectlist');
  return result;
};

export const vmProjectInfo = async (key) => {
  const result = await get(API_ROOT + '/vm/projectinfo', { key });
  return result;
};

export const vmProjectBookmarks = async (key) => {
  const result = await get(API_ROOT + '/vm/projectbookmarks', { key });
  return result;
};

export const pingVuppetmasterEngine = async (host, cb) => {
  try {
    superagent
      .get(host + '/vm/cdk')
      .on('error', (err) => {
        cb(null);
      })
      .then((response) => {
        const body = response.body;
        cb(body);
      });
  } catch (e) {
    cb(null);
  }
};

// SCRIPTS

export const getScripts = async (uuid, key) => {
  superagent
    .get(API_ROOT_VM + '/resources?name=scripts&uuid=' + uuid + '&key=' + key)
    .on('error', (err) => {})
    .then((response) => {
      const body = response.body;
      console.log('getScripts ', body);
      store.dispatch(actionSetScripts(body.res));
    });
};

// EVALUATIONS

export const evalDownloadSessionsCSV = async (
  company_uuid,
  module_uuid,
  filename
) => {
  const result = await get(API_ROOT + '/eval/sessionsAsCSV', {
    company_uuid,
    module_uuid,
  });
  downloadAsTextFile(filename, result.map((row) => row.join(',')).join('\n'));
};

export const evalDownloadSessionsAsXLSX = async (
  company_uuid,
  module_uuid,
  filename
) => {
  const result = await getBinary(API_ROOT + '/eval/sessionsAsXLSX', {
    company_uuid,
    module_uuid,
  });

  const url = window.URL.createObjectURL(
    new Blob([await result.arrayBuffer()])
  );
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename + '.xlsx');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const evalSessions = async (company_uuid, module_uuid) => {
  const result = await get(API_ROOT + '/eval/sessions', {
    company_uuid,
    module_uuid,
  });
  return result;
};

export default {
  post,
  get,
  graphQL,

  login,
  logout,
  authStatus,
  getipdata,
  pingVuppetmasterEngine,

  user,
  users,
  createUser,
  updateUser,
  deleteUser,

  members,
  member,
  createMember,
  createManyMembers,
  updateMember,
  deleteMember,

  modules,
  moduleSingle,
  modulesByCompany,
  modulesByMember,
  createModule,
  updateModule,
  deleteModule,

  sessions,
  sessionsMember,
  session,
  sessionsByCompanyModule,
  createSession,
  updateSession,
  updateSessionReplaceUUID,
  deleteSession,

  feedbacks,
  createFeedback,
  updateFeedback,

  tutorials,
  tutorial,
  createTutorial,
  updateTutorial,
  deleteTutorial,

  companies,
  company,
  createCompany,
  updateCompany,
  deleteCompany,

  pages,

  vmProjectList,
  vmProjectInfo,
  vmProjectBookmarks,

  media,
  uploadMedia,
  replaceMedia,
  cloneMedia,
  updateMedia,
  deleteMedia,

  getScripts,

  evalSessions,
  evalDownloadSessionsAsXLSX,
  evalDownloadSessionsCSV,
};
