import React, { Component } from 'react';
import {
	withRouter,
} from "react-router-dom";
import './Tutorials.css';
import incrementFileBasename from 'increment-file';
import styled from 'styled-components';

import { Tab } from 'semantic-ui-react'

import { connect } from 'react-redux';

import {
	actionSetPath,
} from '../../../redux/actions/actionMain';
import { actionSetTutorial, actionSetTutorials } from '../../../redux/actions/actionTutorial'
import store from '../../../redux/store';

import Tutorial from './Tutorial'
import Variables from '../../3_organisms/Variables'
import MainThemes from '../../3_organisms/MainThemes'

import RestAPI from '../../../RestAPI';
import DataTable from '../../3_organisms/DataTable/DataTable';
import ModalOkCancel from '../../3_organisms/ModalOkCancel';

import { getTestViewUrl } from '../../../helpers/getPreViewUrl';
import { scopeIncludesOneOf } from '../../../helpers/scopecheck';
import { actionSetPage } from '../../../redux/actions/actionPage';
import NewRowModal from '../../3_organisms/NewRowModal/NewRowModal';

import { createNewTutorial } from '../../../helpers/tutorial_manager';
var clone = require('clone');


const EditArea = styled.div`
  margin-top: 1rem;
`;

class Tutorials extends Component {

	constructor(props) {
		super(props);
		this.state = {};
	}

	updateView() {
		store.dispatch(actionSetTutorials(null));
		RestAPI.tutorials();
	}

	onSelect(tutorial) {
		RestAPI.tutorial(tutorial.uuid);
	}

	onAddNew(name) {

		let tutorial = createNewTutorial(name);

		RestAPI.createTutorial(tutorial).then((res) => {
			if (res && !res.error) {
				store.dispatch(actionSetTutorial(tutorial));
				this.updateView();
			}
		});
	}

	onDelete(tutorial) {
		RestAPI.deleteTutorial(tutorial).then((res) => {
			if (res && !res.error) {
				store.dispatch(actionSetTutorial(null));
				this.updateView();
			}
		});

	}

	findNextProjectName(name) {
		let newname = incrementFileBasename(name);
		let array = this.props.tutorials.filter((t) => t.name === newname);
		if (array.length === 0)
			return newname;
		else
			return this.findNextProjectName(newname);
	}


	onDuplicate(pTutorial) {

		let newname = this.findNextProjectName(pTutorial.name);

		RestAPI.tutorial(pTutorial.uuid).then((res) => {

			let tutorial = res.data.tutorials[0];

			let newtutorial = {
				name: newname,
				pages: [],
				pages_array: clone(tutorial.pages_array),
				pages_hierarchy: clone(tutorial.pages_hierarchy),
				vuppetmaster_key: tutorial.vuppetmaster_key,
				modified: new Date().toISOString(),
				created: new Date().toISOString(),
				themes: clone(tutorial.themes),
				variables: clone(tutorial.variables),
				version: tutorial.version,
			};

			RestAPI.media(tutorial.uuid).then(() => {
				RestAPI.createTutorial(newtutorial).then((result) => {
					newtutorial.uuid = result.uuid;

					// clone all media resources
					let nodes = [];
					if (newtutorial.pages_array) {
						newtutorial.pages_array.forEach(p => {
							if (p.nodes) {
								p.nodes.forEach(node => {
									if (node.type === 'image' && node.data) {
										nodes.push(node);
									}
								});
							}
						});
					}

					let count = 0;
					for (let m in this.props.media) {
						let media = this.props.media[m];
						RestAPI.cloneMedia(media.uuid, newtutorial.uuid).then(res => {
							console.log('Media: ', media.uuid, ' to ', res.data.uuid);
							nodes = nodes.map(n => {
								if (n.data.resource === media.uuid) {
									console.log('\treplace ', n.data.resource, ' with ', res.data.uuid);
									n.data.resource = res.data.uuid;
								}
								return n;
							});
							count++;
							if (count === this.props.media.length) {
								RestAPI.updateTutorial(newtutorial, () => {
								});
								this.updateView();
							}
						});
					}
				});
			});

		});


	}

	onSet(tutorial) {

//		if (this.props.vuppetmaster)
	//		RestAPI.getScripts(this.props.vuppetmaster._uuid, tutorial.vuppetmaster_key);

		this.props.history.push('/admin/pages');

		RestAPI.media(tutorial.uuid).then(() => {
			store.dispatch(actionSetPath('pages'));


			RestAPI.tutorial(tutorial.uuid);

			//			store.dispatch(actionSetThemes(tutorial.themes));
			//			store.dispatch(actionSetVariables(tutorial.variables));
			//			store.dispatch(actionSetTutorial(tutorial));
			store.dispatch(actionSetPage(null));

		});
	}


	onAssign(row, index) {
		console.log('TODO: IMPLEMENT ASSIGN BY TUTORIAL');
	}

	render() {
		const {
			tutorial,
			tutorials,
			login,
		} = this.props;

		const {
			showAddModal,
			tutorialToDelete,
		} = this.state;

		const canEdit = scopeIncludesOneOf(login && login.scope, ['root', 'production']);

		const rows = tutorials && tutorials.map((tutorial, index) => (
			{
				name: tutorial.name,
				tutorial: tutorial,
				pages: (tutorial.pages && tutorial.pages.length) || (tutorial.pages_array && tutorial.pages_array.length),
				created: tutorial.created,
				modified: tutorial.modified,
			}
		));

		const actions = [
			canEdit && {
				icon: 'edit',
				color: 'blue',
				tooltip: 'edit tutorial',
				func: (row) => this.onSet(row.tutorial),
			},
		];

		const actionsMenu = [
			canEdit && {
				text: 'Edit',
				icon: 'external',
				tooltip: 'edit tutorial',
				func: (row) => this.onSet(row.tutorial),
			},
			canEdit && {
				text: 'Clone',
				icon: 'copy',
				tooltip: 'clone tutorial',
				func: (row) => this.onDuplicate(row.tutorial),
			},
			canEdit && {
				text: 'Show',
				icon: 'tv',
				tooltip: 'show preview',
				func: (row) => {
					var win = window.open(getTestViewUrl(row.tutorial.uuid, ''), '_blank');
					win.focus();
				}
			},
			canEdit && {
				text: 'Delete',
				icon: 'delete',
				color: 'red',
				tooltip: 'delete tutorial',
				func: (row) => this.setState({ tutorialToDelete: row.tutorial }),
			},
		];

		const TabSelected = (props) => {
			if (tutorial)
				return <Tab
					{...props}
					menu={{ pointing: true }}
					panes={panes}

					// CD: because a redux dispatch chnages the tab index. I don´t know why????
					activeIndex={this.state.tabIndex}
					onTabChange={(e, d) => {
						this.setState({ tabIndex: d.activeIndex });
					}}
				/>;
			else
				return null;
		};

		const panes = [
			{
				menuItem: 'Tutorial',
				render: (props) => <Tab.Pane attached={false}>
					<Tutorial {...props}
						update={() => this.updateView()} />
				</Tab.Pane>,
			},
			{
				menuItem: 'Variables',
				render: (props) => <Tab.Pane attached={false}>
					<Variables {...props}
						update={() => this.updateView()} />
				</Tab.Pane>,
			},
			{
				menuItem: 'Themes',
				render: (props) => <Tab.Pane attached={false}>
					<MainThemes {...props}
						update={() => this.updateView()} />
				</Tab.Pane>,
			},
		];

		const columns = [
			{
				name: 'Name',
				key: 'name',
				required: true,
				singleLine: true,
				header: false,
			},
			{
				name: 'Pages',
				key: 'pages',
				disabled: true,
				singleLine: true,
				header: false,
			},
			{
				name: 'Created',
				key: 'created',
				disabled: true,
				singleLine: true,
				type: 'date'
			},
			{
				name: 'Modified',
				key: 'modified',
				disabled: true,
				singleLine: true,
				type: 'date'
			},
		];

		return (
			<div >
				<h3>Tutorials:</h3>
				<div id='tutorials'>
					<DataTable
						name='tutorials'
						placeholder='Filter name'
						sortable={true}
						filter={'name'}
						columns={columns}
						rows={rows}
						actions={actions}
						actionsMenu={actionsMenu}
						onSelect={(row) => this.onSelect(row.tutorial)}
						generalActions={[
							canEdit && {
								icon: 'add',
								text: 'new tutorial',
								color: 'blue',
								float: 'right',
								func: (event) => this.setState({ showAddModal: true }),
							}
						]}
						updateView={() => this.updateView()}
					/>
				</div>
				{
					canEdit &&
					<EditArea>
						<TabSelected />
					</EditArea>
				}
				<ModalOkCancel
					text='Delete Tutorial?'
					color='red'
					open={tutorialToDelete != null}
					onOK={() => {
						this.onDelete(tutorialToDelete);
						this.setState({ tutorialToDelete: null });
					}}
					onCancel={() => this.setState({ tutorialToDelete: null })}
				/>
				{
					showAddModal &&
					<NewRowModal
						icon={'add'}
						title={'new tutorial'}
						fields={columns}
						onApply={(result) => {
							this.onAddNew(result.name);
							this.setState({ showAddModal: false });
						}}
						onCancel={() => this.setState({ showAddModal: false })} />
				}
			</div>
		);
	}
}

// You have to connect() to any reducers that you wish to connect to yourself
Tutorials = connect(
	state => ({
		vuppetmaster: state.vuppetmaster.vuppetmaster,
		login: state.login.login,
		tutorials: state.tutorial.tutorials,
		tutorial: state.tutorial.tutorial,
		media: state.media,
	}),
	{}, // bind account loading action creator
)(Tutorials);

export default withRouter(Tutorials);
