import React, { Component } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { API_ROOT } from '../../../api-config';
import styled from 'styled-components';
import MediaModal from '../../3_organisms/MediaModal/MediaModal';

const Host = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const Selector = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  height: 38px;
  color: rgba(0, 0, 0, 0.87);
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  overflow: hidden;

  > * {
    margin: 0 !important;
  }
`;

const Wrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 38px;
  padding: 1px;
  background-color: ${(props) =>
    props && props.selected ? '#00b5ad' : 'transparent'};
  transition: background-color 0.25s ease;

  > img,
  > video {
    max-width: 100%;
    max-height: 100%;
  }
`;

const FileName = styled.div`
  display: inline-block;
  padding: 10px 4px;
  overflow: hidden;
  white-space: nowrap;
  flex: 1 1 auto;
`;

class MediaSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      media: null,
      showModal: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.media && nextProps.media !== this.props.media) {
      this.setState({ media: nextProps.media });
    }
  }

  setMedia(media) {
    this.setState({ media: media });
    if (this.props.onChanged) {
      this.props.onChanged(media);
    }
    this.setState({ showModal: false });
  }

  render() {
    let { label } = this.props;

    let { showModal } = this.state;

    let media = this.props.media ? this.props.media : this.props.state;

    return (
      <Host>
        <label style={{ display: 'inline-block', width: '100px' }}>
          {label}
        </label>
        <Selector>
          {media && (
            <Wrapper>
              {media.type && media.type.indexOf('video') === 0 ? (
                <video width="100" height="50">
                  <source
                    src={`${API_ROOT}/media/${media.owner_uuid}&${media.filename}`}
                    type={media.type}
                  />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img
                  src={`${API_ROOT}/media/${media.owner_uuid}&${media.filename}`}
                  alt="_image"
                />
              )}
            </Wrapper>
          )}
          <FileName>{(media && media.name) || 'select media'}</FileName>
          <Button
            icon
            color="grey"
            onClick={() => this.setState({ showModal: true })}
          >
            <Icon name={'picture'} />
          </Button>
        </Selector>
        {showModal && (
          <MediaModal
            onApply={(media) => this.setMedia(media)}
            onCancel={() => this.setState({ showModal: false })}
          />
        )}
      </Host>
    );
  }
}

export default MediaSelect;
