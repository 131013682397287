import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { Dropdown, Button } from 'semantic-ui-react';
import EditLine from '../../1_atom/EditLine/EditLine.js';
import { arrayDropDown } from '../../../helpers/arrayDropDown';

import { connect } from 'react-redux';
import store from '../../../redux/store';

import styles from './User.module.scss';

import RestAPI from '../../../RestAPI';
import { actionSetUser } from '../../../redux/actions/actionUser.js';
import { keyframes } from 'styled-components';
const clone = require('clone');
const roles = require('./../../../helpers/authorization_roles.json');

class User extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      passwd: '',
      setpasswd: false,
      valid: true,
    };

    RestAPI.users();
    RestAPI.members();
  }

  onApply() {
    if (!this.props.user) {
      return false;
    }

    let user = {
      ...this.props.user,
    };
    user.name = this.state.name !== '' ? this.state.name : this.props.user.name;
    user.email =
      this.state.email !== '' ? this.state.email : this.props.user.email;

    if (this.state.passwd) user.passwd = this.state.passwd;

    store.dispatch(actionSetUser(user));

    RestAPI.updateUser(user).then((result) => {
      RestAPI.users();
      this.props.history.push('/admin/users');
    });
  }

  render() {
    const { user, companies, users, members } = this.props;
    const { setpasswd, valid } = this.state;

    let optCompany = arrayDropDown(companies);
    let optRole = Object.keys(roles).map((key) => {
      return {
        key,
        value: key,
        text: key,
      };
    });

    return (
      user && (
        <div className={styles.main}>
          {process.env.NODE_ENV === 'development' && (
            <div>UUID: {user.uuid}</div>
          )}
          <EditLine
            label="Name:"
            text={user.name}
            onChanged={(text) => {
              const valid =
                !users.find((u) => u.name === text && u.name !== user.name) &&
                !members.find((member) => member.name === text);
              this.setState({ name: text, valid });
            }}
          />
          {!valid && <div style={{ color: 'red' }}>name exists</div>}
          <EditLine
            label="eMail:"
            text={user.email}
            onChanged={(text) => this.setState({ email: text })}
          />
          {setpasswd ? (
            <EditLine
              label="Password:"
              apply={'Set'}
              onApply={() => this.setState({ setpasswd: false })}
              onChanged={(passwd) => this.setState({ passwd: passwd })}
            />
          ) : (
            <div>
              <Button
                onClick={() => {
                  this.setState({ setpasswd: true });
                }}
              >
                Set Password
              </Button>
              <br />
            </div>
          )}
          <br />
          Comany:
          <Dropdown
            placeholder="Select Company"
            fluid
            multiple
            value={user && user.companies_uuid ? user.companies_uuid : []}
            selection
            options={optCompany}
            onChange={(e, d) => {
              let u = clone(user);
              u.companies_uuid = d.value;
              store.dispatch(actionSetUser(u));
            }}
          />
          <br />
          Role:
          <Dropdown
            placeholder="Select Role"
            value={user && user.role}
            selection
            options={optRole}
            onChange={(e, d) => {
              let u = clone(user);
              u.role = d.value;
              store.dispatch(actionSetUser(u));
            }}
          />
          <br />
          {valid && (
            <Button
              floated="left"
              color={'green'}
              onClick={() => this.onApply()}
            >
              Apply
            </Button>
          )}
        </div>
      )
    );
  }
}

// You have to connect() to any reducers that you wish to connect to yourself
User = connect(
  (state) => ({
    user: state.user.user,
    users: state.user.users,
    members: state.member.members,
    companies: state.company.companies,
  }),
  {}
)(User);

export default withRouter(User);
