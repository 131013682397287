import React, { Component } from 'react';
import { Dropdown, Segment } from 'semantic-ui-react';

import EditLine from '../../1_atom/EditLine/EditLine.js';

import { connect } from 'react-redux';
import store from './../../../redux/store';
import { actionSetNode } from '../../../redux/actions/actionPage';

class NodeVarText extends Component {
  getVarType(uuid) {
    let v = this.props.variables.find((v) => v.uuid === uuid);
    if (v) return v.type;
    else return 'undef';
  }

  render() {
    let optVars = [];
    if (this.props.variables) {
      this.props.variables.forEach((element) => {
        if (element.type === 'text')
          optVars.push({
            key: element.uuid,
            text: element.name,
            value: element.uuid,
          });
      });
    }

    return (
      <Segment>
        <EditLine
          label="Start:"
          text={this.props.node.start}
          onChanged={(text) =>
            store.dispatch(actionSetNode({ ...this.props.node, start: text }))
          }
        />
        <Dropdown
          placeholder="Select variable"
          value={this.props.node.data.var ? this.props.node.data.var : ''}
          selection
          options={optVars}
          onChange={(e, d) => {
            let node = JSON.parse(JSON.stringify(this.props.node));
            node.data.var = d.value;
            store.dispatch(actionSetNode(node));
          }}
        />
        <EditLine
          label="Value:"
          text={this.props.node.data.value ? this.props.node.data.value : '0'}
          onChanged={(text) => {
            let node = JSON.parse(JSON.stringify(this.props.node));
            node.data.value = text;
            store.dispatch(actionSetNode(node));
          }}
        />
      </Segment>
    );
  }
}

NodeVarText = connect(
  (state) => ({
    node: state.page.node,
    page: state.page.page,
    tutorial: state.tutorial.tutorial,
    variables: state.main.variables,
  }),
  {}
)(NodeVarText);

export default NodeVarText;
