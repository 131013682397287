export const allLanguages = [
  { code: 'de', text: 'German',original: 'Deutsch' },
  { code: 'en', text: 'English',original: 'English'  },
  { code: 'it', text: 'Italy', original: 'Italia'},
  { code: 'fr', text: 'French', original: 'Français' },
  { code: 'es', text: 'Spanish', original: 'Español' },
  { code: 'pt', text: 'Portuguese',original: 'Português'  },
  { code: 'nl', text: 'Dutch',original: 'Nederlands'  },
  { code: 'pl', text: 'Polish',original: 'Polski'  },
  { code: 'tr', text: 'Turkish',original: 'Türkçe'  },
  { code: 'ru', text: 'Russian',original: 'Eрусский язык'  },
  { code: 'ro', text: 'Romanian',original: 'Română'  },
  { code: 'jp', text: 'Japanese',original: 'にほんご'  },
];
