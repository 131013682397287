import React, { Component } from 'react';
import { Segment } from 'semantic-ui-react'


import EditLine from '../../1_atom/EditLine/EditLine.js';

import { connect } from 'react-redux';
import store from './../../../redux/store';
import { actionSetNode } from '../../../redux/actions/actionPage';

class NodeTTS extends Component {

	constructor(props) {
		super(props);
		this.state = {
		}
	}

	render() {
		return (
			<Segment>
				{this.props.node.name}
				<EditLine label='Name:' text={this.props.node.text} onChanged={(text) => store.dispatch(actionSetNode( { ...this.props.node, name:  text } )) } />
				<EditLine label='Start:' text={this.props.node.start} onChanged={(text) => store.dispatch(actionSetNode( { ...this.props.node, start:  text } )) } />
				<EditLine label='Text:' text={this.props.node.data?this.props.node.data.text:''} onChanged={(text) => {

					if (!this.props.node.data)
						this.props.node.data = { text: text	};
					this.props.node.data = { ...this.props.node.data, text: text }
					store.dispatch(actionSetNode(this.props.node)) }
				} />
			</Segment>
		)
	}
}

NodeTTS = connect(
	state => ({
		node: state.page.node,
	}),
	{},
)(NodeTTS);

export default NodeTTS;

