import React, { Component } from 'react';
import { Icon, Table, Button, Header } from 'semantic-ui-react'
import EditLine from './../1_atom/EditLine/EditLine.js';

import { connect } from 'react-redux';
import store from '../../redux/store';

import { actionSetTutorial } from '../../redux/actions/actionTutorial.js';
import { actionSetVariables } from '../../redux/actions/actionMain'

import RestAPI from '../../RestAPI';
const UUID = require('uuid/v4');

class Variables extends Component {

	constructor(props) {
		super(props);
		this.state = {
			selindex: -1,
		}
	}

	onSelect(variable, index) {
		this.setState({ selindex: index })
	}

	onDelete(variable) {

		let array = JSON.parse(JSON.stringify(this.props.variables));

		array = JSON.parse(JSON.stringify(array));
		array = array.filter(v => v.uuid !== variable.uuid);
		store.dispatch(actionSetVariables(array));

	}

	onAddNew(type) {
		let v = {
			uuid: UUID(),
			type: type,
			name: 'name',
			value: type==='count'?0:'',
		};
		this.props.variables.push(v);

		let array = JSON.parse(JSON.stringify(this.props.variables));
		store.dispatch(actionSetVariables(array));
	}

	render() {

		let array = this.props.variables;
		var output = array ? array.map((variable, index) => {
			return (
				<Table.Row key={index} onClick={() => { this.onSelect(variable, index); }} active={index === this.state.selindex}  >
					<Table.Cell singleLine>
						<Header textAlign='left'>
							<EditLine label='' text={variable.name} onChanged={(text) => {
								variable.name = text;
							}} />
						</Header>
					</Table.Cell>
					<Table.Cell>
						<Header textAlign='left'>{variable.type}</Header>
					</Table.Cell>
					{!(variable.mode && variable.mode === 'system') && (
					<Table.Cell singleLine>
						<Button color='red' icon onClick={() => { this.onDelete(variable); }} >
							<Icon name='delete' />
						</Button>
					</Table.Cell>)}
				</Table.Row>)
		}) : null;

		return (
			<div className="users" >
				{
					array ?
						<div>
							<Table compact size='small' celled selectable>
								<Table.Header>
									<Table.Row>
										<Table.HeaderCell singleLine>Name</Table.HeaderCell>
										<Table.HeaderCell singleLine>Type</Table.HeaderCell>
										<Table.HeaderCell></Table.HeaderCell>
									</Table.Row>
								</Table.Header>

								<Table.Body>
									{output}
								</Table.Body>
								<Table.Footer fullWidth>
									<Table.Row>
										<Table.HeaderCell colSpan='6'>
										</Table.HeaderCell>
									</Table.Row>
								</Table.Footer>
							</Table>
						</div> : null
				}
				<Button onClick={() => { this.onAddNew('text'); }} floated='right' icon labelPosition='left' primary size='small'>
					<Icon name='add' /> Add text variable
				</Button>
				<Button onClick={() => { this.onAddNew('page'); }} floated='right' icon labelPosition='left' primary size='small'>
					<Icon name='add' /> Add page variable
				</Button>
				<Button onClick={() => { this.onAddNew('count'); }} floated='right' icon labelPosition='left' primary size='small'>
					<Icon name='add' /> Add count variable
				</Button>
				<Button onClick={() => {

					let tutorial = JSON.parse(JSON.stringify(this.props.tutorial));
					tutorial.variables = this.props.variables;
					store.dispatch(actionSetVariables(tutorial.variables));
					store.dispatch(actionSetTutorial(tutorial));
					RestAPI.updateTutorial(tutorial).then( () => {
						this.props.update();
					});

				}} >apply
				</Button>

			</div >
		)
	}
}


// You have to connect() to any reducers that you wish to connect to yourself
Variables = connect(
	state => ({
		variables: state.main.variables,
		tutorial: state.tutorial.tutorial
	}),
	{},
)(Variables);
export default Variables;
