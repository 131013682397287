import React, { Component } from 'react';
import { Dropdown, Label, Segment } from 'semantic-ui-react';

import EditLine from '../../1_atom/EditLine/EditLine.js';

import { connect } from 'react-redux';
import store from './../../../redux/store';
import { actionSetNode } from '../../../redux/actions/actionPage';

class NodeVarCompare extends Component {
  render() {
    let optVars = [];
    if (this.props.variables)
      this.props.variables.forEach((element) => {
        if (element.type === 'count')
          optVars.push({
            key: element.uuid,
            text: element.name,
            value: element.uuid,
          });
      });

    let optPages = [];
    if (this.props.tutorial.pages_array) {
      this.props.tutorial.pages_array.forEach((p) => {
        optPages.push({
          key: p.uuid,
          text: p.name,
          value: p.uuid,
        });
      });
    }
    if (this.props.tutorial.variables) {
      this.props.tutorial.variables.forEach((v) => {
        if (v.type === 'page') {
          optPages.push({
            key: v.uuid,
            text: 'Var: ' + v.name,
            value: v.uuid,
          });
        }
      });
    }

    let optCompOperator = [
      { key: '==', text: '==', value: '==' },
      { key: '<=', text: '<=', value: '<=' },
      { key: '<', text: '<', value: '<' },
      { key: '>=', text: '>=', value: '>=' },
      { key: '>', text: '>', value: '>' },
    ];

    return (
      <Segment>
        <EditLine
          label="Start:"
          text={this.props.node.start}
          onChanged={(text) =>
            store.dispatch(actionSetNode({ ...this.props.node, start: text }))
          }
        />
        <Label>IF</Label>
        <Dropdown
          placeholder="Select variable"
          value={this.props.node.data.param1 ? this.props.node.data.param1 : ''}
          selection
          options={optVars}
          onChange={(e, d) => {
            let node = JSON.parse(JSON.stringify(this.props.node));
            node.data.param1 = d.value;
            store.dispatch(actionSetNode(node));
          }}
        />
        <Dropdown
          placeholder="Select operator"
          value={
            this.props.node.data.operator ? this.props.node.data.operator : ''
          }
          selection
          options={optCompOperator}
          onChange={(e, d) => {
            let node = JSON.parse(JSON.stringify(this.props.node));
            node.data.operator = d.value;
            store.dispatch(actionSetNode(node));
          }}
        />
        <Dropdown
          placeholder="Select variable"
          value={this.props.node.data.param2 ? this.props.node.data.param2 : ''}
          selection
          options={optVars}
          onChange={(e, d) => {
            let node = JSON.parse(JSON.stringify(this.props.node));
            node.data.param2 = d.value;
            store.dispatch(actionSetNode(node));
          }}
        />
        <Label>THEN</Label>
        <Dropdown
          placeholder="Select"
          value={
            this.props.node.data && this.props.node.data.value
              ? this.props.node.data.value
              : ''
          }
          selection
          options={optPages}
          onChange={(e, d) => {
            let node = JSON.parse(JSON.stringify(this.props.node));
            if (!node.data) node.data = { page: '' };
            node.data = { ...node.data, value: d.value };
            store.dispatch(actionSetNode(node));
          }}
        />
        <Label>ELSE</Label>
        <Dropdown
          placeholder="Select"
          value={
            this.props.node.data && this.props.node.data.elsevalue
              ? this.props.node.data.elsevalue
              : ''
          }
          selection
          options={optPages}
          onChange={(e, d) => {
            let node = JSON.parse(JSON.stringify(this.props.node));
            if (!node.data) node.data = { page: '' };
            node.data = { ...node.data, elsevalue: d.value };
            store.dispatch(actionSetNode(node));
          }}
        />
      </Segment>
    );
  }
}

NodeVarCompare = connect(
  (state) => ({
    node: state.page.node,
    page: state.page.page,
    tutorial: state.tutorial.tutorial,
    variables: state.main.variables,
  }),
  {}
)(NodeVarCompare);

export default NodeVarCompare;
