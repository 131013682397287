import React, { Component } from 'react';
import { Popup, Icon, List, Button, Segment, Rating } from 'semantic-ui-react';

import {
	withRouter,
} from "react-router-dom";


import RenderView from './RenderView';
import TrainerStart from './TrainerStart';
import HelpModal from './../../1_atom/HelpModal/HelpModal';
import { API_ROOT } from '../../../api-config';
import Script from 'react-load-script';

import './TrainerView.css'
import RestAPI from '../../../RestAPI';

import { connect } from 'react-redux';
import store from '../../../redux/store';
import { actionSetPage } from '../../../redux/actions/actionPage';
import { actionSetVariables, actionSetThemes } from '../../../redux/actions/actionMain';
import { actionSetSession } from "../../../redux/actions/actionSession";
import {Howl } from 'howler';
import ReactResizeDetector from "react-resize-detector";

var Preload = require('react-preload').Preload;
var clone = require('clone');


const ifvisible = require('ifvisible.js');

const IconButton = ({ iconname, tooltip, func }) => (
	<Popup trigger={
		<Button
			icon
			size={'mini'}
			onClick={() => func()}
		>
			<Icon name={iconname} />
		</Button>
	}
		content={tooltip}
	/>
)

class TrainerView extends Component {

	constructor(props) {
		super(props);

		let vmurl = 'https://vuppetmaster.de/vm/api?key=trainer';

		if (process.env.NODE_ENV !== 'production') {
			vmurl = '';
		}

		if (process.env.MODE === 'offline') {
			console.log('### mode offline ####');
			vmurl = 'http://127.0.0.1/vm/api?key=trainer';
		}

		this.state = {
			progress: 0,
			trigger: 1,
			timer: 0,
			startpage_uuid: null,
			tutorial_uuid: null,
			flat_pages_array: null,
			openHelp: false,

			currentPageUUID: null,
			backgroundStyle: null,

			visible: false,

			vuppetmasterActive: false,

			_images: [],

			vmurl: vmurl,
			vmReady: false,
		};

		this._vuppetmaster = null;

		this.logoutTimer = null;

		/*
				mode : 
					preview				// PreView in PageManager
					trainer				// fullscreen 
					testmode			// fullscreen test mode
					demo					// demo mode for admin

		*/

		ifvisible.on("blur", () => {
			console.log('# minimze');
			this._vuppetmaster && this._vuppetmaster.speakStop();
			this._vuppetmaster && this._vuppetmaster.playScript(null);
		});

		ifvisible.on("focus", () => {
			console.log('# focus');
			this.onRestart();
		});



		this._timerIntervalArray = [];
		this._timerTimeoutArray = [];

	}

	componentWillUnmount() {
		if (this.props.mode === 'trainer' || this.props.mode === 'testmode' || this.props.mode === 'demo') {
//			this.logout();
		}
	}

	/**** componentDidMount *******************************************************************************************
	 * 
	 * 
	 */
	componentDidMount() {

		// user direct the old online vm
		this.setState({ vmurl: 'https://vuppetmaster.de/vm/api?key=trainer' });

/*  
		if (process.env.NODE_ENV !== 'production') {
			RestAPI.pingVuppetmasterEngine('http://127.0.0.1', (result) => {
				if (result && result.result === 'cdk')
					this.setState({ vmurl: 'http://127.0.0.1/vm/api?key=trainer' });
				else
					this.setState({ vmurl: 'https://vuppetmaster.de/vm/api?key=trainer' });
			});
		}*/
	}

	myComponentDidMount() {

		this._vuppetmaster && this._vuppetmaster.on('progress', (val) => {
			this.setState({ progress: Math.floor(val) });
		});

		this._vuppetmaster && this._vuppetmaster.on('complete', (res) => {
			this.setState({ visible: true });


			this._vuppetmaster.enableCamControl && this._vuppetmaster.enableCamControl(false);

			if (res.error) {
				console.log('vuppetmaster result:', res.error);
				return;
			}

			if (this.props.mode === 'trainer' || this.props.mode === 'testmode' || this.props.mode === 'demo') {

				this.setState({ vuppetmasterActive: true });

				if (this.props.page && this.props.page.name === 'loading') {

					if (this.props.session) {
						// try to evaluate the start page to continue
						let pages = this.props.session.pages_uuid;
						if (pages.length > 0) {
							console.log('# goto last page of session');
							let lastPage = pages.pop();

							// restor variables from session
							if (this.props.session.variables)
								store.dispatch(actionSetVariables(this.props.session.variables));

							this.onGotoPageByUUID(lastPage);
							return;
						}
					}

					let var_startpage = this.props.variables.find(v => v.name === '_startPage_');
					if (var_startpage) {
						this.onGotoPageByUUID(var_startpage.uuid);
					}
				}
			}
		});


		if (this.props.mode === 'preview') {
			//
			//	PreView 
			//
		}
		else if (this.props.mode === 'trainer') {
			//
			//	Trainer main view
			//
			console.log('Trainer componentDidMount ', this.props.tutorial && this.props.tutorial.uuid)
			if (this.props.tutorial && this.props.tutorial.pages_array) {

				RestAPI.media(this.props.tutorial.uuid).then(res => {
					store.dispatch(actionSetVariables(this.props.tutorial.variables));
					store.dispatch(actionSetThemes(this.props.tutorial.themes));

					this.preloadImages(this.props.tutorial);

					this._vuppetmaster && this._vuppetmaster.loadProject(this.props.tutorial.vuppetmaster_key, null);

					// get loading page
					let startPage = this.props.tutorial.pages_array.find(p => p.name === 'loading');
					this.initPageVars(startPage);
					store.dispatch(actionSetPage(startPage));
				});
			}
		}
		else if (this.props.mode === 'testmode' || this.props.mode === 'demo') {
			//
			//	Testmode
			//
			console.log('# start with testmode demo');
			var uuid = this.props.match.params.uuid.split('&');

			let startpage_uuid = uuid.length === 1 ? null : uuid[1];
			let tutorial_uuid = uuid[0];

			this.setState({ tutorial_uuid: tutorial_uuid });

			RestAPI.media(tutorial_uuid).then(res => {
				RestAPI.tutorial(tutorial_uuid).then((res) => {
					if (res && !res.error) {

						let tutorial = res.data.tutorials[0];
						this.preloadImages(tutorial);

						let array = [];
						this.addPageArray(tutorial.pages_hierarchy, array);
						this.setState({ flat_pages_array: array });

						this._vuppetmaster && this._vuppetmaster.loadProject(tutorial.vuppetmaster_key, null);

						let startPage = null;
						if (!startpage_uuid) {
							// get loading page
							startPage = tutorial.pages_array.find(p => p.name === 'loading');
						}
						else
							startPage = tutorial.pages_array.find(p => p.uuid === startpage_uuid);

						if (startPage)
							startpage_uuid = startPage.uuid;
						else if (tutorial.pages_hierarchy.length)
							startpage_uuid = tutorial.pages_hierarchy[0].uuid;
						else
							startpage_uuid = null;


						this.setState({ startpage_uuid: startpage_uuid });

						startPage = tutorial.pages_array.find(p => p.uuid === startpage_uuid);

						// goto page without starting the timers
						this.initPageVars(startPage);
						store.dispatch(actionSetPage(startPage));
					}
				});
			});
		}
	}

	preloadImages(tutorial) {

		// Preload images
		let nodeList = [];
		if (tutorial.pages_array) {
			tutorial.pages_array.forEach(p => {
				if (p.nodes) {
					p.nodes.forEach(node => {
						if (node.type === 'image' && node.data) {
							nodeList.push(node);
						}
					});
				}
			});
		}
		let imageList = nodeList.map(n => this.getFilenameByNodeImage(n));
		// remove duplicte entries
		imageList = Array.from(new Set(imageList));
		this.setState({ _images: imageList });
	}


	updateTutorial() {
		this.onStop();
		RestAPI.tutorial(this.state.tutorial_uuid).then((res) => {
			if (res && !res.error) {

				let tutorial = res.data.tutorials[0];
				if (tutorial && tutorial.pages_array) {

					let array = [];
					this.addPageArray(tutorial.pages_hierarchy, array);
					this.setState({ flat_pages_array: array });

					let page = tutorial.pages_array.find(p => p.uuid === this.props.page.uuid);
					if (page) {
						store.dispatch(actionSetPage(page));
						this.initPage(page);
					}
				}
			}
		});
	}

	addPageArray(tree, array) {
		for (let i in tree) {
			let p = tree[i];
			array.push({ uuid: p.uuid, name: p.name });
			if (p.children && p.children.length) {
				this.addPageArray(p.children, array);
			}
		}
	}


	initPageVars(page) {
		page.nodes.forEach(node => {
			this.setState({ trigger: this.state.trigger + 1 });
			if (node.type === 'var_set_page' || node.type === 'var_set_text') {
				let v = this.props.variables.find(gv => gv.uuid === node.data.var);
				v.value = node.data.value;
			}
			else if (node.type === 'var_set_count') {
				let v = this.props.variables.find(gv => gv.uuid === node.data.var);
				switch (node.data.operator) {
					case '=': v.value = node.data.value; break;
					case '+=': v.value += node.data.value; break;
					case '-=': v.value -= node.data.value; break;
					default: break;
				}
			}
		});
	}


	initPage(page) {
		page.nodes.forEach(node => {
			node.active = true;
			if (node.type === 'image') {
				if (node.data && node.data.background) {
					this.setBackgroundNode(node);
				}
			}
		});
	}



	triggerLogoutTimer() {
		console.log('# triggerLogoutTimer');
		clearInterval(this.logoutTimer);
		this.logoutTimer = setTimeout(() => {
			this.logout();
		}, 1000 * 60 * 6);
	}

	startPageTimer(page) {

		if (this.props.mode === 'preview') {
			return;
		}


		this.onStop();
		this._timerIntervalArray.push(setInterval(() => {
			this.setState({ timer: this.state.timer + 1 });
		}, 1000));

		if (page.uuid !== this.state.currentPageUUID) {
			this.setState({ currentPageUUID: page.uuid });

			if (this.props.mode === 'trainer' && this.hasPageButton(page)) {
				this.triggerLogoutTimer();
			}
			else {
				clearInterval(this.logoutTimer);
			}
		}

		// first calc all variables
		page.nodes.forEach(node => {
			node.active = false;
			this._timerTimeoutArray.push(setTimeout(() => {
				node.active = true;
				this.setState({ trigger: this.state.trigger + 1 });

				if (node.type === 'var_set_page' || node.type === 'var_set_text') {
					let v = this.props.variables.find(gv => gv.uuid === node.data.var);
					v.value = node.data.value;
				}
				else if (node.type === 'var_set_count') {
					let v = this.props.variables.find(gv => gv.uuid === node.data.var);
					if (v) {

						console.log('# v ',JSON.stringify(v,null,4));
						//					console.log('# node.data ',JSON.stringify(node.data,null,4));

						if (typeof v.value === 'string') {
							if (v.value === '')
								v.value = 0;
							else 
								v.value = Number.parseInt(v.value, 10);
						}
						if (typeof node.data.value === 'string') {
							if (node.data.value === '')
								node.data.value = 0;
							else 
								node.data.value = Number.parseInt(node.data.value, 10);
						}

						switch (node.data.operator) {
							case '=': v.value = node.data.value; break;
							case '+=': v.value += node.data.value; break;
							case '-=': v.value -= node.data.value; break;
							default: break;
						}

						let min = 0, max = 0, current = 0;
						if (v.name === '_minScore_')
							min = v.value;
						if (v.name === '_maxScore_')
							max = v.value;
						if (v.name === '_currentScore_')
							current = v.value;
						this.setSessionScore(min, max, current);
					}
				}
				else if (node.type === 'var_compare') {
					let param1 = this.props.variables.find(gv => gv.uuid === node.data.param1);
					let param2 = this.props.variables.find(gv => gv.uuid === node.data.param2);
					console.log('# param1 = ' + param1.value + '   param2 = ' + param2.value);

					//					console.log('# param1 ',JSON.stringify(param1,null,4));
					//				console.log('# param2 ',JSON.stringify(param1,null,4));


					if (param1 && param2) {
						let result = false;

						if (typeof param1.value === 'string') {
							if (param1.value === '')
								param1.value = 0;
							else
								param1.value = Number.parseInt(param1.value, 10);
						}
						if (typeof param2.value === 'string') {
							if (param2.value === '')
								param2.value = 0;
							else
								param2.value = Number.parseInt(param2.value, 10);
						}
						switch (node.data.operator) {
							case '==': result = (param1.value === param2.value); break;
							case '<=': result = (param1.value <= param2.value); break;
							case '<': result = (param1.value < param2.value); break;
							case '>=': result = (param1.value >= param2.value); break;
							case '>': result = (param1.value > param2.value); break;
							default: break;
						}
						if (result) {
							this.onGotoPageByUUID(node.data.value);
						}
						else if (node.data.elsevalue) {
							this.onGotoPageByUUID(node.data.elsevalue);
						}
					}
				}
			}, node ? node.start : 0));
		});


		page.nodes.forEach(node => {
			node.active = false;
			console.log('startPageTimer');
			this._timerTimeoutArray.push(setTimeout(() => {
				node.active = true;
				this.setState({ trigger: this.state.trigger + 1 });

				if (node.type === 'script') {
					if (this.state.vuppetmasterActive) {
						this._vuppetmaster.speakStop();
						if (node.data)
							this._vuppetmaster.playScript(node.data.script);
					}
				}
				else if (node.type === 'sound') {
					if (node.data && node.data.url) {
						let volume = 1;
						if (node.data.volume) {
							try {
								volume = Number.parseInt(node.data.volume, 10);
								volume = volume / 100;
							}
							catch (e) {};

						}

						var sound = new Howl({
							src: [process.env.PUBLIC_URL + '/assets/sounds/' + node.data.url],
							volume: volume,
						});
						sound.play();
					}
				}
				else if (node.type === 'tts') {
					if (node.data) {
						let text = node.data.text;
						if (this.props.variables) {
							this.props.variables.forEach(element => {
								text = text.replace(element.name, element.value ? element.value : '');
							});
						}
						if (this.state.vuppetmasterActive) {
							this._vuppetmaster.speak({
								tts: {
									voice: 'Vicki',
									text: '<speak>' + text + '</speak>'
								}
							});
						}
					}
				}
				else if (node.type === 'page') {
					if (this.props.mode === 'trainer') {
						this.onGotoPageByUUID(node.data.page);
					}
				}
				else if (node.type === 'image') {
					if (node.data && node.data.background) {
						this.setBackgroundNode(node);
					}
				}

				if (node.end) {
					this._timerTimeoutArray.push(setTimeout(() => {
						this.startPageTimer2(node);
					}, node.end - node.start));
				}
			}, node ? node.start : 0));
		});

	}

	startPageTimer2(node) {
		console.log('startPageTimer end');
		node.active = false;
		this.setState({ trigger: this.state.trigger + 1 });
	}

	onNext() {
		let index = this.state.flat_pages_array.findIndex(p => p.uuid === this.props.page.uuid);
		if (index >= 0) {
			if ((index + 1) < this.state.flat_pages_array.length) {
				this.onGotoPageByUUID(this.state.flat_pages_array[index + 1].uuid);
			}
		}
	}

	onPrev() {
		let index = this.state.flat_pages_array.findIndex(p => p.uuid === this.props.page.uuid);
		if (index >= 0) {
			if ((index - 1) >= 0) {
				this.onGotoPageByUUID(this.state.flat_pages_array[index - 1].uuid);
			}
		}
	}

	onStop() {
		this.setState({ timer: 0 });
		this._timerIntervalArray.forEach((id) => clearInterval(id));
		this._timerTimeoutArray.forEach((id) => clearTimeout(id));

		if (this.props.mode === 'trainer' || this.props.mode === 'testmode' || this.props.mode === 'demo') {
			this.state.vuppetmasterActive && this._vuppetmaster.speakStop();
			this.state.vuppetmasterActive && this._vuppetmaster.playScript(null);
		}
	}

	onRestart() {

		this.props.variables.forEach(element => {
			element.value = '';
		});

		//		this.initPageVariables(this.props.page);
		this.startPageTimer(this.props.page);
		console.log('restartPage');
	}


	onGotoPageByUUID(pageuuid) {
		let page = this.props.tutorial.pages_array.find(p => p.uuid === pageuuid);
		if (page) {
			// found page
			//			this.initPageVariables(page);
			this.onGotoPage(page);
			return;
		}

		let variable = this.props.variables.find(v => v.uuid === pageuuid);
		if (variable) {
			page = this.props.tutorial.pages_array.find(p => p.uuid === variable.value);
			if (page) {
				//				this.initPageVariables(page);
				this.onGotoPage(page);
			}
		}
	}

	onGotoPage(page) {

		// check if current page has ratings
		if (this.hasPageRating(this.props.page)) {

			// store the rating in the sessions
			let rating = [];
			this.props.page.nodes.forEach(n => {
				if (n.type === 'rating') {
					if (n.name) {
						rating.push({
							name: n.name,
							maxRating: n.data && n.data.maxRating,
							result: n.data && n.data.result
						});
					}
				}
			});
			this.setSessionRating(rating);
		}

		if (this.props.mode === 'trainer' && page.name === 'logout') {
			this.logout();
		}

		this.startPageTimer(page);

		// Hack
		setTimeout(() => { this.updateSessionPage(page); }, 500);

		store.dispatch(actionSetPage(page));
	}

	logout() {
		this.onStop();
		RestAPI.logout();
		this.props.history.push('/');
		// hack to reload page, because the Helix Render engine!
		window.location.reload();
	}

	updateSessionPage(page) {
		if (this.props.mode !== 'trainer')
			return;

		let session = clone(this.props.session);
		session.pages_uuid.push(page.uuid);
		session.variables = clone(this.props.variables);
		session.modified = new Date().toISOString();

		// set new page status
		if (!session.status)
			session.status = {};

		if (page.status && page.status.end && !session.status.end)
			session.status.end = true;

		if (page.status && page.status.finished && !session.status.finished)
			session.status.finished = true;

		store.dispatch(actionSetSession(session));
		RestAPI.updateSession(session);
	}

	setSessionScore(min, max, current) {
		if (this.props.mode !== 'trainer')
			return;

		let session = clone(this.props.session);
		if (min)
			session.scoreMin = min;
		if (max)
			session.scoreMax = max;
		if (current)
			session.scoreCurrent = current;

		// convert
		if (typeof session.scoreCurrent === 'string' || session.scoreCurrent instanceof String) {
			session.scoreCurrent = Number(session.scoreCurrent) || 0;
		}
		// convert
		if (typeof session.scoreMin === 'string' || session.scoreMin instanceof String) {
			session.scoreMin = Number(session.scoreMin) || 0;
		}
		// convert
		if (typeof session.scoreMax === 'string' || session.scoreMax instanceof String) {
			session.scoreMax = Number(session.scoreMax) || 0;
		}

		let finished = this.props.variables.find(gv => gv.name === '_briefingFinished_');
		if (finished && finished.value && finished.value > 0)
			session.finished = true;

		session.modified = new Date().toISOString();
		store.dispatch(actionSetSession(session));
		RestAPI.updateSession(session);
	}

	setSessionRating(rate) {
		if (this.props.mode !== 'trainer')
			return;

		let session = clone(this.props.session);
		session.rating = rate;
		session.modified = new Date().toISOString();
		store.dispatch(actionSetSession(session));
		RestAPI.updateSession(session);
	}

	determineThemeCSS(theme, style) {
		if (theme === undefined)
			return;
		if (theme === null)
			return;
		if (typeof theme === 'string' || theme instanceof String)
			return;

		/*
			let sampleTheme = [
				{ 
					theme : 'theme1'
					data : {
						class1 : {	
							color: 'red',
							with: 'll',
						},
						class2 : {	
							color: 'red',
							with: 'll',
						},
					}
				},
			];*/

		if (this.props.themes) {

			let ft = this.props.themes.find((t) => (t.theme === theme.name));
			if (ft && ft.classes) {

				// get class
				let classInstance = ft.classes[theme.class];
				if (classInstance) {
					Object.keys(classInstance).forEach((k) => {
						style[k] = classInstance[k];
					});
				}
			}
		}
	}

	getFilenameByNodeImage(node) {
		let filename = '';
		let media = (node.data.resource && this.props.media && this.props.media.find(m => m.uuid === node.data.resource));
		if (media) {
			filename = API_ROOT + `/media/${media.owner_uuid}&${media.filename}`;
		}
		else {
			filename = API_ROOT + '/media_old/' + node.data.filename;
		}

		return filename;
	}

	hasPageButton(page) {
		return page && page.nodes && page.nodes.find(n => n.type === 'button');
	}

	hasPageRating(page) {
		return page && page.nodes && page.nodes.find(n => n.type === 'rating');
	}


	setBackgroundNode(node) {

		if (!node.active)
			return null;

		let style = {};
		this.determineThemeCSS(node.theme, style);

		if (node.css) {
			try {
				style = clone(node.css);
				style.position = 'absolute';
				style.zIndex = '-1';
			} catch (e) {
				console.error('css parse error: ', e);
			}
		}

		//		console.log('### render page:', style, JSON.stringify(node, null, 4));

		switch (node.type) {
			case 'image':
				if (node.data && node.data.background) {

					let filename = this.getFilenameByNodeImage(node);

					var backgroundStyle = {
						position: 'absolute',
						width: "100%",
						height: "100%",
						backgroundSize: 'cover',
						zIndex: '-1',
						backgroundImage: "url(" + filename + ")"
					};

					this.setState({ backgroundStyle: backgroundStyle })
					return null;
				}
				break;
			default:
				break;
		}
	}

	handleScriptLoad() {

		this._vuppetmaster = new window.Vuppetmaster();
		this.setState( { vmReady: true });
		this.myComponentDidMount();
	}


	renderNormalNode(node, index) {

		/* inline style
		 https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Properties_Reference
		*/

		if (!node.active)
			return null;

		let style = {};
		this.determineThemeCSS(node.theme, style);

		if (node.css && node.css !== '' && node.css !== 'undef') {

			// use node css
			try {
				let nodestyle = clone(node.css);
				Object.keys(nodestyle).forEach((k) => {
					style[k] = nodestyle[k];
				});

				style.position = 'absolute';
			} catch (e) {
				console.error('css parse error: ', node.name, '#', node.type, '#', e);
			}
		}

		//		console.log('### render page:', style, JSON.stringify(node, null, 4));
		switch (node.type) {
			case 'text':

				if (!node.data)
					return null;
				let text = node.data.text;
				if (this.props.variables) {
					this.props.variables.forEach(element => {
						text = text.replace(element.name, element.value ? element.value : '');
					});
				}
				return (
					<div key={index} style={style}>{text}</div>
				)
			case 'image':
				if (!node.data)
					return null;
				if (node.data.background)
					return null;
				else {
					let filename = this.getFilenameByNodeImage(node);
					return (<div key={index}>
						<img src={filename} alt="_image" style={style} />
					</div>);
				}
			case 'button':
				style.zIndex = 1000;
				return (
					<Button key={index} style={style} onClick={() => {
						console.log('## button ', node.data);
						this.triggerLogoutTimer();
						if (node.data.page)
							this.onGotoPageByUUID(node.data.page);
					}}>{node.data ? node.data.text : ''}</Button>
				)
			case 'rating':
				if (!node.data)
					return null;
				if (!node.data.maxRating)
					return null;
				return (
					<Rating
						key={index}
						style={style}
						size='massive'
						icon='star'
						defaultRating={node.data.maxRating}
						maxRating={node.data.maxRating}
						onRate={(e, d) => {
							node.data.result = d.rating;
						}}
					/>
				)
			case 'script':
				return null;
			case 'tts':
				return null;
			case 'page':
				return null;
			default:
				return null;
		}
	}

	/**** render *********************************************************************************
	 * 
	 * 
	 * 
	 * 
	*/
	render() {

		let pageslist = this.state.flat_pages_array && this.state.flat_pages_array.map((p, index) => {
			return (
				<List.Item
					key={index}
					onClick={() => { this.onGotoPageByUUID(p.uuid); }}
				>
					<List.Content>
						<List.Header>
							<div style={{ backgroundColor: (p.uuid === (this.props.page && this.props.page.uuid) ? '#00ff00' : null) }} > {p.name} </div>
						</List.Header>
					</List.Content>
				</List.Item>
			)
		});

		let variableslist = this.props.variables && this.props.variables.map((v, index) => {
			if (v.type !== 'count')
				return null;
			else
				return (
					<List.Item key={index} >
						<List.Content>
							<List.Header>
								<div> {v.name} = {v.value} </div>
							</List.Header>
						</List.Content>
					</List.Item>
				)
		});

		var outputNormal = (this.props.page && this.props.page.nodes) ? this.props.page.nodes.map((node, index) => {
			return (
				this.renderNormalNode(node, index)
			)
		}) : null;

		let showAvatar = (this.props.mode !== 'preview');

		if (showAvatar && this.props.grid.active && this.props.mode !== 'trainer')
			showAvatar = false;

		return (

			<div id='maintrainer'>
 			{this.state.vmurl !== '' &&
					<Script
						url={this.state.vmurl}
						onCreate={() => {
						}}
						onError={() => {
							console.log('script load error');
							this.setState({ scriptError: true })
						}}
						onLoad={this.handleScriptLoad.bind(this)}
					/>}

		<ReactResizeDetector handleWidth handleHeight>

			{(data) => {
					let width = data.width;
					let height = data.height;
					
					if (!width) width = 10;
					if (!height) height = 10;

					const new_ratio = 1.77;
					let newWidth = width;
					let newHeight = height;
					let newMarginLeft = 0;
					let newMarginTop = 0;
					let currentRatio = width / height;
					if (currentRatio < new_ratio) {
						newHeight = width / new_ratio;
						newMarginTop = (height - newHeight) / 2;
					}
					else {
						newWidth = height * new_ratio;
						newMarginLeft = (width - newWidth) / 2;
					}
          let top = (height - newHeight) / 2;
          if (top < 0) top = 0;

          let style = {
						position: 'absolute',
            width: newWidth + "px",
            height: newHeight + "px",
						marginLeft: newMarginLeft + 'px',
						marginTop: newMarginTop + 'px',
					};

					if (this.state.vmReady) {
					return (


			<div id='renderview' style={style} >
				{!this.state.vuppetmasterActive && this.props.mode === 'trainer' && (
					<TrainerStart mode={'loading'} progress={this.state.progress}
					showProgress={this.state.progress > 0 || 0.001}
					/>)}

				{showAvatar && (<RenderView showwait={false}> </RenderView>)}

				<div id='backgroundborder' 
				>
				</div>
				<div id='background'>
					{this.state.backgroundStyle && (
						<div style={this.state.backgroundStyle}></div>
					)}
				</div>
				<div id='trigger'>
					{this.state.trigger}
				</div>
				<div id='normal'>
					{outputNormal}
				</div>
				{this.props.mode === 'trainer' && (
					<div id='helpbutton'>
						<Button
							icon
							size={'mini'}
							onClick={() => { this.setState({ openHelp: true }); }}
						>
							Hilfe
							<Icon name={'help'} />
						</Button>
					</div>)}


				{this.props.mode === 'testmode' && (
					<div id="debugmenue">
						<Segment>
							<div style={{ width: '333px' }}>
								Page: {this.props.page ? this.props.page.name : '--'}
							</div>
							<br />

							<IconButton iconname='angle left' tooltip='previous page' func={() => this.onPrev()} />
							<IconButton iconname='stop' tooltip='stop page' func={() => {
								this.onStop();
								this.initPage(this.props.page);
							}} />
							<IconButton iconname='play' tooltip='start page' func={() => this.onRestart()} />
							<IconButton iconname='angle right' tooltip='next page' func={() => this.onNext()} />
							<br />
							<IconButton iconname='redo' tooltip='update page' func={() => this.updateTutorial()} />
							Timer: {this.state.timer}
							<br />
							<div id='pagelist'>
								<List size='mini' selection verticalAlign='middle'>
									{pageslist}
								</List>
							</div>
							<br />
							Variables:
							<div id='variableslist'>
								<List size='mini' selection verticalAlign='middle'>
									{variableslist}
								</List>
							</div>

						</Segment>
					</div >)
				}

				{this.props.mode === 'demo' && (
					<div id="debugmenue">
						<Segment>
							<div style={{ width: '333px' }}>
								Page: {this.props.page ? this.props.page.name : '--'}
							</div>
							<br />

							<IconButton iconname='angle left' tooltip='previous page' func={() => this.onPrev()} />
							<IconButton iconname='stop' tooltip='stop page' func={() => {
								this.onStop();
								this.initPage(this.props.page);
							}} />
							<IconButton iconname='play' tooltip='start page' func={() => this.onRestart()} />
							<IconButton iconname='angle right' tooltip='next page' func={() => this.onNext()} />
							<br />
							<div id='pagelist'>
								<List size='mini' selection verticalAlign='middle'>
									{pageslist}
								</List>
							</div>
						</Segment>
					</div >)
				}

				<HelpModal
					open={this.state.openHelp}
					onClose={() => { this.setState({ openHelp: false }) }}
				/>
				{this.state._images.length > 0 && (
					<Preload
						images={this.state._images}
						autoResolveDelay={3000}
						onError={() => {
							this.setState({ _images: [] });
							console.log('# handlePreloadError');
						}}
						onSuccess={() => {
							this.setState({ _images: [] });
							console.log('# handlePreloadSuccess');
						}}
						resolveOnError={true}
						mountChildren={true}
					>
						<h1>success</h1>
					</Preload>)}

			</div>

					)}
					else {
						return (<div>Loading</div>)
					}
				}
				}

			</ReactResizeDetector>
		</div>

		);
	}
}




TrainerView = connect(
	state => ({
		_vuppetmaster: state.vuppetmaster.vuppetmaster,
		tutorial: state.tutorial.tutorial,
		session: state.session.session,
		themes: state.main.themes,
		variables: state.main.variables,
		media: state.media,
		grid: state.settings.grid,
		page: state.page.page
	}),
	{},
)(TrainerView);

export default withRouter(TrainerView);
