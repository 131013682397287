import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styles from './Feedbacks.module.scss';

import { Dropdown, Flag } from 'semantic-ui-react';

import { connect } from 'react-redux';
import store from '../../../redux/store';

import RestAPI from '../../../RestAPI';
import DataTable from '../../3_organisms/DataTable/DataTable';
import { actionSetCompany } from '../../../redux/actions/actionCompany';
import { actionSetFeedbackSession } from '../../../redux/actions/actionFeedback';
import { arrayDropDown } from '../../../helpers/arrayDropDown';
import reducerViewControl from '../../../redux/reducer/reducerViewControl';

class Feedbacks extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  updateView() {
    RestAPI.members();
    RestAPI.modules();
    RestAPI.companies();
    RestAPI.feedbacks();
  }

  onSelect(session_uuid) {
    store.dispatch(actionSetFeedbackSession(session_uuid));
    this.props.history.push('/admin/feedback/' + session_uuid);
  }

  render() {
    const { companies, company, members, modules, feedbacks } = this.props;

    const {} = this.state;

    let reducedSessions =
      feedbacks &&
      feedbacks
        .map((feedback) => feedback.session_uuid);

    reducedSessions = [...new Set(reducedSessions)];

    const rows =
      reducedSessions &&
      reducedSessions.map((session_uuid) => {
        const feedback = feedbacks.find(
          (feedback) => feedback.session_uuid === session_uuid
        );
        const module =
          modules &&
          modules.find((module) => module.uuid === feedback.module_uuid);
        const member =
          members &&
          members.find((member) => member.uuid === feedback.member_uuid);
        const feedbackCompany =
          module &&
          companies &&
          companies.find((company) => company.uuid === module.company_uuid);

        if (company && company.uuid !== feedbackCompany.uuid)
          return null;

        return {
          name: member && member.name,
          session_uuid: session_uuid,
          created: feedback.created,
          company: feedbackCompany && feedbackCompany.name,
          module: module && module.name,
          language: feedback.language,
        };
      }).filter(Boolean);

    const language = (feedback) => {
      return (
        <Flag
          key={feedback.language}
          name={feedback.language === 'en' ? 'gb' : feedback.language}
        />
      );
    };

    const status = (feedback) => {

      return (
        <div></div>
      );
    };

    const columns = [
      {
        name: 'Created',
        key: 'created',
        disabled: true,
        singleLine: true,
        type: 'date',
      },
      {
        name: 'Name',
        key: 'name',
        required: true,
        singleLine: true,
      },
      {
        name: 'Module',
        key: 'module',
        disabled: true,
        singleLine: true,
      },
      {
        name: 'Language',
        key: 'language',
        render: (feedback, key) => {
          return language(feedback);
        },
        disabled: true,
        singleLine: true,
      },
      {
        name: 'Status',
        key: 'status',
        render: (feedback, key) => {
          return status(feedback);
        },
        disabled: true,
        singleLine: true,
      },
      !company && {
        name: 'Company',
        key: 'company',
        disabled: true,
        singleLine: true,
      },
    ];

    const optCompanies = arrayDropDown(companies, 'all');

    return (
      <div className={styles.main}>
        <h3>Feedback:</h3>
        Company:
        {companies && companies.length > 1 && (
          <Dropdown
            placeholder="Filter Company"
            value={company ? company.uuid : undefined}
            selection
            options={optCompanies}
            onChange={(e, d) => {
              store.dispatch(
                actionSetCompany(
                  companies.find((company) => company.uuid === d.value)
                )
              );
              this.updateView(d.value);
            }}
          />
        )}
        <div>
          <DataTable
            name="feedbacks"
            placeholder="Filter name"
            sortable={true}
            filter={'name'}
            columns={columns}
            rows={rows}
            onSelect={(row) => this.onSelect(row.session_uuid)}
            updateView={() => this.updateView()}
          />
        </div>
      </div>
    );
  }
}

// You have to connect() to any reducers that you wish to connect to yourself
Feedbacks = connect(
  (state) => ({
    login: state.login.login,
    user: state.user.user,
    users: state.user.users,
    members: state.member.members,
    modules: state.module.modules,
    feedbacks: state.feedback.feedbacks,
    companies: state.company.companies,
    company: state.company.company,
  }),
  {} // bind account loading action creator
)(Feedbacks);

export default withRouter(Feedbacks);
