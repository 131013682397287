import React, { Component } from 'react';
import { Dropdown, Segment } from 'semantic-ui-react'

import EditLine from '../../1_atom/EditLine/EditLine.js';

import { connect } from 'react-redux';
import store from './../../../redux/store';
import { actionSetNode } from '../../../redux/actions/actionPage';

class NodeVarPage extends Component {

	getVarType(uuid)
	{
		let v = this.props.variables.find( v => v.uuid === uuid);
		if (v)
			return v.type;
		else
			return 'undef';
	}


	render() {

		let optVars = [];
		if (this.props.variables)
			this.props.variables.forEach(element => {
				if (element.type === 'page')
					optVars.push({
						key: element.uuid,
						text: element.name,
						value: element.uuid
					});
			});

		let optPages = [];
		if (this.props.tutorial.pages_array) {
			this.props.tutorial.pages_array.forEach(p => {
				optPages.push({
					key: p.uuid,
					text: p.name,
					value: p.uuid
				});
			});
		}
		if (this.props.tutorial.variables) {
			this.props.tutorial.variables.forEach(v => {
				if (v.type === "page") {

					optPages.push({
						key: v.uuid,
						text: "Var: " + v.name,
						value: v.uuid
					});
				}
			});
		}

		return (
			<Segment>
				<EditLine label='Start:' text={this.props.node.start} onChanged={(text) => store.dispatch(actionSetNode({ ...this.props.node, start: text }))} />
				<Dropdown
					placeholder='Select variable'
					value={this.props.node.data.var ? this.props.node.data.var : ''}
					selection options={optVars}
					onChange={(e, d) => {
						let node = JSON.parse(JSON.stringify(this.props.node));
						node.data.var = d.value;
						store.dispatch(actionSetNode(node));
					}}
				/>
				<Dropdown
				placeholder='Select'
				value={(this.props.node.data && this.props.node.data.value) ? this.props.node.data.value : ''}
				selection options={optPages}
				onChange={(e, d) => {
					let node = JSON.parse(JSON.stringify(this.props.node));
					if (!node.data)
						node.data = { page: '', pagename: '' };
					node.data = { ...node.data, value: d.value }
					store.dispatch(actionSetNode(node));
				}} />
			</Segment>
		)
	}
}

NodeVarPage = connect(
	state => ({
		node: state.page.node,
		page: state.page.page,
		tutorial: state.tutorial.tutorial,
		variables: state.main.variables,
	}),
	{},
)(NodeVarPage);


export default NodeVarPage;

