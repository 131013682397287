import React, { Component } from 'react';

import { Icon, Button, Message } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { cx } from '../../../helpers/classNames.helper';

import styles from './ControlModal.module.scss';

const Sequences = (props) => {
  return (
    <div>
      {props.data &&
        props.data.map((sequence) => {
          return (
            <div className={styles.sequence_button} key={sequence.uuid}>
              <Button
                fluid
                size={'mini'}
                color={sequence.uuid === props.current ? 'green' : null}
                onClick={() => {
                  props.onClick(sequence.uuid);
                }}
              >
                {sequence.name}
              </Button>
            </div>
          );
        })}
    </div>
  );
};

class ControlModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      speed: 1,
      showSequences: false,
      currentSequence: null,
      sequences: [],
      project: null,
      active: false,
    };
  }

  componentDidMount() {
    this.setState({
      currentSequence: this.props.vuppetmaster.trainer.getCurrentSequence(),
    });
    this.props.vuppetmaster.trainer.on('sequence.changed', () => {
      this.setState({
        currentSequence: this.props.vuppetmaster.trainer.getCurrentSequence(),
      });
    });
  }

  componentWillUnmount() {}

  onGoto(uuid) {
    const seq = this.props.vuppetmaster.trainer.getSequenceByUUID(uuid);
    if (seq) seq.play();
  }

  onGotoNext() {
    const uuid = this.props.vuppetmaster.trainer.getNextSequenceUUID(
      this.state.currentSequence.uuid
    );
    if (uuid) this.onGoto(uuid);
  }

  onGotoPrev() {
    const uuid = this.props.vuppetmaster.trainer.getPrevSequenceUUID(
      this.state.currentSequence.uuid
    );
    if (uuid) this.onGoto(uuid);
  }

  render() {
    const { sequences } = this.props;
    const { showSequences, currentSequence, active } = this.state;

    return (
      <div>
        <div
          className={
            active
              ? cx(styles.mainSticky, styles.hiddenSticky)
              : styles.mainSticky
          }
        >
          <Button
            color={'blue'}
            icon="settings"
            onClick={() => {
              this.setState({ active: true });
            }}
          ></Button>
        </div>
        <div className={active ? styles.main : cx(styles.main, styles.hidden)}>
          <Message>
            <div className={styles.close}>
              <Button
                icon="close"
                size={'mini'}
                onClick={() => {
                  this.setState({ active: false });
                }}
              ></Button>
            </div>
            <div>
              <Icon color={'blue'} size={'big'} name={'settings'}></Icon>
              {currentSequence && (
                <div className={styles.current}>
                  Current Sequence: {currentSequence.name}
                </div>
              )}
            </div>
            <div>
              <Button.Group size={'mini'}>
                <Button
                  icon
                  size="mini"
                  onClick={() => {
                    this.onGotoPrev();
                  }}
                >
                  <Icon name="step backward" />
                  Prev
                </Button>
                <Button
                  icon
                  size={'mini'}
                  active={showSequences}
                  onClick={() => {
                    this.setState({ showSequences: !this.state.showSequences });
                  }}
                >
                  <Icon name="unordered list" />
                  List
                </Button>
                <Button
                  icon
                  size="mini"
                  onClick={() => {
                    this.onGotoNext();
                  }}
                >
                  <Icon name="step forward" />
                  Next
                </Button>
              </Button.Group>
            </div>
            {showSequences && (
              <Message className={styles.sequences}>
                <Sequences
                  onClick={(uuid) => {
                    this.onGoto(uuid);
                  }}
                  data={sequences}
                  current={currentSequence && currentSequence.uuid}
                />
              </Message>
            )}
          </Message>
        </div>
      </div>
    );
  }
}

ControlModal = connect(
  (state) => ({
    login: state.login.login,
    language: state.module.language,
    session: state.session.session,
    member: state.member.member,
    vuppetmaster: state.vuppetmaster.vuppetmaster,
    module: state.module.module,
    control: state.settings.control,
  }),
  {}
)(ControlModal);

export default ControlModal;
