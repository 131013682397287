import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import store from '../../../redux/store';

import styles from './UserPreview.module.scss';

import RestAPI from '../../../RestAPI';
import { actionSetSession } from '../../../redux/actions/actionSession';
import {
  actionSetLanguage,
} from '../../../redux/actions/actionModule';
import { parseQueryString } from '../../../helpers/URL.helper';

const { detect } = require('detect-browser');
const browser = detect();

const MobileDetect = require('mobile-detect');
const md = new MobileDetect(window.navigator.userAgent);

class UserPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      module: null,
      language: 'de',
    };
  }

  async componentDidMount() {
    this.setState({ selected: false });

    const user = this.props.user;

    let clientdata = {};
    if (md.mobile()) {
      clientdata.mobile = {
        phone: md.phone(),
        tablet: md.tablet(),
        userAgent: md.userAgent(),
        os: md.os(),
        isiphone: md.is('iPhone'),
      };
    }

    clientdata.browser = {
      name: browser.name,
      version: browser.version,
      os: browser.os,
      ip: null,
    };

    // create a new session
    let newSession = {
      version: '2.0.0',
      tutorial_uuid: '',
      module_uuid: null,
      pages_uuid: null,
      sequences: [],
      user_uuid: user.uuid,
      member_uuid: null,
      finished: false, // obsolete
      status: {
        finished: false,
        end: false,
      },
      scoreMin: 0,
      scoreMax: 0,
      scoreCurrent: 0,
      variables: null,
      clientdata: clientdata,
      created: new Date().toISOString(),
      modified: new Date().toISOString(),
    };

    const result = await RestAPI.createSession(newSession);
    if (result) {
      newSession.uuid = result.uuid;
      store.dispatch(actionSetSession(newSession));
    }

    //
    // Get user modules
    //
    const query = parseQueryString(window.location.search);
    const module_uuid = query.module;
    const language = query.lang;

    if (!module_uuid) return;

    const module = await RestAPI.moduleSingle(module_uuid);

    if (module.storybuilder_key) {
      store.dispatch(actionSetLanguage(language));
    }

    // fill new session
    newSession.module_uuid = module.uuid;
    newSession.member_uuid = user.uuid;
    newSession.finished = null;
    newSession.status = {
      end: false,
      finished: false,
    };

    newSession.scoreCurrent = 0;

    newSession.created = new Date().toISOString();
    newSession.variables = [];
    newSession.clientdata.ip = this.props.ipdata;

    let id = setInterval(() => {
      if (this.props.ipdata) {
        clearInterval(id);
        newSession.clientdata.ip = this.props.ipdata;
        RestAPI.updateSession(newSession).then((result) => {
          if (result) {
            store.dispatch(actionSetSession(newSession));
          }
          this.props.history.push('/trainer2');
        });
      }
    }, 100);
  }

  render() {
    const { vuppetmaster, module } = this.props;
    return (
      <div className={styles.main}>
        <div>
          <Icon size={'large'} name="circle notch" loading></Icon>Loading
        </div>
      </div>
    );
  }
}

// You have to connect() to any reducers that you wish to connect to yourself
UserPreview = connect(
  (state) => ({
    ipdata: state.login.ipdata,
    session: state.session.session,
    user: state.user.user,
    module: state.module.module,
    vuppetmaster: state.vuppetmaster.vuppetmaster,
  }),
  {} // bind account loading action creator
)(UserPreview);

export default withRouter(UserPreview);
