import React, { Component } from 'react';

import { Icon, Button, Message, Form, TextArea } from 'semantic-ui-react';
import { connect } from 'react-redux';
import RestAPI from '../../../RestAPI';
import styles from './FeedbackModal.module.scss';
import { cx } from '../../../helpers/classNames.helper';

class FeedbackModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSequence: null,
      text: '',
      thanks: false,
      replaying: false,
    };
    this._replayTimer = null;
    this._replay = false;
  }

  componentDidMount() {
    this.setState({
      currentSequence: this.props.vuppetmaster.trainer.getCurrentSequence(),
    });
    this.props.vuppetmaster.trainer.on('sequence.changed', () => {
      this.setState({
        currentSequence: this.props.vuppetmaster.trainer.getCurrentSequence(),
      });
    });
  }

  componentWillUnmount() {}

  onSend() {
    const data = {
      created: new Date().toISOString(),
      text: this.state.text,
      module_uuid: this.props.module.uuid,
      member_uuid: this.props.member.uuid,
      user_uuid: this.props.user.uuid,
      sequence_uuid: this.state.currentSequence.uuid,
      sequence_name: this.state.currentSequence.name,
      language: this.props.language,
      session_uuid: this.props.session.uuid,
    };
    RestAPI.createFeedback(data);
    this.setState({ text: '' });
  }

  onGoto(uuid) {
    const seq = this.props.vuppetmaster.trainer.getSequenceByUUID(uuid);
    if (seq) seq.play();
  }

  onGotoNext() {
    const uuid = this.props.vuppetmaster.trainer.getNextSequenceUUID(
      this.state.currentSequence.uuid
    );
    if (uuid) this.onGoto(uuid);
    return uuid ? true : false;
  }

  onGotoPrev() {
    const uuid = this.props.vuppetmaster.trainer.getPrevSequenceUUID(
      this.state.currentSequence.uuid
    );
    if (uuid) this.onGoto(uuid);
    return uuid ? true : false;
  }

  render() {
    const { onActive, active } = this.props;
    const { text } = this.state;
    return (
      <div>
        <div
          className={
            active
              ? cx(styles.mainSticky, styles.hiddenSticky)
              : styles.mainSticky
          }
        >
          <Button
            color={'blue'}
            icon="chat"
            onClick={() => {
              onActive(true);
            }}
          ></Button>
        </div>
        <div className={active ? styles.main : cx(styles.main, styles.hidden)}>
          <Message>
            <div className={styles.close}>
              <Button
                icon="close"
                size={'mini'}
                onClick={() => {
                  onActive(false);
                }}
              ></Button>
            </div>
            <div>
              <Icon color={'blue'} size={'big'} name={'chat'}></Icon>
              Feedback
            </div>{' '}
            <div className={styles.form}>
              {' '}
              <Form>
                <TextArea
                  value={text}
                  placeholder=""
                  onChange={(e, { value }) => this.setState({ text: value })}
                />
              </Form>
            </div>
            <div className={styles.sendbutton}>
              <Button
                size={'mini'}
                disabled={text === ''}
                onClick={() => {
                  this.onSend();
                  this.setState({ thanks: true });
                  setTimeout(() => {
                    this.setState({ thanks: false });
                    onActive(false);
                  }, 500);
                }}
              >
                {'Senden'}
              </Button>
            </div>
            <div className={styles.navigation}>
              <Button.Group size={'mini'}>
                <Button
                  icon="step backward"
                  size="mini"
                  onClick={() => {
                    if (this._replay) {
                      onActive(false);
                      if (!this.onGotoPrev())
                        this.props.vuppetmaster.trainer.rePlaySequence();
                    } else {
                      this._replay = true;
                      setTimeout(() => {
                        onActive(false);
                        this._replay = false;
                        this.props.vuppetmaster.trainer.rePlaySequence();
                      }, 500);
                    }
                  }}
                ></Button>
                <Button
                  icon="step forward"
                  size="mini"
                  onClick={() => {
                    onActive(false);
                    setTimeout(() => {
                      this.onGotoNext();
                    }, 200);
                  }}
                ></Button>
              </Button.Group>
            </div>
          </Message>
        </div>
      </div>
    );
  }
}

FeedbackModal = connect(
  (state) => ({
    login: state.login.login,
    language: state.module.language,
    session: state.session.session,
    member: state.member.member,
    user: state.user.user,
    vuppetmaster: state.vuppetmaster.vuppetmaster,
    module: state.module.module,
    feedback: state.settings.feedback,
  }),
  {}
)(FeedbackModal);

export default FeedbackModal;
