import React, { Component } from 'react';
import { Dropdown, Segment } from 'semantic-ui-react'


import EditLine from '../../1_atom/EditLine/EditLine.js';

import { connect } from 'react-redux';
import store from './../../../redux/store';
import { actionSetNode } from '../../../redux/actions/actionPage';

class NodePage extends Component {

	render() {

		let opt = [];
		this.props.tutorial.pages_array.forEach(element => {
			opt.push( {
				key: element.uuid,
				text: element.name,
				value: element.uuid
			})
		});
		if (this.props.tutorial.variables)
			this.props.tutorial.variables.forEach(v => {
				if (v.type === "page") {

					opt.push({
						key: v.uuid,
						text: "Var: " + v.name,
						value: v.uuid
					});
				}
			});



		return (
			<Segment>
				{this.props.node.name}
				<EditLine label='Start:' text={this.props.node.start} onChanged={(text) => store.dispatch(actionSetNode( { ...this.props.node, start:  text } )) } />
				<Dropdown
					placeholder='Select'
					value={(this.props.node.data&&this.props.node.data.page)?this.props.node.data.page:''}
					selection options={opt}
					onChange={(e, d) => {
						console.log('#### onDropdownChanged',d.value);

						let node = JSON.parse(JSON.stringify(this.props.node));
						if (!node.data)
							node.data = { page: ''	};
						node.data = { ...node.data, page: d.value }

						let page = this.props.tutorial.pages_array.find(p => p.uuid === d.value);
						if (page) {
							node.data = { ...node.data, pagename: page.name };
						}
						else {
							let v = this.props.tutorial.variables.find(v => v.uuid === d.value);
							if (v)
								node.name = v.name;
						}




						store.dispatch(actionSetNode(node));
					}}
				/>
			</Segment>
		)
	}
}

NodePage = connect(
	state => ({
		node: state.page.node,
		tutorial: state.tutorial.tutorial,
	}),
	{},
)(NodePage);


export default NodePage;

