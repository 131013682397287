import React, { Component } from 'react';
import { Dropdown, Segment } from 'semantic-ui-react';

import EditLine from '../../1_atom/EditLine/EditLine.js';

import { connect } from 'react-redux';
import store from '../../../redux/store';
import { actionSetNode } from '../../../redux/actions/actionPage';

class NodeSound extends Component {
  render() {
    const sounds = [
      {
        name: '--',
        url: '',
      },
      {
        name: 'incoming',
        url: 'data_incoming.mp3',
      },
      {
        name: 'infowait',
        url: 'infowait.mp3',
      },
      {
        name: 'response_negative',
        url: 'response_negative.mp3',
      },
      {
        name: 'response_particle_stars',
        url: 'response_particle_stars.mp3',
      },
      {
        name: 'response_positive',
        url: 'response_positive.mp3',
      },
      {
        name: 'wrong_fuzzno',
        url: 'wrong_fuzzno.mp3',
      },
      {
        name: 'wrong_wowbass',
        url: 'wrong_wowbass.mp3',
      },
    ];

    let opt = [];
    sounds.forEach((element) => {
      opt.push({
        key: element.name,
        text: element.name,
        value: element.url,
      });
    });

    return (
      <Segment>
        {this.props.node.name}
        <EditLine
          label="Start:"
          text={this.props.node.start}
          onChanged={(text) =>
            store.dispatch(actionSetNode({ ...this.props.node, start: text }))
          }
        />
        <EditLine
          label="Volume:"
          text={this.props.node.data ? this.props.node.data.volume : ''}
          onChanged={(text) => {
            if (!this.props.node.data) this.props.node.data = { volume: text };
            this.props.node.data = { ...this.props.node.data, volume: text };
            store.dispatch(actionSetNode(this.props.node));
          }}
        />

        <Dropdown
          placeholder="Select"
          value={
            this.props.node.data && this.props.node.data.url
              ? this.props.node.data.url
              : ''
          }
          selection
          options={opt}
          onChange={(e, d) => {
            console.log('#### onDropdownChanged', d.value);

            let node = JSON.parse(JSON.stringify(this.props.node));
            if (!node.data) node.data = { url: '' };
            node.data = { ...node.data, url: d.value };
            node.name = sounds.find((s) => s.url === d.value).name;

            store.dispatch(actionSetNode(node));
          }}
        />
      </Segment>
    );
  }
}

NodeSound = connect(
  (state) => ({
    node: state.page.node,
    scripts: state.main.scripts,
  }),
  {}
)(NodeSound);

export default NodeSound;
