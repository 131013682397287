import React, {Component} from 'react';
import {Button,} from 'semantic-ui-react'
import EditLine from '../../1_atom/EditLine/EditLine.js';

import {connect} from 'react-redux';
import store from '../../../redux/store';
import {actionSetTutorial} from '../../../redux/actions/actionTutorial'

import RestAPI from '../../../RestAPI';

class Tutorial extends Component {

  constructor(props) {
    super(props);

    this.state = {
      name: '',
      vuppetmaster_key: '',
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.tutorial && nextProps.tutorial.uuid !== this.props.tutorial.uuid)
      this.setState({
        name: '',
        vuppetmaster_key: '',
      });
  }

  onApply() {
    if (!this.props.tutorial) {
      return false;
    }

    let tutorial = {
      ...this.props.tutorial,
    };
    tutorial.name = this.state.name !== '' ? this.state.name : this.props.tutorial.name;
    tutorial.vuppetmaster_key = this.state.vuppetmaster_key !== '' ? this.state.vuppetmaster_key : this.props.tutorial.vuppetmaster_key;

    store.dispatch(actionSetTutorial(tutorial));

    RestAPI.updateTutorial(tutorial).then((result) => {
      this.props.update();
    });

  }

  render() {
    const { tutorial } = this.props;
    return tutorial && (
      <div>
        UUID: {tutorial.uuid}
        <EditLine label='Name:' text={tutorial.name} onChanged={(text) => this.setState({name: text})}/>
        <EditLine label='Key:' text={tutorial.vuppetmaster_key} onChanged={(text) => this.setState({vuppetmaster_key: text})}/>
        <Button onClick={() => this.onApply()}>Apply</Button>
      </div>
    )
  }
}


Tutorial = connect(
  state => ({
    tutorial: state.tutorial.tutorial,
  }),
  {},
)(Tutorial);
export default Tutorial;

