
export const getTestViewUrl = (tutorialuuid,pageuuid) => {
	const hostname = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
	let url = hostname + '/' + process.env.REACT_APP_DEPLOY +'/testmode/' + tutorialuuid + (pageuuid?('&' + pageuuid):'');
	return url;
};

export const getDemoUrl = (tutorialuuid,pageuuid) => {
	const hostname = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
	let url = hostname + '/' + process.env.REACT_APP_DEPLOY +'/demo/' + tutorialuuid + (pageuuid?('&' + pageuuid):'');
	return url;
};

