import React, { Component } from 'react';

import { Icon, Button, Segment, Message, Label } from 'semantic-ui-react';
import { connect } from 'react-redux';

import styles from './DebugView.module.scss';

const Statistics = (props) => {
  const all = props.data.length;
  const loaded = props.data.filter((entry) => entry.loaded).length;
  const played = props.data.filter((entry) => entry.played).length;
  return (
    <div>
      {`total ${all}/${loaded}/${played}`}
      <table>
        <thead>
          <tr>
          <th>model</th>
          <th>text</th>
          <th>length</th>
            <th>loaded</th>
            <th>played</th>
          </tr>
        </thead>
        <tbody>
          {props.data &&
            props.data.map((model) => {
              return model.tts.map((tts, index) => {
                return (
                  <tr key={index+model.name}>
                    <td>{model.name}</td>
                    <td>{tts.text}</td>
                    <td>{tts.length}</td>
                    <td>{tts.loaded ? '✓' : '-'}</td>
                    <td>{tts.played ? '✓' : '-'}</td>
                  </tr>
                );
              });
            })}
        </tbody>
      </table>
    </div>
  );
};
const Variables = (props) => {
  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {props.data &&
            props.data.map((variable) => {
              return (
                <tr key={variable.uuid}>
                  <td>{variable.name}</td>
                  <td>{variable.value}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

const Sequences = (props) => {
  return (
    <div>
      <table>
        <tbody>
          {props.data &&
            props.data.map((sequence) => {
              return (
                <tr key={sequence.uuid}>
                  <td>
                    {' '}
                    <div className={styles.sequence_button} key={sequence.uuid}>
                      <Button
                        fluid
                        size={'mini'}
                        color={sequence.uuid === props.current ? 'green' : null}
                        onClick={() => {
                          props.onClick(sequence.uuid);
                        }}
                      >
                        {sequence.name}
                      </Button>
                    </div>
                  </td>
                  <td>{sequence.loaded ? '✓' : ''}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

class DebugView extends Component {
  constructor(props) {
    super(props);

    this._showStatButton = true; //(process.env.NODE_ENV !== 'production');

    this.state = {
      speed: 1,
      showVariables: false,
      showSequences: false,
      showStatistics: false,
      currentSequence: null,
      statisticsTTS: null,
      sequences: [],
      project: null,
    };
    this._timerVmID = 0;
    this._timerStatisticsID = 0;
  }

  componentDidMount() {
    this._timerVmID = setInterval(() => {
      if (this.props.vuppetmaster) {
        clearInterval(this._timerVmID);
        this.init();
      }
    }, 100);
    if (this._showStatButton) {
      this._timerStatisticsID = setInterval(() => {
        if (this.props.vuppetmaster) {
          const stat = this.props.vuppetmaster.statistics.getTTS();
          this.setState({
            statisticsTTS: stat,
          });
        }
      }, 500);
    }
  }

  componentWillUnmount() {
    if (this._timerVmID) clearInterval(this._timerVmID);
    if (this._timerStatisticsID) clearInterval(this._timerStatisticsID);
  }

  init() {
    this.props.vuppetmaster.trainer.on('project.loaded', () => {
      const sequences = this.props.vuppetmaster.trainer.getSequenceFlow();
      const project =  this.props.vuppetmaster.getSceneManager().getProject();

      this.setState({
        project,
        variables: project.variables,
        sequences,
      });
    });
    this.props.vuppetmaster.trainer.on('sequence.changed', () => {
      const currentSeq = this.props.vuppetmaster.trainer.getCurrentSequence();
      const sequences = this.props.vuppetmaster.trainer.getSequenceFlow();

      this.setState({ currentSequence: currentSeq, sequences });
    });
    this.props.vuppetmaster.trainer.on('variable.changed', (newVariable) => {
      let variables = this.state.variables.map((variable) => {
        if (variable.uuid === newVariable.uuid) {
          variable.value = newVariable.value;
        }
        return variable;
      });
      this.setState({ variables });
    });
  }

  setSpeed(value) {
    this.setState({ speed: value });
    if (this.props.vuppetmaster) {
      this.props.vuppetmaster.trainer.setSpeed(value);
    }
  }

  onGoto(uuid) {
    const seq = this.props.vuppetmaster.trainer.getSequenceByUUID(uuid);
    if (seq) seq.play();
  }

  render() {
    const { debug } = this.props;
    const {
      speed,
      showSequences,
      showVariables,
      showStatistics,
      currentSequence,
      statisticsTTS,
      variables,
      sequences,
      project
    } = this.state;

    if (!debug || !project) return null;

    const speedButtons = [
      {
        icon: 'play',
        speed: 1,
      },
      {
        icon: 'fast forward',
        speed: 2,
      },
      {
        icon: 'fast forward',
        speed: 4,
      },
      {
        icon: 'fast forward',
        speed: 8,
      },
    ].map((entry) => {
      return (
        <Button
          key={entry.icon + entry.speed}
          size={'mini'}
          icon
          onClick={() => this.setSpeed(entry.speed)}
          active={speed === entry.speed ? true : false}
        >
          <Icon name={entry.icon} />
          {entry.speed !== 1 ? entry.speed : ''}
        </Button>
      );
    });

    return (
      <div className={styles.main}>
        <Segment>
          <Button.Group size={'mini'}>{speedButtons}</Button.Group>{' '}
          <Button
            icon
            size={'mini'}
            active={showVariables}
            onClick={() => {
              this.setState({ showVariables: !this.state.showVariables });
            }}
          >
            <Icon name="database" />
          </Button>
          <Button
            icon
            size={'mini'}
            active={showSequences}
            onClick={() => {
              this.setState({ showSequences: !this.state.showSequences });
            }}
          >
            <Icon name="unordered list" />
          </Button>
          {this._showStatButton && (
            <Button
              icon
              size={'mini'}
              active={showStatistics}
              onClick={() => {
                this.setState({ showStatistics: !this.state.showStatistics });
              }}
            >
              <Icon name="chart bar" />
            </Button>
          )}
          {currentSequence && (
            <div className={styles.current}>
              <Label>Current: {currentSequence.name}</Label>
            </div>
          )}
          {showStatistics && (
            <Message className={styles.statistics}>
              <Statistics data={statisticsTTS} />
            </Message>
          )}
          {showVariables && (
            <Message className={styles.variables}>
              <Variables data={variables} />
            </Message>
          )}
          {showSequences && (
            <Message className={styles.sequences}>
              <Sequences
                onClick={(uuid) => {
                  this.onGoto(uuid);
                }}
                data={sequences}
                current={currentSequence && currentSequence.uuid}
              />
            </Message>
          )}
        </Segment>
      </div>
    );
  }
}

DebugView = connect(
  (state) => ({
    vuppetmaster: state.vuppetmaster.vuppetmaster,
    module: state.module.module,
    debug: state.settings.debug,
  }),
  {}
)(DebugView);

export default DebugView;
