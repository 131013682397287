import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react';

import { withRouter } from 'react-router-dom';

import styles from './TrainerView2.module.scss';
import './TrainerView2.css';

import RestAPI from '../../../RestAPI';
import { connect } from 'react-redux';
import store from '../../../redux/store';
import { actionSetSession } from '../../../redux/actions/actionSession';
import TrainerStart from './TrainerStart';
import RatingView from './../../3_organisms/RatingView/RatingView';
import FeedbackModal from './../../3_organisms/FeedbackModal/FeedbackModal';
import ControlModal from './../../3_organisms/ControlModal/ControlModal';
import { slide as Menu } from 'react-burger-menu';
import { scopeIncludesOneOf } from '../../../helpers/scopecheck';
import ControlPanel from '../../3_organisms/ControlPanel/ControlPanel';
import Copyright from './../../1_atom/Copyright/Copyright';
import { actionSetModule } from '../../../redux/actions/actionModule';
import { actionSetPause } from '../../../redux/actions/actionViewControl';
import { translate } from '../../../helpers/translate';

const MobileDetect = require('mobile-detect');
const md = new MobileDetect(window.navigator.userAgent);

const clone = require('clone');

const ifvisible = require('ifvisible.js');

class TrainerView2 extends Component {
  constructor(props) {
    super(props);

    this._vm = this.props.vuppetmaster;

    this.state = {
      loadingProgress: 0,
      vuppetmasterMode: 'loading',
      moduleActive: false,
      projectProgress: 0,
      bookmarks: [],
      debugBookmarks: [],
      menuOpen: false,
      currentSeqUUID: null,
      showPause: false,
      showControlPanel: false,
      currentBookmark: null,
      finished: false,
      mobile: md.mobile(),
      sequenceFlow: [],
      feedback: false,
    };

    this.logoutTimer = null;
  }

  componentWillUnmount() {
  }

  /**** componentDidMount *******************************************************************************************
   *
   *
   */
  componentDidMount() {
    ifvisible.on('blur', () => {
      if (this.state.moduleActive) {
        this.onPause(true);
      }
    });

    ifvisible.on('focus', () => {});

    const module = this.props.module;

    // start button
    this.setState({
      showPause: module.showPause,
      showControlPanel: module.showProgress || module.showPause,
    });

    // member 'demo_charamel' wants a 30fps version
    let options = {};
    if (
      this.props.member &&
      this.props.member.name &&
      this.props.member.name === 'demo_charamel'
    ) {
      options.maxFPS = 25;
    }

    this._vm = this.props.vuppetmaster;
    if (this._vm) {
      this._vm.trainer.onVariableChange((variable) => {
        if (variable && variable.name) {
          switch (variable.name) {
            case 'projectFinished':
              if (this.state.finished) return;
              const status = {
                finished: variable.value,
                end: true,
              };
              this.updateSessionSequence(
                {
                  uuid: this.state.currentSeqUUID,
                  variable: {
                    name: 'finished',
                    value: variable.value,
                  },
                },
                status
              );
              if (variable.value === true) {
                this.setState({ finished: true, vuppetmasterMode: 'rating' });
              }
              break;
            case 'currentPoints':
              this.updateSessionScore(variable.value);
              break;
            default:
              break;
          }
        }
      });
      this._vm.trainer.on('project.firstsequence', (data) => {
        setTimeout(() => {
          // hide overlay loading view
          this.setState({ vuppetmasterMode: 'running' });
          this.setState({ moduleActive: true });
        }, 1);
      });
      this._vm.trainer.on('sequence.changed', (data) => {
        if (data && data.uuid) {
          const sequence = this._vm.trainer.getSequenceByUUID(data.uuid);
          if (sequence) {
            // check if pause is allowd
            /*            this.setState({
              showPause:
                module.showPause && !sequence.name.includes('question'),
            });*/

            // Calc progress
            const currentUUID = data.next ? data.next : data.uuid;
            this.setState({ currentSeqUUID: currentUUID });
            this.updateSessionSequence({ uuid: currentUUID });

            const progressData = this._vm.trainer.getProgress(currentUUID);

            if (progressData.bookmark && progressData.bookmark.current) {
              const translation = this.state.project.translations.find(
                (t) => t._id === progressData.bookmark.current
              );
              let currentBookmark = null;
              if (translation)
                currentBookmark = translation[this.props.language];

              this.setState({
                currentBookmark: {
                  title: currentBookmark,
                  index: progressData.bookmark.index,
                  max: progressData.bookmark.max,
                },
              });
            }
            this.setState({ projectProgress: progressData.progress });
          }
        }
      });
      this._vm.trainer.on('project.progress', (data) => {
        this.setState({ loadingProgress: Math.floor(data.value * 100) });
      });
      this._vm.trainer.on('project.loaded', (res) => {
        this.setState({ vuppetmasterMode: 'loaded' });

        const project = this._vm.getSceneManager().getProject();

        this.setState({ project });

        const newModule = clone(this.props.module);
        newModule.project = project;
        store.dispatch(actionSetModule(newModule));

        const getNumberOfVariable = (name) => {
          let variable = project.variables.find(
            (variable) => variable.name === name
          );
          if (variable && variable.value) return parseInt(variable.value);
          else return 0;
        };

        // get min max
        let min = getNumberOfVariable('minPoints');
        let max = getNumberOfVariable('maxPoints');
        this.updateSessionMinMax(min, max);

        // set currentScore
        this._vm.trainer.setVariableNumber(
          'currentPoints',
          this.props.session.scoreCurrent
        );

        // get bookmarks
        if (project) {
          // get sequence flow
          const sequenceFlow = this._vm.trainer.getSequenceFlow();

          this.setState({ sequenceFlow });

          // get bookmarsk from variables
          let bookmarks = project.variables
            .filter((v) => v.name.startsWith('bookmark'))
            .map((v) => {
              const translation = project.translations.find(
                (t) => t.name === v.value
              );
              return {
                name: translation ? translation[this.props.language] : v.value,
                sequence: v.value,
              };
            });

          // get bookmarks from sequences
          sequenceFlow.forEach((sequence) => {
            if (sequence.bookmarkTitleUUID) {
              const translation = project.translations.find(
                (t) => t._id === sequence.bookmarkTitleUUID
              );
              if (translation)
                bookmarks.push({
                  name: translation[this.props.language],
                  sequence: sequence.uuid,
                });
            }
          });

          this.setState({ bookmarks: bookmarks });
        }

        // get debugBookmarks
        const data = this._vm.trainer.getProjectData();
        let debugBookmarks = [];
        data.scenes.forEach((scene) => {
          scene.sequences.forEach((seq) => {
            debugBookmarks.push({
              name: seq.name,
              sequence: seq.name,
            });
          });
        });
        this.setState({ debugBookmarks });
      });
      this._vm.trainer.loadProject({
        app: 'trainer',
        key: this.props.module.storybuilder_key,
        language: this.props.language,
        start: false,
      });
    }
  }

  onExit() {
    if (this._vm) {
      const sequence = this._vm.trainer.getCurrentSequence();
      sequence.stop();
    }
    if (this.props.user) {
      // user
      this.props.history.push('/dashboard');
    } else {
      // member
      this.props.history.push('/select');
    }
    window.location.reload();
  }

  onPause(flag) {
    store.dispatch(actionSetPause(flag));
    if (flag) this._vm.trainer.pause();
    else this._vm.trainer.resume();
  }

  startTutorial() {
    this.setState({ vuppetmasterMode: 'starting' });

    if (this.props.language)
      this._vm.trainer.setCurrentLanguage(this.props.language);

    // check if project starts at the beginning
    this._vm.trainer.playProject(this.props.start_sequenceuuid);
  }

  updateSessionMinMax(min, max) {
    let session = clone(this.props.session);
    session.scoreMin = min;
    session.scoreMax = max;
    store.dispatch(actionSetSession(session));
    RestAPI.updateSession(session);
  }

  updateSessionSequence(sequence, status) {
    let session = clone(this.props.session);

    if (session.sequences.find((seq) => seq.uuid === sequence.uuid)) {
      // update
      session.sequences = session.sequences.map((seq) => {
        if (seq.uuid === sequence.uuid) {
          seq = { ...seq, ...sequence };
        }
        return seq;
      });
    } else {
      // add
      session.sequences.push(sequence);
    }
    session.modified = new Date().toISOString();
    if (status) {
      session.status = { ...session.status, ...status };
    }
    store.dispatch(actionSetSession(session));
    RestAPI.updateSession(session);
  }

  updateSessionScore(score) {
    let session = clone(this.props.session);
    session.modified = new Date().toISOString();
    session.scoreCurrent = score;
    store.dispatch(actionSetSession(session));
    RestAPI.updateSession(session);
  }

  onSessionRating(rating) {
    this.updateSessionSequence({
      uuid: this.state.currentSeqUUID,
      rating: rating,
    });
  }

  /**** render *********************************************************************************
   *
   *
   *
   *
   */
  render() {
    const {
      vuppetmasterMode,
      bookmarks,
      currentBookmark,
      debugBookmarks,
      projectProgress,
      showControlPanel,
      showPause,
      mobile,
      sequenceFlow,
      feedback,
    } = this.state;
    const { language, member, user, module, login } = this.props;

    const burger =
      vuppetmasterMode === 'running' &&
      member &&
      bookmarks &&
      bookmarks.length > 2 &&
      scopeIncludesOneOf(member.scope, ['demo']);

    const debug =
      vuppetmasterMode === 'running' &&
      ((member && scopeIncludesOneOf(member.scope, ['debug'])) || user);

    const showLoadingPanel =
      vuppetmasterMode === 'loading' ||
      vuppetmasterMode === 'loaded' ||
      vuppetmasterMode === 'starting';

    let shownBookmarks = [];
    if (burger) shownBookmarks = bookmarks;
    if (debug) shownBookmarks = debugBookmarks;

    const burgerItems = shownBookmarks.map((bookmark) => {
      return (
        <a
          key={bookmark.sequence}
          onClick={() => {
            let sequence = this._vm.trainer.getSequenceByName(
              bookmark.sequence
            );
            if (!sequence) {
              sequence = this._vm.trainer.getSequenceByUUID(bookmark.sequence);
            }
            this.setState({ menuOpen: false });
            if (sequence) sequence.play();
          }}
        >
          {bookmark.name}
        </a>
      );
    });

    const showFeedback =
      user || scopeIncludesOneOf(login && login.scope, ['feedback']);
    const showControl =
      user || scopeIncludesOneOf(login && login.scope, ['control']);

    return (
      <div className={styles.main}>
        <div className={styles.renderview}>
          {(debug || burger) && (
            <Menu
              isOpen={false}
              onStateChange={(state) => {
                this.setState({ menuOpen: state.isOpen });
              }}
            >
              {burgerItems}
            </Menu>
          )}
          {vuppetmasterMode === 'rating' && (
            <div className={styles.rating}>
              <Grid
                textAlign="center"
                style={{ height: '100vh' }}
                verticalAlign="middle"
              >
                <Grid.Column style={{ maxWidth: 550 }}>
                  <RatingView
                    language={language}
                    onClick={(rating) => {
                      this.onSessionRating(rating);
                      this.onExit();
                    }}
                  />
                </Grid.Column>
              </Grid>
            </div>
          )}
          {showLoadingPanel && (
            <TrainerStart
              confirmUserID={module.confirmUserID}
              progress={this.state.loadingProgress}
              showProgress={vuppetmasterMode === 'loading'}
              showWait={vuppetmasterMode === 'starting'}
              onStart={() => {
                this.startTutorial();
              }}
            />
          )}
          <div className={styles.webglcontainer} id="webglcontainer"></div>
          {mobile && !showLoadingPanel && (
            <div className={styles.hint}>
              <h5>{translate('rotate', language)}</h5>
              <img
                className={styles.rotateimage}
                src={
                  process.env.PUBLIC_URL + '/assets/turn_mobilephone_black.svg'
                }
                alt="vm"
              />
            </div>
          )}

          <div className={styles.footer}>
            <Copyright showLink={false} language={language} />
          </div>
        </div>
        {showControlPanel && !showLoadingPanel && (
          <ControlPanel
            showExit={user ? true : false}
            onExit={() => {
              this.onExit();
            }}
            showProgress={module.showProgress}
            showPause={showPause}
            projectProgress={projectProgress}
            bookmarks={bookmarks}
            currentBookmark={currentBookmark}
            language={language}
            onPause={(flag) => {
              if (!flag) this.setState({ feedback: false });
              this.onPause(flag);
            }}
          />
        )}
        {vuppetmasterMode === 'running' && showFeedback && (
          <FeedbackModal
            active={feedback}
            onActive={(active) => {
              this.setState({ feedback: active });
              this.onPause(active);
            }}
          />
        )}
        {vuppetmasterMode === 'running' && showControl && (
          <ControlModal sequences={sequenceFlow} />
        )}
      </div>
    );
  }
}

TrainerView2 = connect(
  (state) => ({
    login: state.login.login,
    module: state.module.module,
    language: state.module.language,
    session: state.session.session,
    member: state.member.member,
    user: state.user.user,
    viewControl: state.viewControl.pause,
    vuppetmaster: state.vuppetmaster.vuppetmaster,
    start_sequenceuuid: state.vuppetmaster.start_sequenceuuid,
    debug: state.settings.debug,
  }),
  {}
)(TrainerView2);

export default withRouter(TrainerView2);
