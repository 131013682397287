
//
// Redux init
// 
import { createStore, applyMiddleware, combineReducers,compose } from 'redux'
import thunk from 'redux-thunk';
import Effects from './effects';

import reducerMain from './reducer/reducerMain'
import reducerVuppetmaster from './reducer/reducerVuppetmaster'
import reducerUser from "./reducer/reducerUser";
import reducerMember from "./reducer/reducerMember";
import reducerFeedback from "./reducer/reducerFeedback";
import reducerModule from "./reducer/reducerModule";
import reducerTutorial from "./reducer/reducerTutorial";
import reducerCompany from "./reducer/reducerCompany";
import reducerSession from "./reducer/reducerSession";
import reducerViewControl from "./reducer/reducerViewControl";
import reducerPage from "./reducer/reducerPage";
import reducerLogin from "./reducer/reducerLogin";
import reducerMedia from './reducer/reducerMedia';
import reducerSettings from './reducer/reducerSettings';

import effectVuppetmaster from './effects/effectVuppetmaster';


const rootReducer = combineReducers({
	main: reducerMain,
	vuppetmaster: reducerVuppetmaster,
  login: reducerLogin,
  user: reducerUser,
	module: reducerModule,
	member: reducerMember,
	tutorial: reducerTutorial,
	company: reducerCompany,
	feedback: reducerFeedback,
	session: reducerSession,
	viewControl: reducerViewControl,
	page: reducerPage,
	media: reducerMedia,
	settings: reducerSettings
});

const effects = new Effects([
  effectVuppetmaster,
]);

const initialState = {};
const middleware = [];

middleware.push(effects, thunk);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middleware))
);

export default store;

