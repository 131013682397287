import React, { Component } from 'react';
import { Dropdown, Segment } from 'semantic-ui-react';

import EditLine from '../../1_atom/EditLine/EditLine.js';
import ViewJson from '../../3_organisms/ViewJson.js';
import SelectTheme from './SelectTheme';
import CssResult from './CssResult';

import { connect } from 'react-redux';
import store from './../../../redux/store';
import { actionSetNode } from '../../../redux/actions/actionPage';

class NodeButton extends Component {
  render() {
    let opt = [];
    this.props.tutorial.pages_array.forEach((page) => {
      opt.push({
        key: page.uuid,
        text: page.name,
        value: page.uuid,
      });
    });

    if (this.props.tutorial.variables)
      this.props.tutorial.variables.forEach((v) => {
        if (v.type === 'page') {
          opt.push({
            key: v.uuid,
            text: 'Var: ' + v.name,
            value: v.uuid,
          });
        }
      });

    return (
      <Segment>
        <h1>Button</h1>
        <EditLine
          label="Name:"
          text={this.props.node.name}
          onChanged={(text) =>
            store.dispatch(actionSetNode({ ...this.props.node, name: text }))
          }
        />
        <EditLine
          label="Start:"
          text={this.props.node.start}
          onChanged={(text) =>
            store.dispatch(actionSetNode({ ...this.props.node, start: text }))
          }
        />
        <EditLine
          label="End:"
          text={this.props.node.end}
          onChanged={(text) =>
            store.dispatch(actionSetNode({ ...this.props.node, end: text }))
          }
        />
        <EditLine
          label="Text:"
          text={this.props.node.data ? this.props.node.data.text : ''}
          onChanged={(text) => {
            if (!this.props.node.data)
              this.props.node.data = { filename: text };
            this.props.node.data = { ...this.props.node.data, text: text };
            store.dispatch(actionSetNode(this.props.node));
          }}
        />
        <SelectTheme />
        <CssResult theme={this.props.node.theme} />
        <br />
        <Dropdown
          placeholder="Select"
          value={
            this.props.node.data && this.props.node.data.page
              ? this.props.node.data.page
              : ''
          }
          selection
          options={opt}
          onChange={(e, d) => {
            console.log('#### onDropdownChanged', d.value);

            let node = JSON.parse(JSON.stringify(this.props.node));
            if (!node.data) node.data = { page: '' };
            node.data = { ...node.data, page: d.value };

            let page = this.props.tutorial.pages_array.find(
              (p) => p.uuid === d.value
            );
            if (page) {
              node.data = { ...node.data, pagename: page.name };
            } else {
              let v = this.props.tutorial.variables.find(
                (v) => v.uuid === d.value
              );
              if (v) node.name = v.name;
            }
            store.dispatch(actionSetNode(node));
          }}
        />
        <ViewJson
          update={(css) => {
            if (this.props.onCssChanged(css))
              store.dispatch(
                actionSetNode({ ...this.props.node, css: JSON.parse(css) })
              );
          }}
          source={JSON.stringify(this.props.node.css, null, 4)}
          title={''}
        />
      </Segment>
    );
  }
}

NodeButton = connect(
  (state) => ({
    tutorial: state.tutorial.tutorial,
    node: state.page.node,
  }),
  {}
)(NodeButton);

export default NodeButton;
