import React, { Component } from 'react';
import { Dropdown } from 'semantic-ui-react'

import store from '../../../redux/store';
import { actionSetNode } from '../../../redux/actions/actionPage'
import { connect } from 'react-redux';

class SelectTheme extends Component {

	constructor(props) {
		super(props);

		this.state = {
			optClass: []
		}

		if (this.props.node.theme) {
			this.updateTheme(this.props.node.theme.name);
			this.updateClass(this.props.node.theme.class);
		}
	}

	updateTheme(name) {
		let node = JSON.parse(JSON.stringify(this.props.node));
		if (!node.theme || (typeof node.theme === 'string' || node.theme instanceof String)) {
			node.theme = {
				name: 'none',
				class: 'none'
			}
		}
		node.theme.name = name;
		store.dispatch(actionSetNode(node));
	}

	updateClass(name) {
		let node = JSON.parse(JSON.stringify(this.props.node));
		if (!node.theme || (typeof node.theme === 'string' || node.theme instanceof String)) {
			node.theme = {
				name: 'none',
				class: 'none'
			}
		}
		node.theme.class = name;
		store.dispatch(actionSetNode(node));
	}

	render() {

		let optTheme = [];
		optTheme.push({
			key: 'none',
			text: 'none',
			value: 'none'
		});
		this.props.themes && this.props.themes.forEach(theme => {
			optTheme.push({
				key: theme.theme,
				text: theme.theme,
				value: theme.theme
			})
		});

		let optClass = [];
		let theme = this.props.themes && this.props.themes.find(t => t.theme === this.props.node.theme.name);
		if (theme && theme.classes) {
			optClass.push({
				key: 'none',
				text: 'none',
				value: 'none'
			});
			Object.keys(theme.classes).forEach((k) => {
				optClass.push({
					key: k,
					text: k,
					value: k
				});
			});
		}

		return (
			<div style={{ display: 'inline-block' }}>
				Theme:
				<Dropdown
					placeholder='Select Theme'
					value={(this.props.node && this.props.node.theme) ? this.props.node.theme.name : 'none'}
					selection options={optTheme}
					onChange={(e, d) => {
						this.updateTheme(d.value);
					}}
				/>
				<Dropdown
					placeholder='Select Class'
					value={(this.props.node && this.props.node.theme) ? this.props.node.theme.class : 'none'}
					selection options={optClass}
					onChange={(e, d) => {
						this.updateClass(d.value);
					}}
				/>
			</div>
		)
	}
}

SelectTheme = connect(
	state => ({
		tutorial: state.tutorial.tutorial,
		themes: state.main.themes,
		node: state.page.node,
	}),
	{},
)(SelectTheme);


export default SelectTheme;

