import React from "react";
import "./RenderView.css";
import { Loader } from "semantic-ui-react";
import ReactResizeDetector from "react-resize-detector";

class RenderView extends React.Component {
  render() {
    var output;
    if (this.props.showwait)
      output = (
        <div id="progress">
          <Loader active size="massive">
            Loading
          </Loader>
        </div>
      );
    else output = "";

    return (
      <div id="resizediv">
        <ReactResizeDetector handleWidth handleHeight>
          {(data) => {
            let width = data.width;
            let height = data.height;
            if (!width) width = 10;
            if (!height) height = 10;
//            console.log('# ', width, height);

	          return (
              <div id="webglcontainerdiv" >
                <canvas id="webglcontainer" width={width.toFixed(0)} height={height.toFixed(0)}/>
                {output}
              </div>
            );
          }}
        </ReactResizeDetector>
      </div>
    );
  }
}

RenderView.defaultProps = {
  active: "false"
};

export default RenderView;
