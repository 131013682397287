import React, { Component } from 'react';
import { Popup, Modal, Checkbox, Header, Icon, Table, Segment, Button } from 'semantic-ui-react'

import NodeProperties from './NodeProperties'

import store from '../../../redux/store';
import { actionSetPage, actionSetNode } from '../../../redux/actions/actionPage'
import { actionSetTutorial } from '../../../redux/actions/actionTutorial'
import { connect } from 'react-redux';
import { scopeIncludesOneOf } from '../../../helpers/scopecheck';

import { getTestViewUrl } from '../../../helpers/getPreViewUrl';

const UUID = require('uuid/v4');
var clone = require('clone');

class Page extends Component {

	constructor(props) {
		super(props);

		this._iconArray = {};
		this._iconArray['text'] = "font";
		this._iconArray['image'] = "image";
		this._iconArray['sound'] = "volume up";
		this._iconArray['script'] = "clock";
		this._iconArray['timer'] = "clock";
		this._iconArray['button'] = "circle outline";
		this._iconArray['page'] = "clock";
		this._iconArray['tts'] = "comment outline";
		this._iconArray['rating'] = "star outline";
		this._iconArray['var_set_page'] = "";
		this._iconArray['var_set_text'] = "";
		this._iconArray['var_set_count'] = "";
		this._iconArray['var_compare'] = "";

		this._textArray = {};
		this._textArray['text'] = "";
		this._textArray['image'] = "";
		this._textArray['sound'] = "";
		this._textArray['script'] = "Script";
		this._textArray['timer'] = "clock";
		this._textArray['button'] = "Button";
		this._textArray['page'] = "Page";
		this._textArray['tts'] = "TTS";
		this._textArray['rating'] = "";
		this._textArray['var_set_page'] = "Set Page Var";
		this._textArray['var_set_text'] = "Set Text Var";
		this._textArray['var_set_count'] = "Set Count Var";
		this._textArray['var_compare'] = "Compare Variable";

		this.state = {
			applyActive: true,
			openNode: false,
			openDelete: false,
			openDeleteNode: null,
			selectedIndex: -1,
			selectedVarIndex: -1,
			selectedVar: null,

		}

	}

	updatePage(page) {
		let pa = JSON.parse(JSON.stringify(page));
		let tutorial = JSON.parse(JSON.stringify(this.props.tutorial));
		tutorial.pages_array = tutorial.pages_array.map(p => {
			if (p.uuid === pa.uuid)
				p = pa;
			return p;
		});
		store.dispatch(actionSetPage(pa));
		store.dispatch(actionSetTutorial(tutorial));

	}

	onAddNewNode(type, text) {
		let page = this.props.page;
		let node = {
			type: type,
			uuid: UUID(),
			active: true,
			name: text,
			start: null,
			end: null,
			css: {},
			theme: 'default',
			data: null
		}
		if (type === 'var_set_page' || type === 'var_set_text' || type === 'var_set_count' || type === 'var_compare')
			node.data = { value: '' };

		if (type === 'sound')
			node.data = { volume: '100', url: '' };
			
		page.nodes.push(node);

		store.dispatch(actionSetPage(page));

		let tutorial = JSON.parse(JSON.stringify(this.props.tutorial));
		tutorial.pages_array = tutorial.pages_array.map(p => {
			if (p.uuid === page.uuid)
				p.nodes = page.nodes;
			return p;
		});
		store.dispatch(actionSetTutorial(tutorial));
	}

	onNodeApply(node) {
		let tutorial = JSON.parse(JSON.stringify(this.props.tutorial));
		let page = this.props.page;

		page.nodes = page.nodes.map(n => {
			if (n.uuid === node.uuid)
				return node;
			else
				return n;
		});

		// replace nodes page in page_array
		tutorial.pages_array = tutorial.pages_array.map(p => {
			if (p.uuid === page.uuid)
				p.nodes = page.nodes;
			return p;
		});
		store.dispatch(actionSetTutorial(tutorial));
	}

	onPageApply(page) {
		let tutorial = JSON.parse(JSON.stringify(this.props.tutorial));

		// replace nodes page in page_array
		tutorial.pages_array = tutorial.pages_array.map(p => {
			if (p.uuid === page.uuid) {
				p.status = page.status;
			}
			return p;
		});
		store.dispatch(actionSetTutorial(tutorial));
	}


	onDelete(node) {
		let page = this.props.page;

		for (var i = page.nodes.length - 1; i >= 0; i--) {
			if (page.nodes[i].uuid === node.uuid) {
				page.nodes.splice(i, 1);
			}
		}

		store.dispatch(actionSetPage(page));

		let tutorial = JSON.parse(JSON.stringify(this.props.tutorial));
		tutorial.pages_array = tutorial.pages_array.map(p => {
			if (p.uuid === page.uuid)
				p.nodes = page.nodes;
			return p;
		});
		store.dispatch(actionSetTutorial(tutorial));
	}

	onDuplicate(node, index) {
		let page = this.props.page;

		let newNode = JSON.parse(JSON.stringify(node));
		newNode.uuid = UUID();

		if (index === (page.nodes.length - 1))
			page.nodes.push(newNode);
		else
			page.nodes.splice(index, 0, newNode);

		let tutorial = JSON.parse(JSON.stringify(this.props.tutorial));
		tutorial.pages_array = tutorial.pages_array.map(p => {
			if (p.uuid === page.uuid)
				p.nodes = page.nodes;
			return p;
		});
		store.dispatch(actionSetTutorial(tutorial));
	}


	onSelect(node, index) {
		this.setState({
			selectedIndex: index
		});
		store.dispatch(actionSetNode(JSON.parse(JSON.stringify(node))));
	}



	showData(node) {

		function isNumber(n) { return !isNaN(parseFloat(n)) && !isNaN(n - 0) }

		let name = '';
		switch (node.type) {
			case 'text': return ((node.name && node.name !== '') && node.name) + ' ' + ((node.data && node.data.text) && node.data.text);
			case 'tts': return node.data ? node.data.text : '';
			case 'rating': return ((node.name && node.name !== '') && node.name) + ' ' + (node.data && node.data.maxRating && node.data.maxRating);
			case 'image': {
				if (node.data) {
					if (node.data.resource && this.props.media) {
						let media = this.props.media.find(r => r.uuid === node.data.resource);
						if (media)
							return media.name
						else
							return 'not set';
					}
					else
						return node.data.filename;
				}
				else {
					return 'not set';
				}
			}
			case 'page':
				if (node.data) {
					let page = this.props.tutorial.pages_array.find(p => p.uuid === node.data.page);
					if (page) {
						return page.name;
					}
					else if (node.data.page) {
						let v = this.props.variables.find(v => v.uuid === node.data.page);
						if (v) {
							name += v.name;
						}
						return name;
					}
				}
				return '-';
			case 'button': return node.name + ' Text:' + ((node.data && node.data.text) ? node.data.text : '') + ' Page:' + ((node.data && node.data.pagename) ? node.data.pagename : '');
			case 'script': return node.data ? node.data.script : 'not set';
			case 'sound': return node.name + ' ' + (node.data.volume ? node.data.volume : '');
			case 'var_set_text':
				name = '';
				if (node.data.var) {
					let v = this.props.variables.find(v => v.uuid === node.data.var);
					if (v) {
						name += v.name;
					}
					name += ' = ' + node.data.value;
				}
				return name;
			case 'var_set_page':
				name = '';
				if (node.data.var) {
					let v = this.props.variables.find(v => v.uuid === node.data.var);
					if (v) {
						name += v.name;
					}

					let p = this.props.tutorial && this.props.tutorial.pages_array.find(p => p.uuid === node.data.value);
					if (p) {
						name += ' = ' + p.name;
					}
				}
				return name;
			case 'var_set_count':
				name = '';
				if (node.data.var) {
					let v = this.props.variables.find(v => v.uuid === node.data.var);
					if (v)
						name += v.name;
					if (node.data.operator)
						name += ' ' + node.data.operator;
					if (isNumber(node.data.value))
						name += ' ' + node.data.value;
				}
				return name;
			case 'var_compare':
				name = ' IF (';

				let p1 = this.props.variables.find(v => v.uuid === node.data.param1);
				let p2 = this.props.variables.find(v => v.uuid === node.data.param2);
				if (p1)
					name += p1.name;
				if (node.data.operator)
					name += node.data.operator;
				if (p2)
					name += p2.name;
				name += ' ) GOTO ';
				let page = this.props.tutorial.pages_array.find(p => p.uuid === node.data.value);
				if (page)
					name += page.name;
				page = this.props.tutorial.pages_array.find(p => p.uuid === node.data.elsevalue);
				if (page)
					name += ' ELSE ' + page.name;
				return name;
			default: return '';
		}
	};

	render() {

		const {
			login,
		} = this.props;

		const isRoot = scopeIncludesOneOf(login && login.scope, ['root']);

		const debug = process.env.NODE_ENV === 'development';

		var outputNodes = this.props.page.nodes && this.props.page.nodes.map((node, index) => {

			return (
				<Table.Row key={index} onClick={() => { this.onSelect(node, index); }} active={index === this.state.selectedIndex}  >
					<Table.Cell>
						<Header textAlign='left'>
							<Icon name={this._iconArray[node.type]} />
							{this._textArray[node.type]}
						</Header>
					</Table.Cell>
					<Table.Cell>
						<Checkbox
							checked={node.active}
							onChange={(event, data) => {

								let n = JSON.parse(JSON.stringify(node));
								n.active = data.checked
								this.onNodeApply(n);
							}}
						/>
					</Table.Cell>
					<Table.Cell>
						<Header textAlign='left'>{this.showData(node)}</Header>
					</Table.Cell>
					{debug && (
						<Table.Cell>
							<Header textAlign='left'>{node.uuid}</Header>
						</Table.Cell>
					)}
					<Table.Cell>
						<Header textAlign='left'>{node.start ? node.start : ''}</Header>
					</Table.Cell>
					<Table.Cell>
						<Header textAlign='left'>{node.end ? node.end : ''}</Header>
					</Table.Cell>
					<Table.Cell singleLine>

						<Popup trigger={
							<Button color='green' icon onClick={() => { this.onSelect(node, index); this.setState({ openNode: true, applyActive: true }) }} >
								<Icon name='edit' />
							</Button>}
							content={'Edit'} />
						<Popup trigger={
							<Button color='red' icon onClick={() => { this.onSelect(node, index); this.setState({ openDelete: true, openDeleteNode: node }) }} >
								<Icon name='delete' />
							</Button>}
							content={'Delete'} />
						<Popup trigger={
							<Button icon onClick={() => { this.onDuplicate(node, index); }} >
								<Icon name='copy' />
							</Button>}
							content={'Duplicate'} />
					</Table.Cell>
				</Table.Row>
			)
		});

		let optVars = [];
		if (this.props.variables)
			this.props.variables.forEach(element => {
				optVars.push({
					key: element.uuid,
					text: element.name,
					value: element.uuid
				});
			});


		return (
			<Segment>
				<div>
					Name: {this.props.page.name}
					{isRoot && (<div>UUID: {this.props.page.uuid}</div>)}
					<br />
					<Button size='mini' floated='left' onClick={() => { this.onAddNewNode('text', 'name'); }} icon labelPosition='left' primary >
						<Icon name='add' />TextField
					</Button>
					<Button size='mini' floated='left' onClick={() => { this.onAddNewNode('image', 'name'); }} icon labelPosition='left' primary >
						<Icon name='add' />Image
					</Button>
					<Button size='mini' floated='left' onClick={() => { this.onAddNewNode('button', 'name'); }} icon labelPosition='left' primary >
						<Icon name='add' />Button
					</Button>
					<Button size='mini' floated='left' onClick={() => { this.onAddNewNode('script', 'name'); }} icon labelPosition='left' primary >
						<Icon name='add' />Script
					</Button>
					<Button size='mini' floated='left' onClick={() => { this.onAddNewNode('page', 'name'); }} icon labelPosition='left' primary >
						<Icon name='add' />Timer Page
					</Button>
					<Button size='mini' floated='left' onClick={() => { this.onAddNewNode('tts', 'name'); }} icon labelPosition='left' primary >
						<Icon name='add' />TTS
					</Button>
					<Button size='mini' floated='left' onClick={() => { this.onAddNewNode('rating', 'name'); }} icon labelPosition='left' primary >
						<Icon name='add' />Survey
					</Button>
					<Button size='mini' floated='left' onClick={() => { this.onAddNewNode('var_set_text', 'name'); }} icon labelPosition='left' primary >
						<Icon name='add' />Set Text Var
					</Button>
					<Button size='mini' floated='left' onClick={() => { this.onAddNewNode('var_set_page', 'name'); }} icon labelPosition='left' primary >
						<Icon name='add' />Set Page Var
					</Button>
					<Button size='mini' floated='left' onClick={() => { this.onAddNewNode('var_set_count', 'name'); }} icon labelPosition='left' primary >
						<Icon name='add' />Set Count Var
					</Button>
					<Button size='mini' floated='left' onClick={() => { this.onAddNewNode('var_compare', 'name'); }} icon labelPosition='left' primary >
						<Icon name='add' />Compare Var
					</Button>
					<Button size='mini' floated='left' onClick={() => { this.onAddNewNode('sound', ''); }} icon labelPosition='left' primary >
						<Icon name='add' />Sound
					</Button>
					<Table compact size='small' celled selectable>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell singleLine>Type</Table.HeaderCell>
								<Table.HeaderCell singleLine>Active</Table.HeaderCell>
								<Table.HeaderCell>Data</Table.HeaderCell>
								{debug && (
									<Table.HeaderCell>UUID</Table.HeaderCell>
								)}
								<Table.HeaderCell singleLine>Start</Table.HeaderCell>
								<Table.HeaderCell singleLine>End</Table.HeaderCell>
								<Table.HeaderCell></Table.HeaderCell>
							</Table.Row>
						</Table.Header>

						<Table.Body>
							{outputNodes}
						</Table.Body>
						<Table.Footer fullWidth>
							<Table.Row>
								<Table.HeaderCell colSpan='6'>
								</Table.HeaderCell>
							</Table.Row>
						</Table.Footer>
					</Table>
					<Popup trigger={
						<Button size='mini' onClick={() => {
							var win = window.open(getTestViewUrl(this.props.tutorial.uuid, this.props.page.uuid), '_blank');
							win.focus();
						}}>
							<Icon name='tv' />
						</Button>}
						content={getTestViewUrl(this.props.tutorial.uuid, this.props.page.uuid)}
					/>
					<Checkbox
						label='End'
						toggle
						checked={(this.props.page.status && this.props.page.status.end) ? true : false}
						onChange={(event, data) => {
							let page = clone(this.props.page);
							if (!page.status)
								page.status = {};
							page.status = { ...page.status, end: data.checked }
							store.dispatch(actionSetPage(page));
							this.onPageApply(page);
						}} />
					<Checkbox
						label='Finished'
						toggle
						checked={(this.props.page.status && this.props.page.status.finished) ? true : false}
						onChange={(event, data) => {
							let page = clone(this.props.page);
							if (!page.status)
								page.status = {};
							page.status = { ...page.status, finished: data.checked }
							store.dispatch(actionSetPage(page));
							this.onPageApply(page);
						}} />


				</div>

				<Modal open={this.state.openNode}>
					<Header icon='settings' />
					<Modal.Content>
						<Segment>
							<NodeProperties onCssChanged={(css) => {
								try {
									if (css) {
										let o = JSON.parse(css);
										if (o.constructor === {}.constructor) {
											this.setState({ applyActive: true });
											return true;
										}
									}
								}
								catch (e) {
								}
								this.setState({ applyActive: false });
								return false;
							}} />
						</Segment>
						<Button color={this.state.applyActive ? 'green' : 'red'} onClick={() => {
							if (this.state.applyActive) {
								this.setState({ openNode: false });
								this.onNodeApply(this.props.node);
							}
						}} >apply </Button>
						<Button onClick={() => {
							this.setState({ openNode: false });
						}} >cancel </Button>
					</Modal.Content>
				</Modal>

				<Modal open={this.state.openDelete}>
					<Header icon='delete' content='Delete Node?' />
					<Modal.Content>
						<Segment color='red'>
							Do you really want to delete the node?
					</Segment>
						<Button onClick={() => {
							let node = this.state.openDeleteNode;
							this.setState({ openDelete: false, openDeleteNode: null });
							this.onDelete(node);
						}} >ok </Button>
						<Button onClick={() => {
							this.setState({ openDelete: false });
						}} >cancel </Button>
					</Modal.Content>
				</Modal>


			</Segment>
		);
	}
}

Page = connect(
	state => ({
		login: state.login.login,
		tutorial: state.tutorial.tutorial,
		page: state.page.page,
		node: state.page.node,
		variables: state.main.variables,
		media: state.media,
	}),
	{},
)(Page);

export default Page;
