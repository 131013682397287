import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import { Segment } from 'semantic-ui-react';

import Script from 'react-load-script';

import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import ThemeSwitch from './ThemeSwitch';
import TrainerView from './../../4_views/TrainerView/TrainerView.js';
import TrainerView2 from './../../4_views/TrainerView/TrainerView2.js';
import Member from './../../4_views/Members/Member.js';
import Members from './../../4_views/Members/Members.js';
import Modules from './../../4_views/Modules/Modules.js';
import Module from './../../4_views/Modules/Module.js';
import ModuleResult from './../../4_views/Modules/ModuleResult.js';
import Tutorials from './../../4_views/Tutorials/Tutorials.js';
import Session from './../../4_views/Sessions/Session.js';
import Sessions from './../../4_views/Sessions/Sessions.js';
import Company from './../../4_views/Company/Company.js';
import Companies from './../../4_views/Company/Companies.js';
import Users from './../Users/Users.js';
import User from './../Users/User.js';
import Feedbacks from './../Feedback/Feedbacks.js';
import Feedback from './../Feedback/Feedback.js';
import PageTree from './../../4_views/Pages/PageTree.js';
import PageTest from './../../4_views/Pages/PageTest.js';
import Page from './../../4_views/Pages/Page';
import Select from './../../4_views/Select/Select';
import UserPreview from './../../4_views/UserPreview/UserPreview';
import Login from './../../4_views/Landing/Login/Login';
import Nothingtodo from './../../4_views/Landing/Nothingtodo';
import KeyHandler from './../../3_organisms/KeyHandler/KeyHandler';
import MainFooter from './MainFooter';
import DebugView from './../../3_organisms/DebugView/DebugView';
import { API_ROOT_VM4 } from '../../../api-config';

import styles from './MainPage.module.scss';

import { scopeIncludesOneOf } from '../../../helpers/scopecheck';
import { actionSetVuppetmaster } from '../../../redux/actions/actionVuppetmaster';
import { actionSetPath } from '../../../redux/actions/actionMain';
import { actionSetUser } from '../../../redux/actions/actionUser';
import { actionSetIpData } from '../../../redux/actions/actionLogin';
import store from '../../../redux/store';

import RestAPI from '../../../RestAPI.js';
import { actionSetLogin } from '../../../redux/actions/actionLogin';
import DashboardContainer from './DashboardContainer';
import AdminMenu from './../../3_organisms/AdminMenu/AdminMenu';
import { parseQueryString } from './../../../helpers/URL.helper';

const publicIp = require('public-ip');

/**** MainPage ***************************************************************/
/*!
 **
 ******************************************************************************/
class MainPage extends Component {
  constructor(props) {
    super(props);

    let title = 'Virtual Trainer';

    if (process.env.NODE_ENV !== 'production') {
      title += ' ' + process.env.NODE_ENV;
    }

    if (process.env.MODE === 'offline') {
      console.log('### mode offline ####');
      title += 'Virtual Trainer';
    }

    this.state = {
      initialized: false,
      changingTheme: false,
      loggingMessage: '',
      loggedIn: false,
      mode: 'login', // login, admin, trainer
      title: title,
      vmurl: null,
    };
    this._vm = null;
  }

  componentDidMount() {
    let vmurl = API_ROOT_VM4 + '/api?key=trainer';
    this.setState({ vmurl });

    // check if token is still valid
    RestAPI.authStatus().then((result) => {
      if (!result.ok) {
        console.log('token invalid -> login ', result.error);
        this.props.history.push('/');
      } else {
        this.setState({
          loggedIn: true,
        });

        let path = this.props.location.pathname;
        if (
          path.startsWith('/admin') ||
          path.startsWith('/trainer') ||
          path.startsWith('/select')
        ) {
          this.onLogin(result);
        } else if (path.startsWith('/user')) {
          // route from dashboard
          this.onLogin(result);
        }
      }
    });
  }

  handleScriptLoad() {
    this._vm = new window.VM.Vuppetmaster();
    store.dispatch(actionSetVuppetmaster(this._vm));
  }

  changeTheme = (theme) => {
    console.log('Theme change to "' + theme + '".');
    let sheet = document.querySelector('#theme');
    if (!sheet) {
      return;
    }

    this.setState({ changingTheme: true });
    setTimeout(() => {
      this.setState({ changingTheme: false });
    }, 500);

    let attr = sheet.getAttribute('href');
    attr = attr.replace('semantic.charamel.min.css', '');
    attr = attr.replace('semantic.default.min.css', '');
    switch (theme) {
      case 'charamel':
        attr += 'semantic.charamel.min.css';
        break;
      default:
        attr += 'semantic.default.min.css';
        break;
    }
    sheet.setAttribute('href', attr);
  };

  setIpData() {
    if (process.env.MODE !== 'offline') {
      publicIp.v4().then((ip) => {
        RestAPI.getipdata(ip).then((result) => {
          store.dispatch(actionSetIpData(result.data));
        });
      });
    } else {
      store.dispatch(actionSetIpData({ ip: 'offline' }));
    }
  }

  async onLogin(loginData) {
    this.setIpData();

    let scope = loginData.scope && loginData.scope.length && loginData.scope[0];
    if (scopeIncludesOneOf(scope, ['member'])) {
      //
      // Normal member
      //
      RestAPI.member(loginData.uuid).then((member) => {
        if (member) {
          this.setState({
            loggedIn: true,
            root: false,
            mode: 'select',
            initialized: true,
          });
          this.props.history.push('/select');
        }
      });
      this.setState({ initialized: true });
    } else if (scopeIncludesOneOf(scope, ['root','admin'])) {
      //
      // Root
      //
      this.setState({ loggedIn: true, mode: 'admin' });
      this.changeTheme('charamel');
      store.dispatch(actionSetUser(null));
      RestAPI.tutorials();
      RestAPI.members();
      RestAPI.companies();
      this.props.history.push('/admin/modules');
      store.dispatch(actionSetPath('modules'));
      RestAPI.modules().then(async () => {
        this.setState({ initialized: true });
      });
    } else if (scopeIncludesOneOf(scope, ['user'])) {
      //
      // User
      //
      const query = parseQueryString(window.location.search);
      const module_uuid = query.module;
      const language = query.lang;
      if (module_uuid) {
        RestAPI.user(loginData.uuid).then((user) => {
          this.props.history.push(
            `/user?module=${module_uuid}&lang=${language}`
          );
        });
      } else {
        // goto dashboard
        window.location.href = process.env.REACT_APP_WEBSITE_DASHBOARD;
      }
    }
  }

  onLogout() {
    this.changeTheme('default');
    RestAPI.logout();
    store.dispatch(actionSetUser(null));
    store.dispatch(actionSetLogin(null));
    this.setState({ loggedIn: false, user: '-', mode: 'login' });
    this.props.history.push('/');
  }

  render() {
    const { vmurl, initialized } = this.state;
    const { module, member, user } = this.props;

    let fullScreen = this.props.location.pathname.startsWith('/admin/pages');
    fullScreen |= this.props.location.pathname.startsWith('/testmode');
    fullScreen |= this.props.location.pathname.startsWith('/demo');
    fullScreen |= this.props.location.pathname.startsWith('/trainer');

    return (
      <KeyHandler>
        <div className={fullScreen ? styles.mainFullscreen : styles.main}>
          {this.state.changingTheme && <ThemeSwitch />}
          {vmurl && (
            <Script
              url={vmurl}
              onCreate={() => {}}
              onError={() => {
                console.log('script load error');
              }}
              onLoad={this.handleScriptLoad.bind(this)}
            />
          )}

          <Helmet>
            <title>{this.state.title}</title>
          </Helmet>
          <Switch>
            <Route
              path="/testmode/:uuid"
              render={(props) => {
                return <TrainerView mode="testmode" {...props} />;
              }}
            />
            <Route
              path="/demo/:uuid"
              render={(props) => {
                return <TrainerView mode="demo" {...props} />;
              }}
            />
            <Route
              exact
              path="/"
              render={(props) => {
                return <Login onLogin={(data) => this.onLogin(data)} />;
              }}
            />
            <Route
              exact
              path="/login"
              render={(props) => {
                return <Login onLogin={(data) => this.onLogin(data)} />;
              }}
            />
            <Route
              exact
              path="/nothingtodo"
              render={(props) => {
                return <Nothingtodo />;
              }}
            />
            <Route
              exact
              path="/select"
              render={(props) => {
                if (member) return <Select />;
                else return <div>Loading select...</div>;
              }}
            />
            <Route
              exact
              path="/user"
              render={(props) => {
                if (user) return <UserPreview />;
                else return <div>Loading select user...</div>;
              }}
            />
            <Route
              path="/trainer"
              render={(props) => {
                if (this.props.tutorial) return <TrainerView mode="trainer" />;
                else return <div>Loading...</div>;
              }}
            />
            <Route
              path="/trainer2"
              render={(props) => {
                if (module) return <TrainerView2 mode="trainer" />;
                else return <div>Loading...</div>;
              }}
            />
            <Route
              path="/admin"
              render={(props) => {
                return (
                  <div style={{ height: '100%' }}>
                    <div
                      className={
                        fullScreen ? styles.mainFullscreen : styles.pagemain
                      }
                    >
                      <div
                        className={
                          fullScreen ? styles.mainFullscreen : styles.pagemain
                        }
                      >
                        <DashboardContainer
                          initialized={initialized}
                          fullScreen={fullScreen}
                          {...this.props}
                          page={this.props.page}
                          mode={this.state.mode}
                          onLogout={() => this.onLogout()}
                        >
                          <div className={styles.flexcontainer}>
                            <div style={{ flex: '0 0 150px' }}>
                              <AdminMenu
                                scope={
                                  this.props.login && this.props.login.scope
                                }
                                activePath={this.props.activePath}
                                history={this.props.history}
                              />
                            </div>
                            <div>
                              <Segment
                                style={{
                                  height: '100%',
                                  overflow: 'auto',
                                  padding: '0,0,0,0',
                                }}
                              >
                                {initialized ? (
                                  <div style={{ height: '100%' }}>
                                    <Route
                                      exact
                                      path="/admin/user/:uuid"
                                      render={(props) => {
                                        return <User />;
                                      }}
                                    />
                                    <Route
                                      exact
                                      path="/admin/users"
                                      render={(props) => {
                                        return <Users />;
                                      }}
                                    />
                                    <Route
                                      exact
                                      path="/admin/feedbacks"
                                      render={(props) => {
                                        return <Feedbacks />;
                                      }}
                                    />
                                    <Route
                                      exact
                                      path="/admin/feedback/:uuid"
                                      render={(props) => {
                                        return <Feedback />;
                                      }}
                                    />
                                    <Route
                                      exact
                                      path="/admin/pages/:uuid"
                                      render={(props) => {
                                        return <Page />;
                                      }}
                                    />
                                    <Route
                                      exact
                                      path="/admin/pages"
                                      render={(props) => {
                                        return <PageTree />;
                                      }}
                                    />
                                    <Route
                                      exact
                                      path="/admin/module/:uuid"
                                      render={(props) => {
                                        return <Module />;
                                      }}
                                    />
                                    {module && (
                                      <Route
                                        exact
                                        path="/admin/moduleresult/:uuid"
                                        render={(props) => {
                                          return <ModuleResult />;
                                        }}
                                      />
                                    )}
                                    <Route
                                      exact
                                      path="/admin/modules"
                                      render={(props) => {
                                        return <Modules />;
                                      }}
                                    />
                                    <Route
                                      exact
                                      path="/admin/member/:uuid"
                                      render={(props) => {
                                        return <Member />;
                                      }}
                                    />
                                    <Route
                                      exact
                                      path="/admin/members"
                                      render={(props) => {
                                        return <Members />;
                                      }}
                                    />
                                    <Route
                                      exact
                                      path="/admin/tutorials"
                                      render={(props) => {
                                        return <Tutorials />;
                                      }}
                                    />
                                    <Route
                                      exact
                                      path="/admin/session/:uuid"
                                      render={(props) => {
                                        return <Session />;
                                      }}
                                    />
                                    <Route
                                      exact
                                      path="/admin/sessions"
                                      render={(props) => {
                                        return <Sessions />;
                                      }}
                                    />
                                    <Route
                                      exact
                                      path="/admin/company/:uuid"
                                      render={(props) => {
                                        return <Company />;
                                      }}
                                    />
                                    <Route
                                      exact
                                      path="/admin/companies"
                                      render={(props) => {
                                        return <Companies />;
                                      }}
                                    />
                                    <Route
                                      exact
                                      path="/admin/settings"
                                      render={(props) => {
                                        return <PageTest />;
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <div>loading</div>
                                )}
                              </Segment>
                            </div>
                          </div>
                        </DashboardContainer>
                        {!fullScreen && <div className={styles.push} />}
                      </div>
                    </div>
                    {!fullScreen && (
                      <div className={styles.footer}>
                        <MainFooter />
                      </div>
                    )}
                  </div>
                );
              }}
            />
          </Switch>
        </div>
        <DebugView />
      </KeyHandler>
    );
  }
}

MainPage = connect(
  (state) => ({
    login: state.login.login,
    tutorials: state.tutorial.tutorials,
    tutorial: state.tutorial.tutorial,
    module: state.module.module,
    modules: state.module.modules,
    member: state.member.member,
    themes: state.main.themes,
    vmprojects: state.vuppetmaster.vmprojects,
    variables: state.main.variables,
    user: state.user.user,
    page: state.page.page,
    activePath: state.main.activePath,
  }),
  {}
)(MainPage);

export default withRouter(MainPage);
