import React, { Component } from 'react';

import { Modal, Header, Button } from 'semantic-ui-react';
import styled from 'styled-components';

const ScrollWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  text-align: center;
  overflow-y: auto;
  max-height: calc(80vh - 200px);
`;

const Upload = styled.div`
  display: flex;
  align-items: flex-start;
  float: left;
  margin-bottom: 5px;
  & > input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
`;

const ErrorText = styled.pre`
  display: block;
  padding: 0 10px;
  margin: 0;
  text-align: left;
`;

class ImportMembersModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleFiles(files) {
    // Check for the various File API support.
    if (window.FileReader) {
      this.processFile(files[0]);
    } else {
      alert('FileReader are not supported in this browser.');
    }
  }

  processFile(fileToRead) {
    const reader = new FileReader();
    reader.onload = (event) => {
      this.processData(event.target.result);
    };
    reader.onerror = (event) => {
      this.setState({ error: 'Failed to read file.' });
    };
    reader.readAsText(fileToRead);
  }

  processData(csv) {
    const allTextLines = csv.split(/\r\n|\n/);
    const rows = [];
    for (let i = 1; i < allTextLines.length; i++) {
      let data = allTextLines[i].split(';');
      let newMember = [data[0], data[1], data[2]];
      rows.push(newMember);

      /*      let cells = [];
      for (let j = 0; j < data.length; j++) {
        cells.push(data[j]);
      }
      rows.push(cells);*/
    }
    this.setState({ error: '', rows });
  }

  onSubmit(rows) {
    if (this.props.onApply) {
      const members = rows
        .map(
          (row) =>
            row &&
            row[0] && {
              name: row[0],
              scope: row[1] === 'demo' ? ['demo'] : [],
              email: null,
              passwd: row[2],
            }
        )
        .filter((row) => !!row);
      this.props.onApply(members);
    }
  }

  render() {
    const { onCancel } = this.props;

    const { rows, error } = this.state;

    return (
      <Modal open={true} size="tiny">
        <Header icon={'user add'} content="Import Members" />
        <Modal.Content>
          <ScrollWrapper>
            <table>
              <thead>
                <th>Username</th>
                <th>Email</th>
                <th>Password</th>
              </thead>
              <tbody>
                {rows &&
                  rows.map((row) => (
                    <tr key={JSON.stringify(row)}>
                      {row.map((cell) => (
                        <td key={cell}>{cell}</td>
                      ))}
                    </tr>
                  ))}
              </tbody>
            </table>
          </ScrollWrapper>
        </Modal.Content>
        <Modal.Actions>
          <Upload type="file" placeholder="Upload">
            <input
              type="file"
              id="file"
              name="file"
              accept=".csv"
              onChange={(event) => this.handleFiles(event.target.files)}
              multiple
            />
            <label className="ui button red" htmlFor="file">
              <i className="upload icon" />
              Import CSV
            </label>
            {error && <ErrorText>{error}</ErrorText>}
          </Upload>
          <Button
            color="green"
            disabled={!rows}
            onClick={() => this.onSubmit(rows)}
          >
            add members
          </Button>
          <Button onClick={onCancel}>cancel</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

ImportMembersModal.defaultProps = {};

export default ImportMembersModal;
