

const initialState = {
	page: null,
	node: null
};


const reducerPage = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PAGE':
      return {
        ...state,
        page: action.page,
      };
		case 'SET_NODE':
      return {
        ...state,
        node: action.node,
      };
    default:
      return state;
  }
};

export default reducerPage;
