import { withRouter } from "react-router-dom";
import React, { Component } from 'react';

import { Button, Checkbox } from 'semantic-ui-react';
import EditLine from '../../1_atom/EditLine/EditLine.js';
import './Companies.css';

import { connect } from 'react-redux';
import store from '../../../redux/store';

import RestAPI, { companies } from '../../../RestAPI';
import { actionSetCompany } from '../../../redux/actions/actionCompany.js';

class Company extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      loginname: null,
      active: null,
    };
  }

  onApply() {
    if (!this.props.company) {
      return false;
    }

    let company = {
      ...this.props.company,
    };
    company.name =
      this.state.name !== '' ? this.state.name : this.props.company.name;
    company.loginname =
      this.state.loginname !== null
        ? this.state.loginname
        : this.props.company.loginname;
    company.active =
      this.state.active !== null
        ? this.state.active
        : this.props.company.active;

    store.dispatch(actionSetCompany(company));

    RestAPI.updateCompany(company).then((result) => {
      RestAPI.companies();
      this.props.history.push('/admin/companies');
    });
  }

  render() {
    const { company } = this.props;
    return (
      company && (
        <div id="company">
          {process.env.NODE_ENV === 'development' && (
            <div>UUID: {company.uuid}</div>
          )}
          <EditLine
            label="Name:"
            text={company.name}
            onChanged={(text) => this.setState({ name: text })}
          />
          <EditLine
            label="Login Company Name:"
            text={company.loginname}
            onChanged={(text) => this.setState({ loginname: text })}
          />
          <div>
            <Checkbox
              label="Active"
              checked={
                this.state.active !== null
                  ? this.state.active
                  : this.props.company.active
              }
              onChange={(event, data) => {
                this.setState({
                  active: data.checked,
                });
              }}
            />
          </div>
          <Button onClick={() => this.onApply()}>Apply</Button>
        </div>
      )
    );
  }
}

// You have to connect() to any reducers that you wish to connect to yourself
Company = connect(
  (state) => ({
    company: state.company.company,
  }),
  {}
)(Company);

export default withRouter(Company);
