import React, { Component } from 'react';
import { Checkbox, Segment } from 'semantic-ui-react';

import EditLine from '../../1_atom/EditLine/EditLine.js';
import ViewJson from '../../3_organisms/ViewJson.js';
import MediaSelect from '../../1_atom/MediaSelect/MediaSelect';
import SelectTheme from './SelectTheme';
import CssResult from './CssResult';

import { connect } from 'react-redux';
import store from './../../../redux/store';
import { actionSetNode } from '../../../redux/actions/actionPage';

import RestAPI from '../../../RestAPI';

class NodeImage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onFormSubmit(e) {
    e.preventDefault(); // Stop form submit
    if (this.state.file) this.fileUpload(this.state.file);
  }

  onChange(e) {
    console.log('############# filename ', e.target.files[0].name);

    if (!this.props.node.data) this.props.node.data = { filename: '' };
    this.props.node.data = {
      ...this.props.node.data,
      filename: e.target.files[0].name,
    };
    store.dispatch(actionSetNode(this.props.node));
    this.setState({ file: e.target.files[0] });
  }

  fileUpload(file) {
    RestAPI.upload(file, (res) => {
      console.log('## upload res', res);
      if (res.result && res.result === 'ok')
        this.setState({ filename: file.name });
    });
    return;
  }

  render() {
    return (
      <Segment>
        {this.props.node.name}
        <EditLine
          label="Name:"
          text={this.props.node.text}
          onChanged={(text) =>
            store.dispatch(actionSetNode({ ...this.props.node, name: text }))
          }
        />
        <EditLine
          label="Start:"
          text={this.props.node.start}
          onChanged={(text) =>
            store.dispatch(actionSetNode({ ...this.props.node, start: text }))
          }
        />
        <EditLine
          label="End:"
          text={this.props.node.end}
          onChanged={(text) =>
            store.dispatch(actionSetNode({ ...this.props.node, end: text }))
          }
        />
        <MediaSelect
          label={'Image:'}
          media={
            this.props.media &&
            this.props.node.data &&
            this.props.media.find(
              (m) => m.uuid === this.props.node.data.resource
            )
          }
          onChanged={(media) => {
            let node = JSON.parse(JSON.stringify(this.props.node));
            node.data = { ...node.data, resource: media.uuid };
            store.dispatch(actionSetNode(node));
          }}
        />

        <SelectTheme />
        <CssResult theme={this.props.node.theme} />
        <br />

        <ViewJson
          update={(css) => {
            if (this.props.onCssChanged(css))
              store.dispatch(
                actionSetNode({ ...this.props.node, css: JSON.parse(css) })
              );
          }}
          source={JSON.stringify(this.props.node.css, null, 4)}
          title={''}
        />

        <Checkbox
          label="Background"
          toggle
          checked={
            this.props.node.data && this.props.node.data.background
              ? true
              : false
          }
          onChange={(event, data) => {
            let node = JSON.parse(JSON.stringify(this.props.node));
            if (!node.data) node.data = { background: '' };
            node.data = { ...node.data, background: data.checked };
            store.dispatch(actionSetNode(node));
          }}
        />
      </Segment>
    );
  }
}

NodeImage = connect(
  (state) => ({
    node: state.page.node,
    media: state.media,
  }),
  {}
)(NodeImage);

export default NodeImage;
