import React, { Component } from 'react';
import { Dropdown, Segment } from 'semantic-ui-react';

import EditLine from '../../1_atom/EditLine/EditLine.js';

import { connect } from 'react-redux';
import store from './../../../redux/store';
import { actionSetNode } from '../../../redux/actions/actionPage';

class NodeVarCount extends Component {
  getVarType(uuid) {
    let v = this.props.variables.find((v) => v.uuid === uuid);
    if (v) return v.type;
    else return 'undef';
  }

  render() {
    let optVars = [];
    if (this.props.variables)
      this.props.variables.forEach((element) => {
        if (element.type === 'count')
          optVars.push({
            key: element.uuid,
            text: element.name,
            value: element.uuid,
          });
      });

    let optSetOperator = [
      { key: '=', text: '=', value: '=' },
      { key: '+=', text: '+=', value: '+=' },
      { key: '-=', text: '-=', value: '-=' },
    ];

    return (
      <Segment>
        {this.props.node.name}
        <EditLine
          label="Start:"
          text={this.props.node.start}
          onChanged={(text) =>
            store.dispatch(actionSetNode({ ...this.props.node, start: text }))
          }
        />
        <Dropdown
          placeholder="Select variable"
          value={this.props.node.data.var ? this.props.node.data.var : ''}
          selection
          options={optVars}
          onChange={(e, d) => {
            let node = JSON.parse(JSON.stringify(this.props.node));
            node.data.var = d.value;
            store.dispatch(actionSetNode(node));
          }}
        />
        {this.getVarType(this.props.node.data.var) === 'count' && (
          //
          // set count
          //
          <div>
            <Dropdown
              placeholder="Select operator"
              value={
                this.props.node.data.operator
                  ? this.props.node.data.operator
                  : ''
              }
              selection
              options={optSetOperator}
              onChange={(e, d) => {
                let node = JSON.parse(JSON.stringify(this.props.node));
                node.data.operator = d.value;
                store.dispatch(actionSetNode(node));
              }}
            />
            <EditLine
              label="Value:"
              text={
                this.props.node.data.value
                  ? this.props.node.data.value.toString()
                  : 0
              }
              onChanged={(text) => {
                let node = JSON.parse(JSON.stringify(this.props.node));
                let value = Number.parseInt(text, 10);
                node.data.value = value;
                store.dispatch(actionSetNode(node));
              }}
            />
          </div>
        )}
      </Segment>
    );
  }
}

NodeVarCount = connect(
  (state) => ({
    node: state.page.node,
    page: state.page.page,
    tutorial: state.tutorial.tutorial,
    variables: state.main.variables,
  }),
  {}
)(NodeVarCount);

export default NodeVarCount;
