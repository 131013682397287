import {
  SET_VUPPETMASTER,
  SET_CURRENT_SEQUENCEUUID,
} from '../actions/actionVuppetmaster';

const effectVuppetmaster = (action, getState, dispatch) => {
  switch (action.type) {
    case SET_VUPPETMASTER:
      break;
    case SET_CURRENT_SEQUENCEUUID:
      break;
    default:
      break;
  }
};

export default effectVuppetmaster;
