import React, { Component } from 'react';
import {
	withRouter,
} from "react-router-dom";

import { Modal, Header, Segment, Button, Icon } from 'semantic-ui-react'

import { connect } from 'react-redux';
import store from '../../../redux/store';
import { actionSetTutorial } from '../../../redux/actions/actionTutorial';
import { actionSetPage, actionSetNode } from '../../../redux/actions/actionPage';

import Page from './Page'
import TrainerView from '../TrainerView/TrainerView'
import './PageTree.css';

import RestAPI from '../../../RestAPI';
import SplitPane from 'react-split-pane';

import 'react-sortable-tree/style.css';
import SortableTree, { changeNodeAtPath } from 'react-sortable-tree';
import FileExplorerTheme from 'react-sortable-tree-theme-file-explorer';

const UUID = require('uuid/v4');
var clone = require('clone');

class PageTree extends Component {

	constructor(props) {
		super(props);

		this.state =
			{
				errorOpen: false,
				deleteOpen: false,
				selected: null,
				errorMessage: ''

			};
	}

	onSave() {
		let tutorial = clone(this.props.tutorial);
		tutorial.modified = new Date().toISOString();
		this.setClearSelect(tutorial.pages_hierarchy);
		RestAPI.updateTutorial(tutorial).then((res) => {
			if (res) {
				if (res.error) {
					this.setState({ errorOpen: true, errorMessage: JSON.stringify(res.error, null, 4) });
				}
				else if (res.data && res.data.tutorials_update) {
					if (!res.data.tutorials_update.ok) {
						this.setState({ errorOpen: true, errorMessage: JSON.stringify("tutorials_update false", null, 4) });
					}
				}
			}
		});
	}

	onDelete() {

		if (!this.state.selected)
			return;

		if (this.state.selected.children && this.state.selected.children.length)
			return;

		let tutorial = clone(this.props.tutorial);

		if (this.removeParent(tutorial.pages_hierarchy, this.state.selected.uuid)) {
			if (tutorial.pages_array) {
				tutorial.pages_array = tutorial.pages_array.filter(p => p.uuid !== this.state.selected.uuid);
			}
			store.dispatch(actionSetTutorial(tutorial));
			this.setState({ selected: null });
		}
	}

	removeParent(array, uuid) {
		for (var i in array) {
			if (array[i].uuid === uuid) {
				array.splice(i, 1);
				return true;
			}
			else if (array[i].children) {
				if (this.removeParent(array[i].children, uuid))
					return true;
			}
		};
		return false;
	}

	onDuplicate() {

		if (!this.state.selected)
			return;

		let tutorial = clone(this.props.tutorial);

		let page2copy = tutorial.pages_array.find(p => p.uuid === this.state.selected.uuid);
		if (!page2copy)
			return;

		let page = {
			uuid: UUID(),
			name: page2copy.name + '_copy',
			theme: page2copy.theme,
			vars: page2copy.vars ? clone(page2copy.vars) : null,
			nodes: page2copy.nodes ? clone(page2copy.nodes) : [],
		};
		tutorial.pages_array.push(page);
		tutorial.pages_hierarchy.push({ name: page.name, uuid: page.uuid });
		store.dispatch(actionSetTutorial(tutorial));
	}

	onAddNew() {
		let tutorial = clone(this.props.tutorial);

		if (!tutorial.pages_array)
			tutorial.pages_array = [];
		if (!tutorial.pages_hierarchy)
			tutorial.pages_hierarchy = [];

		let page = {
			uuid: UUID(),
			name: 'name',
			theme: null,
			vars: null,
			nodes: [],
		};
		tutorial.pages_array.push(page);
		tutorial.pages_hierarchy.push({ name: page.name, uuid: page.uuid });

		store.dispatch(actionSetTutorial(tutorial));
	}


	setClearSelect(array) {
		if (array) {
			array.forEach(element => {
				element.selected = null;
				this.setClearSelect(element.children);
			});
		}
	}

	selectPage(pageNode) {
		this.setClearSelect(this.props.tutorial.pages_hierarchy);

		pageNode.selected = true;
		this.setState({ selected: pageNode });

		this.forceUpdate();

		if (!this.props.tutorial.pages_array)
			return;

		let page = this.props.tutorial.pages_array.find(p => p.uuid === pageNode.uuid);

		if (!page.nodes)
			page.nodes = [];

		store.dispatch(actionSetNode(null));
		store.dispatch(actionSetPage(page));

	}



	render() {

		const getNodeKey = ({ treeIndex }) => treeIndex;

		const style = { fontSize: '1.1rem' };
		const styleSel = { fontSize: '1.1rem', backgroundColor: '#ffa102' };


		return (

			<div style={{ height: '100%' }}>
				<div style={{ height: '100%' }}>


					<SplitPane split="vertical" minSize={50} defaultSize={400}>
						<div style={{ height: '80%' }}>
							<SortableTree
								theme={FileExplorerTheme}
								treeData={(this.props.tutorial && this.props.tutorial.pages_hierarchy) ? this.props.tutorial.pages_hierarchy : []}
								onChange={pages_hierarchy => {
									let tutorial = clone(this.props.tutorial);
									tutorial.pages_hierarchy = pages_hierarchy;
									store.dispatch(actionSetTutorial(tutorial));
								}}

								generateNodeProps={({ node, path }) => ({
									onClick: (e) => {
										const clickedItemClassName = e.target.className;
										if (
											clickedItemClassName !== 'rstcustom__expandButton' &&
											clickedItemClassName !== 'rstcustom__collapseButton'
										) {
											this.selectPage(node);
										}
									},
									icons: node.isDirectory
										? [
											<Icon name='folder outline' />
										]
										: [
											<Icon name='folder outline' />
										],
									title: (
										<div>
											<input
												style={node.selected ? styleSel : style}
												value={node.name}
												onChange={event => {
													const name = event.target.value;

													console.log('#### rename ', name, node);

													let tutorial = clone(this.props.tutorial);
													tutorial.pages_hierarchy = changeNodeAtPath({
														treeData: this.props.tutorial.pages_hierarchy,
														path,
														getNodeKey,
														newNode: { ...node, name },
													});

													let page = tutorial.pages_array.find(p => p.uuid === node.uuid);
													page.name = name;
													store.dispatch(actionSetTutorial(tutorial));
												}}
											/>
										</div>
									),
								})}

							/>
							<Button color='green' icon onClick={() => { this.onSave(); }} >
								<Icon name='save' />
							</Button>
							<Button color='blue' icon onClick={() => { this.onAddNew(); }} >
								<Icon name='add' />
							</Button>
							{this.state.selected && (
								<Button color='blue' icon onClick={() => { this.onDuplicate(); }} >
									<Icon name='copy' />
								</Button>)}
							{this.state.selected && (
								<Button color='red' icon onClick={() => { this.setState({ deleteOpen: true }); }} >
									<Icon name='delete' />
								</Button>)}
						</div>
						<div style={{ height: '100%' }}>
							<SplitPane split="horizontal" minSize={250}
								pane2Style={{ display: 'flex', overflow: 'auto', width: '100%', border: '0px solid #000000' }}>
								<div className='page' >
									{this.props.page ? (<Page style={{ width: '100%', height: '100%' }} ></Page>) : null}
								</div>
								{this.props.tutorial ? <TrainerView mode='preview' /> : '---'}
							</SplitPane>
						</div>
					</SplitPane>
				</div >

				<Modal open={this.state.deleteOpen}>
					<Header icon='delete' content='Delete Page?' />
					<Modal.Content>
						<Segment color='red'>
							Do you really want to delete the page?
					</Segment>
						<Button onClick={() => {
							this.setState({ deleteOpen: false });
							this.onDelete();
						}} >ok </Button>
						<Button onClick={() => {
							this.setState({ deleteOpen: false });
						}} >cancel </Button>
					</Modal.Content>
				</Modal>

				<Modal open={this.state.errorOpen}>
					<Header icon='delete' color='red' content='Error save' />
					<Modal.Content>
						<Segment>
							{this.state.errorMessage}
						</Segment>
						<Button onClick={() => {
							this.setState({ errorOpen: false });
						}} >ok </Button>
					</Modal.Content>
				</Modal>
			</div >

		);
	}
}

/*
									{this.props.tutorial ? <TrainerView mode='preview' /> : '---'}

 // , backgroundColor: '#0f00f0', 	border: '10px solid #000000'}}   >


								<div style={{width:'100%', height:'100%', backgroundColor:'#ff0000'}}>
								<Segment >
								2
								</Segment>
								</div >
*/

/*
<div className='page' >
{ this.props.page?(<Page style={{ width: '100%', height: '100%' }} ></Page>):null}
</div >
<div className='page' >
{this.props.tutorial ? <TrainerView mode='inwindow' /> : '---'}
</div >
*/

// You have to connect() to any reducers that you wish to connect to yourself
PageTree = connect(
	state => ({
		tutorial: state.tutorial.tutorial,
		user: state.user.user,
		page: state.page.page,
	}),
	{}, // bind account loading action creator
)(PageTree);

export default withRouter(PageTree);
