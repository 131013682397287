import React, { Component } from 'react';
import {
	withRouter,
} from "react-router-dom";

import { Button, Icon, Segment } from 'semantic-ui-react'

import { connect } from 'react-redux';
import './PageTree.css';

import SplitPane from 'react-split-pane';


// This only needs to be done once; probably during your application's bootstrapping process.
//import 'react-sortable-tree/style.css';

// You can import the default tree with dnd context
import SortableTree from 'react-sortable-tree';

// Or you can import the tree without the dnd context as a named export. eg
//import { SortableTreeWithoutDndContext as SortableTree } from 'react-sortable-tree';



class PageTest extends Component {

	constructor(props) {
		super(props);

		this.state = {
			treeData: [{ title: 'Chicken', children: [{ title: 'Egg' }] }],
		};
	}

	onAdd() {
		let treeData = this.state.treeData;

		treeData.push({ title: 'cd' });

		this.setState({ treeData: treeData });
	}

	//	height: calc(100% - 110px);

	render() {

		return (
			<div style={{ height: '100%' }}>
				<div style={{ height: '100%' }}>

					<SplitPane split="vertical" minSize={'500px'} defaultSize={400}>
						<div style={{ height: '100%' }}>
							<SortableTree
								treeData={this.state.treeData}
								onChange={treeData => this.setState({ treeData: treeData })}
							/>
							<Button color='green' icon onClick={() => { this.onAdd(); }} >
								<Icon name='save' />
							</Button>
						</div >
						<SplitPane split="horizontal" pane2Style={{ display: 'block', overflow: 'auto',   width: '100%', backgroundColor: '#ff0000', border: '10px solid #000000' }} >
							<div className='page'>
								<Segment  >
									1
				<br />
									1
				<br />
									1
				<br />
									1
				<br />
									1
				<br />
									1
				<br />
									1
				<br />
								</Segment>
							</div >

							<div className='cdk'>
								3
									<Segment >
									2
								</Segment>
							</div>
						</SplitPane>
					</SplitPane>
				</div >
			</div >
		);
	}
}


// You have to connect() to any reducers that you wish to connect to yourself
PageTest = connect(
	state => ({
		tutorial: state.tutorial.tutorial
		//    pages_array: state.page.pages_array,
	}),
	{}, // bind account loading action creator
)(PageTest);

export default withRouter(PageTest);
