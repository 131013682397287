import React, { Component } from 'react';

import { Modal, Header, Button } from 'semantic-ui-react'
import styled from 'styled-components';

const ScrollWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  text-align: center;
  overflow-y: auto;
  max-height: calc(80vh - 200px);
`;

const Upload = styled.div`
  display: flex;
  align-items: flex-start;
  float: left;
  margin-bottom: 5px;
  & > input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
`;

const ErrorText = styled.pre`
  display: block;
  padding: 0 10px;
  margin: 0;
  text-align: left;  
`;

class ImportSessionsModal extends Component {

	constructor(props) {
		super(props);
		this.state = {};
	}

	handleFiles(files) {
		// Check for the various File API support.
		if (window.FileReader) {
			this.processFile(files[0]);
		} else {
			alert('FileReader are not supported in this browser.');
		}
	}

	processFile(fileToRead) {
		const reader = new FileReader();
		reader.onload = (event) => {
			this.processData(event.target.result);
		};
		reader.onerror = (event) => {
			this.setState({ error: 'Failed to read file.' })
		};
		reader.readAsText(fileToRead);
	}

	processData(json) {

		let sessions = null;
		try {
			let result = JSON.parse(json);
			sessions = result.result;
		}
		catch (e) {
			this.setState({ error: 'Failed parse sessions' })
		}

		// convert string pages_uuid to array[] 
		sessions = sessions.map(session => {
			if (typeof session.pages_uuid === 'string' || session.pages_uuid instanceof String) {
				if (session.pages_uuid === '') {
					session.pages_uuid = [];
				}
				else {
					// convert to array
					session.pages_uuid = JSON.parse(session.pages_uuid.replace(/'/g, "\""));
				}
			}
			return session;
		});

		// convert session status 
		sessions = sessions.map(session => {
			if (!session.status) {
				session.status = {
					end : false || session.finished,
					finished : session.finished,
				}
			}
			return session;
		});


		// convert tutorial_uuid to module_uuid 
		sessions = sessions.map(session => {
			if (session.module_uuid === undefined || session.module_uuid === null) {
				if (session.tutorial_uuid) {
					let module = this.props.modules.find( m => m.tutorial_uuid === session.tutorial_uuid);
					session.module_uuid = module && module.uuid;
				}
			}
			return session;
		});


		// filter existing entries
		sessions = sessions.filter(session => {
			let found = this.props.sessions.find(s => {
				if (s._id === session._id) {
					return session
				}
				if (
					JSON.stringify(s.pages_uuid) === JSON.stringify(session.pages_uuid)
					&&
					s.created === session.created
					&&
					s.modified === session.modified
					&&
					s.member_uuid === session.member_uuid
					&&
					s.module_uuid === session.module_uuid
					&&
					//					JSON.stringify(s.variables) === JSON.stringify(session.variables)
					//				&&
					s.status.finished === session.status.finished
					)
				{
					return session;
				}
				else return null;
			});
			return found===undefined;
		});

		this.setState({ error: '', importSessions: sessions })
	}

	onSubmit() {

		if (this.props.onApply) {
			this.props.onApply(this.state.importSessions);
		}
	}

	render() {
		const {
			onCancel,
			members,
			modules
		} = this.props;

		const {
			importSessions,
			error,
		} = this.state;

		return (
			<Modal open={true} size="large">
				<Header icon={'user add'} content="Import Sessions" />
				<Modal.Content>
					<ScrollWrapper>
						<table>
							<thead>
								<th>member</th>
								<th>module</th>
								<th>created</th>
								<th>modified</th>
							</thead>
							<tbody>
								{
									importSessions && importSessions.map(session => {
										let member = members && members.find(m => m.uuid === session.member_uuid);
										let module = modules && modules.find(m => m.tutorial_uuid === session.tutorial_uuid);
										return (
											<tr key={session.uuid}>
												<td>{member && member.name}</td>
												<td>{module && module.name}</td>
												<td>{session.created}</td>
												<td>{session.modified}</td>
											</tr>
										)
									})
								}
							</tbody>
						</table>
					</ScrollWrapper>
				</Modal.Content>
				<Modal.Actions>
					<Upload type="file" placeholder='Upload'>
						<input
							type="file"
							id="file"
							name="file"
							accept=".json"
							onChange={(event) => this.handleFiles(event.target.files)}
							multiple
						/>
						<label
							className="ui button red"
							htmlFor="file"
						>
							<i className="upload icon" />Import JSON
            </label>
						{
							error && <ErrorText>{error}</ErrorText>
						}
					</Upload>
					<Button
						color='green'
						disabled={!importSessions}
						onClick={() => this.onSubmit(importSessions)}
					>
						add sessions
          </Button>
					<Button onClick={onCancel}>cancel</Button>
				</Modal.Actions>
			</Modal>
		);
	};
}

ImportSessionsModal.defaultProps = {};

export default ImportSessionsModal;

