import React from 'react';

import styles from './Copyright.module.scss';
import { translate } from './../../../helpers/translate';

const Copyright = ({ showLink = true, language = 'de' }) => {
  return (
    <div className={styles.main}>
      {translate('copyright', language)}
      {showLink && (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.virtual-trainer.de"
        >
          {' – '}
          www.virtual-trainer.de
        </a>
      )}
    </div>
  );
};

export default Copyright;
