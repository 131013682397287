import React, { Component } from 'react';
import { Button } from 'semantic-ui-react'
import ViewJson from './ViewJson.js';

import { connect } from 'react-redux';
import store from '../../redux/store';

import { actionSetThemes } from '../../redux/actions/actionMain'
import { actionSetTutorial } from '../../redux/actions/actionTutorial.js';

import RestAPI from '../../RestAPI';

/*

let themes = [
	{
		theme: 'common',
		classes: {
			"bg": {
				width: '100%'
			},
			"button": {
				width: '44px'
			}
		}
	},
	{
		theme: 'blau',
		classes: {
			"button": {
				postion: '10em'
			},
			"claudia": {
				postion: '10em'
			}
		}
	}
];

common.button
blau.claudia.button

*/



















class MainThemes extends Component {

	constructor(props) {
		super(props);
		this.state = {
			text: null,
			applyActive: true,
			message: ''
		}
	}

	addNewTheme()
	{
		let css = null;

		if (this.state.text === null) {
			if (Array.isArray(this.props.themes)) 
				css = this.props.themes;
			else
				css = [];
		}
		else 
			css = JSON.parse(this.state.text);

		if (Array.isArray(css)) {
			css.push( {
					theme: 'newtheme',
					classes: {
						image: {
							width: '100%'
						},
						button: {
							width: '100%'
						}
				}
			});
		}
		this.onCssChanged( JSON.stringify(css) );
		this.setState( { text : JSON.stringify(css) });
		store.dispatch(actionSetThemes(css));
	}

	onCssChanged(text) {
		if (text) {
			try {
				JSON.parse(text);
				this.setState({ applyActive: true });
				return true;
			}
			catch (e) {
				this.setState({ applyActive: false });
				return false;
			}
		}
	}


	render() {
		return (
			<div>
				<ViewJson
					update={(text) => {
						this.onCssChanged(text);
						this.setState({ text: text });
					}}
					source={JSON.stringify(this.props.themes, null, 2)}
					title={''}
				/>
				<Button
					color={this.state.applyActive ? 'green' : 'red'}
					onClick={() => {
						let tutorial = JSON.parse(JSON.stringify(this.props.tutorial));
						tutorial.themes = JSON.parse(this.state.text);
						if (!tutorial.themes)
							return;
						store.dispatch(actionSetThemes(tutorial.themes));
						store.dispatch(actionSetTutorial(tutorial));
						RestAPI.updateTutorial(tutorial).then((result) => {
/*							if (result.error) {
								this.setState( { message: 'Status ' + result.status + ' ' + result.error })
							}
							else {
								this.setState( { message: '' });*/
								this.props.update();
//							}
						});
					}} >Apply</Button>
				<Button
					onClick={() => { this.addNewTheme();
					}} >Add new class</Button>
					{this.state.message}
			</div>
		)
	}
}


// You have to connect() to any reducers that you wish to connect to yourself
MainThemes = connect(
	state => ({
		themes: state.main.themes,
		tutorial: state.tutorial.tutorial
	}),
	{},
)(MainThemes);
export default MainThemes;
