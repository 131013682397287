
export const SET_VUPPETMASTER = 'SET_VUPPETMASTER';
export const SET_CURRENT_SEQUENCEUUID = 'SET_CURRENT_SEQUENCEUUID';

export const actionSetVuppetmaster = (data) => ({
	type: 'SET_VUPPETMASTER',
	vuppetmaster: data
});

export const actionSetStartSequenceUUID = (uuid) => ({
	type: 'SET_START_SEQUENCEUUID',
	start_sequenceuuid: uuid
});

export const actionCurrentSequenceUUID = (uuid) => ({
	type: 'SET_CURRENT_SEQUENCEUUID',
	current_sequenceuuid: uuid
});

export const actionSetVmProjects = (data) => ({
	type: 'SET_VMPROJECTS',
	vmprojects: data
});

export const actionSetVmProject = (data) => ({
	type: 'SET_VMPROJECT',
	vmproject: data
});
