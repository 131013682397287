import React, { Component } from 'react';

import { Menu } from 'semantic-ui-react';
import { actionSetPath } from '../../../redux/actions/actionMain';
import store from '../../../redux/store';
import { scopeIncludesOneOf } from '../../../helpers/scopecheck';
import { connect } from 'react-redux';
import RestAPI from '../../../RestAPI';

class AdminMenu extends Component {
  handleItemClick = (item) => {
    switch (item) {
      case 'users':
        RestAPI.users();
        RestAPI.companies();
        break;
      case 'sessions':
        RestAPI.members();
        RestAPI.modules();
        RestAPI.companies();
        break;
      case 'feedbacks':
        RestAPI.members();
        RestAPI.feedbacks();
        RestAPI.modules();
        RestAPI.companies();
        break;
      case 'tutorials':
        RestAPI.tutorials();
        break;
      case 'members':
        RestAPI.members();
        RestAPI.modules();
        RestAPI.companies();
        break;
      case 'modules':
        RestAPI.modules();
        RestAPI.tutorials();
        RestAPI.members();
        RestAPI.companies();
        break;
      case 'companies':
        RestAPI.companies();
        RestAPI.modules();
        break;
      default:
        break;
    }

    this.props.history.push(`/admin/${item}`);
    store.dispatch(actionSetPath(item));
  };

  render() {
    const { scope, activePath } = this.props;

    const companies = scopeIncludesOneOf(scope, ['root', 'admin', 'user']);
    const users = scopeIncludesOneOf(scope, ['root', 'admin']);
    const tutorials = scopeIncludesOneOf(scope, [
      'root',
      'admin',
      'production',
    ]);
    const sessions = scopeIncludesOneOf(scope, ['root', 'admin', 'user']);
    const feedbacks = scopeIncludesOneOf(scope, ['root', 'admin', 'user']);
    const members = scopeIncludesOneOf(scope, ['root', 'admin', 'user']);
    const modules = scopeIncludesOneOf(scope, ['root', 'admin', 'user']);

    return (
      <Menu fluid vertical size="massive">
        <Menu.Menu>
          {companies && (
            <Menu.Item
              name="Companies"
              active={activePath === 'companies'}
              onClick={() => this.handleItemClick('companies')}
            />
          )}
          {users && (
            <Menu.Item
              name="Users"
              active={activePath === 'users'}
              onClick={() => this.handleItemClick('users')}
            />
          )}
          {members && (
            <Menu.Item
              name="Members"
              active={activePath === 'members'}
              onClick={() => this.handleItemClick('members')}
            />
          )}
          {tutorials && (
            <Menu.Item
              name="Tutorials"
              active={activePath === 'tutorials'}
              onClick={() => this.handleItemClick('tutorials')}
            />
          )}
          {modules && (
            <Menu.Item
              name="Modules"
              active={activePath === 'modules'}
              onClick={() => this.handleItemClick('modules')}
            />
          )}
          {sessions && (
            <Menu.Item
              name="Sessions"
              active={activePath === 'sessions'}
              onClick={() => this.handleItemClick('sessions')}
            />
          )}
          {feedbacks && (
            <Menu.Item
              name="Feedbacks"
              active={activePath === 'feedbacks'}
              onClick={() => this.handleItemClick('feedbacks')}
            />
          )}
        </Menu.Menu>
      </Menu>
    );
  }
}

// You have to connect() to any reducers that you wish to connect to yourself
AdminMenu = connect(
  (state) => ({
    tutorial: state.main.tutorial,
  }),
  {} // bind account loading action creator
)(AdminMenu);

export default AdminMenu;
