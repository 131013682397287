

export const actionSetModule = (module) => ({
	type: 'SET_MODULE',
	module: module,
});

export const actionSetModules = (modules) => ({
	type: 'SET_MODULES',
	modules: modules,
});

export const actionSetLanguage = (language) => ({
	type: 'SET_LANGUAGE',
	language: language,
});
