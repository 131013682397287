import React, { Component } from 'react';
import { Button, Modal, Segment, Header } from 'semantic-ui-react'

import ViewJson from '../../3_organisms/ViewJson.js';

import store from '../../../redux/store';
import { actionSetNode } from '../../../redux/actions/actionPage'
import { connect } from 'react-redux';

class CssResult extends Component {

	constructor(props) {
		super(props);

		this.state = {
			open: false
		}

		if (this.props.node.theme) {
			this.updateTheme(this.props.node.theme.name);
			this.updateClass(this.props.node.theme.class);
		}
	}

	updateTheme(name) {
		let node = JSON.parse(JSON.stringify(this.props.node));
		if (!node.theme || (typeof node.theme === 'string' || node.theme instanceof String)) {
			node.theme = {
				name: 'none',
				class: 'none'
			}
		}
		node.theme.name = name;
		store.dispatch(actionSetNode(node));
	}

	updateClass(name) {
		let node = JSON.parse(JSON.stringify(this.props.node));
		if (!node.theme || (typeof node.theme === 'string' || node.theme instanceof String)) {
			node.theme = {
				name: 'none',
				class: 'none'
			}
		}
		node.theme.class = name;
		store.dispatch(actionSetNode(node));
	}


	determineThemeCSS(style) {
		if (this.props.theme === undefined)
			return;
		if (typeof this.props.theme === 'string' || this.props.theme instanceof String)
			return;

		if (this.props.themes) {

			let ft = this.props.themes.find((t) => (t.theme === this.props.theme.name));
			if (ft && ft.classes) {

				// get class
				let classInstance = ft.classes[this.props.theme.class];
				if (classInstance) {
					Object.keys(classInstance).forEach((k) => {
						style[k] = classInstance[k];
					});
				}
			}
		}

		// use node css
		try {
			let nodestyle = JSON.parse(JSON.stringify(this.props.node.css));
			Object.keys(nodestyle).forEach((k) => {
				style[k] = nodestyle[k];
			});

			style.position = 'absolute';
			style.zIndex = '-1';
		} catch (e) {
			console.error('css parse error: ',this.props.node.name,'#',this.props.node.type,'#' , e);
		}
	}


	render() {


		let style = {};
		this.determineThemeCSS(style);


		return (
			<div style={{ display: 'inline-block' }}>
				<Button onClick={() => { this.setState({ open: true }) }}> Show Result </Button>
				<Modal open={this.state.open}>
					<Header icon='delete' content='Result' />
					<Modal.Content>
						<Segment>
							<ViewJson
								source={JSON.stringify(style, null, 4)}
								title={''}
							/>
						</Segment>
						<Button onClick={() => {
							this.setState({ open: false });
						}} >ok </Button>
					</Modal.Content>
				</Modal>
			</div>
		)
	}
}

CssResult = connect(
	state => ({
		themes: state.main.themes,
		node: state.page.node,
	}),
	{},
)(CssResult);


export default CssResult;

