import React, { Component } from 'react';

import NodeText from './NodeText';
import NodeImage from './NodeImage';
import NodePage from './NodePage';
import NodeButton from './NodeButton';
import NodeScript from './NodeScript';
import NodeTTS from './NodeTTS';
import NodeSound from './NodeSound';
import NodeRating from './NodeRating';
import NodeVarCount from './NodeVarCount';
import NodeVarText from './NodeVarText';
import NodeVarPage from './NodeVarPage';
import NodeVarCompare from './NodeVarCompare';

import { connect } from 'react-redux';

class NodeProperties extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  renderNode() {
    switch (this.props.node.type) {
      case 'image':
        return (
          <NodeImage onCssChanged={(css) => this.props.onCssChanged(css)} />
        );
      case 'text':
        return (
          <NodeText onCssChanged={(css) => this.props.onCssChanged(css)} />
        );
      case 'page':
        return <NodePage />;
      case 'button':
        return (
          <NodeButton onCssChanged={(css) => this.props.onCssChanged(css)} />
        );
      case 'script':
        return <NodeScript />;
      case 'tts':
        return <NodeTTS />;
      case 'sound':
        return <NodeSound />;
      case 'rating':
        return (
          <NodeRating onCssChanged={(css) => this.props.onCssChanged(css)} />
        );
      case 'var_set_count':
        return <NodeVarCount />;
      case 'var_set_text':
        return <NodeVarText />;
      case 'var_set_page':
        return <NodeVarPage />;
      case 'var_compare':
        return <NodeVarCompare />;
      default:
        return null;
    }
  }

  render() {
    return <div>{this.renderNode()}</div>;
  }
}

NodeProperties = connect(
  (state) => ({
    node: state.page.node,
  }),
  {}
)(NodeProperties);

export default NodeProperties;
