

const initialState = {
  companies: null,
  company: null,
};


const reducerTutorial = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_COMPANIES':
      return {
        ...state,
        companies: action.companies,
      };
		case 'SET_COMPANY':
      return {
        ...state,
        company: action.company,
      };
    default:
      return state;
  }
};

export default reducerTutorial;
