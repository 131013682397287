
const UUID = require('uuid/v4');


function db2css(css) {
	let newcss = {};
	css.forEach(o => {
		newcss[o.k] = o.v;
	});
	return newcss;
}

function css2db(css) {
	let newcss = [];
	Object.keys(css).forEach((k) => {
		newcss.push({ k: k, v: css[k] });
	});
	return newcss;
}


export const convertTutorialToDB = (tutorial) => {

	// prepare tutorial pages for saving
	let tut = JSON.parse(JSON.stringify(tutorial));

	if (tut.themes) {
		tut.themes.forEach(theme => {
			Object.keys(theme.classes).forEach((k) => {
				let css = css2db(theme.classes[k])
				theme.classes[k] = css;
			});
		});
	}

	if (tut.pages_array) {
		tut.pages_array.forEach(p => {
			if (p.nodes) {
				p.nodes.forEach(n => {
					if (n.css) {
						let newcss = [];
						Object.keys(n.css).forEach((k) => {
							newcss.push({ k: k, v: n.css[k] });
						});
						n.css = newcss;
					}
				});
			}
		});
	}
	return tut;
}


export const convertTutorialFromDB = (tut) => {
	if (!tut.variables)
		tut.variables = [];
	else
		tut.variables.forEach(v => {
			if (v.type === 'count')
				v.value = 0;
			else
				v.value = '';
		});

	if (tut.themes) {
		tut.themes.forEach(theme => {
			Object.keys(theme.classes).forEach((k) => {
				if (Array.isArray(theme.classes[k])) {
					let css = db2css(theme.classes[k]);
					theme.classes[k] = css;
				};
			});
		});
	}


	if (tut.pages_array) {
		tut.pages_array.forEach(p => {
			if (p.nodes) {
				p.nodes.forEach(n => {
					// convert css 
					if (n.css && (n.css.constructor && {}.constructor)) {
						let newcss = {};
						n.css.forEach(o => {
							newcss[o.k] = o.v;
						});
						n.css = newcss;
					}
					else 
						n.css = {};

					// check if uuid of node vars exists
					if (n.type === 'var_set_page' || n.type === 'var_set_text' || n.type === 'var_set_count' || n.type === 'var_compare') {
						if (!n.uuid) {
							n.uuid = UUID();
						}
					}
				});
			}

			// convert old vars to node events
			if (p.vars) {
				p.vars.forEach(v => {

					let es = {
						'name': '',
						'type': '',
						'active': true,
						'data': {
							operator: '=',
							var: v.var_uuid,
							value: v.value
						}
					}

					// check var type and convert them
					let fv = tut.variables.find(gv => gv.uuid === v.var_uuid);
					if (fv) {
						switch (fv.type) {
							case 'text': es.type = 'var_set_text'; break;
							case 'page': es.type = 'var_set_page'; break;
							case 'count': es.type = 'var_set_count'; break;
							default: break;
						}
					}
					p.nodes.push(es);
				});
				p.vars = null;
			}
		});
	}
}


const globalVars = [
	{ uuid: UUID(), mode: 'system', type: 'count', name: '_minScore_', value: 0 },
	{ uuid: UUID(), mode: 'system', type: 'count', name: '_maxScore_', value: 0 },
	{ uuid: UUID(), mode: 'system', type: 'count', name: '_currentScore_', value: 0 },
	{ uuid: UUID(), mode: 'system', type: 'count', name: '_finished_', value: 0 },
];

export const createNewTutorial = (name) => {

	let tutorial = {
		version: 'v2',
		name: name,
		pages: [],
		pages_array: [],
		pages_hierarchy: [],
		themes: [],
		variables: [],
		vuppetmaster_key: '',
		modified: new Date().toISOString(),
		created: new Date().toISOString(),
	};

	globalVars.forEach((v) => {
		tutorial.variables.push(v);
	});

	let variable = {
		uuid: UUID(),
		mode: 'system',
		type: 'page',
		name: '_startPage_',
		value: '',
	};
	tutorial.variables.push(variable);

	let loadingpage = {
		uuid: UUID(),
		name: 'loading',
		theme: null,
		nodes: [
			{
				type: 'var_set_page',
				uuid: UUID(),
				active: true,
				name: '',
				start: null,
				end: null,
				css: '',
				theme: 'default',
				data: {
					var: variable.uuid
				}
			}
		],
	};

	tutorial.pages_array.push(loadingpage);
	tutorial.pages_hierarchy.push({ name: loadingpage.name, uuid: loadingpage.uuid });

	return tutorial;
};
