import React, {Component} from 'react';

import {Modal, Header, Button, Tab} from 'semantic-ui-react'
import {connect} from 'react-redux';
import RestAPI from '../../../RestAPI';
import MediaPreview from '../../1_atom/MediaPreview/MediaPreview';
import styled from 'styled-components';
import DataTable from '../DataTable/DataTable';
import moment from 'moment/moment';

const ScrollWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  text-align: center;
  overflow-y: auto;
  max-height: calc(100vh - 300px);
  margin: 15px 0;
  flex: 1 1 auto
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const PreviewArea = styled.div`
  overflow-y: auto;
  flex: 0 1 250px;
  padding-left: 2rem;
`;

const Upload = styled.div`
  display: flex;
  align-items: flex-start;
  float: left;
  margin-bottom: 5px;
  & > input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
`;

const ReplaceInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const ErrorText = styled.pre`
  display: block;
  padding: 0 10px;
  margin: 0;
  text-align: left;  
`;

class MediaModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    RestAPI.media(this.props.tutorial.uuid);
  }

  uploadFiles(files) {
    this.setState({error: ''});

    if (files) {
      let results = [];
      let promise = null;
      for (let file of files) {
        if (promise) {
          promise = promise.then(
            (result) => {
              results.push(result);
              return RestAPI.uploadMedia(file,this.props.tutorial.uuid)
            },
          );
        }
        else {
          promise = RestAPI.uploadMedia(file,this.props.tutorial.uuid);
        }
      }
      if (promise) {
        promise.then((result) => {
          results.push(result);
          let errors = results.map(
            (r, index) =>
              (results.length > 1 ? (index + 1 + ': ') : '')
              + (r.ok ? 'Success!' : (r.error + '').replace(/\n.*$/mg, '')),
          );
          this.setState({error: errors.join('\n')});
        }).catch(err => {
          console.error('Upload failed!', err);
          this.setState({error: err});
        });
      }
    }
  }

  onDelete(media) {
    RestAPI.deleteMedia(media);
  }

  onReplace(media) {
    const fileInput = document.getElementById('replaceFile');

    const callback = (event) => {
      if (fileInput.files && fileInput.files.length === 1) {
        RestAPI.replaceMedia(media.uuid,media.owner_uuid,fileInput.files[0]).then((result) => {
          this.setState({
            error: result.ok ? 'Success!' : (result.error + '').replace(/\n.*$/mg, ''),
          });
        }).catch((err) => {
          console.error('Upload failed!', err);
          this.setState({error: err});
        });
      }

      fileInput.removeEventListener('change', callback);
    };

    fileInput.addEventListener('change', callback);
    fileInput.click();
  }

  render() {
    const {
      media,
      onApply,
      onCancel,
    } = this.props;

    const {
      selected,
      error,
    } = this.state;

    const columns = [
      {
        name: 'Name',
        key: 'name',
        singleLine: true,
      },
      {
        name: 'Created',
        key: 'created',
        singleLine: true,
      },
    ];

    const actions = [
      {
        color: 'red',
        icon: 'delete',
        func: (row, index) => {
          let entry = media[index];
          this.onDelete(entry);
        },
      },
      {
        color: 'blue',
        icon: 'exchange',
        func: (row, index) => {
          let entry = media[index];
          this.onReplace(entry);
        },
      },
    ];

    const panes = [
      {
        menuItem: 'List',
        render: () => <ListWrapper>
          <ScrollWrapper>
						<DataTable
              columns={columns}
              rows={
                media.map(media => ({
									name: media.name,
									media: media,
                  created: moment(media.created).format("DD:MM:YYYY HH:mm:ss"),
                }))
              }
              actions={actions}
              onSelect={(result) => {
								this.setState({selected: result.media});
							}}
            />
          </ScrollWrapper>
          <PreviewArea>
            {
              selected &&
              <MediaPreview
                media={selected}
                detailed
              />
            }

          </PreviewArea>
        </ListWrapper>,
      },
      {
        menuItem: 'Previews',
        render: () => <ScrollWrapper>
          {
            media && media.map((media, index) =>
              media && <MediaPreview
                key={media.uuid + index}
                media={media}
                selected={selected.uuid === media.uuid}
                onClick={() => this.setState({selected: media})}
                onReplace={() => this.onReplace(media)}
                onDelete={() => this.onDelete(media)}
              />,
            )
          }
        </ScrollWrapper>,
      },
    ];

    return (
      <Modal open={true}>
        <Header icon={'image'} content="Select media"/>
        <Modal.Content>
          <Tab panes={panes} menu={{pointing: true}}/>
        </Modal.Content>
        <Modal.Actions>
          <Upload type="file" placeholder='Upload'>
            <input
              type="file"
              id="file"
              name="file"
              accept="image/*|video/*"
              onChange={(event) => this.uploadFiles(event.target.files)}
              multiple
            />
            <label
              className="ui button red"
              htmlFor="file"
            >
              <i className="upload icon"/>Upload File
            </label>
            {
              error && <ErrorText>{error}</ErrorText>
            }
          </Upload>
          <Button
            color='green'
            disabled={!media || !selected}
            onClick={() => onApply(selected)}
          >
            apply
          </Button>
          <ReplaceInput
            type="file"
            id="replaceFile"
            name="file"
            accept="image/*|video/*"
          />
          <Button onClick={onCancel}>cancel</Button>
        </Modal.Actions>
      </Modal>
    );
  };
}

MediaModal.defaultProps = {};

MediaModal = connect(
  state => ({
		tutorial: state.tutorial.tutorial,
    media: state.media,
  }),
  {}, // bind account loading action creator
)(MediaModal);

export default MediaModal;

